import React, {useState, useCallback, useRef} from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'

import BookmarkIcon from '@material-ui/icons/Bookmark'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import {useSnackbar} from 'notistack'
import {Bookmark} from '../db/models'
import {usePlayer} from './PlayerProvider/components/PlayerProgress'
import {Podcast} from '../apis/listenNotes/models'
import EpisodeMedia from './ResolveMedia/EpisodeMedia'
import ReadableTime from './ReadableTime'
import {useBookmarks} from './BookMarksProvider'
import {useAnalytics} from '../utils/analytics'
import AlertDialog from './Dialog/AlertDialog'
import {useSharingControls} from './SharingControlsProvider'
import {useAuth} from './AuthProvider'
import EditBookmarkForm from './EditBookmarkForm'
import {incrementPlayCount, setBookmarkShareable} from '../db'
import ClipProgress from './ClipProgress'
import {useUser} from '../utils/auth'
import {copyBookmarkToMyLibrary} from '../db/utils'
import extractIdsFromBookmark from '../utils/extractIdsFromBookmarkPath'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      position: 'relative',
      width: '100%',
    },
    topRow: {
      display: 'flex',
      alignItems: 'center',
    },
    time: {
      flexGrow: 1,
    },
    bookmarkIcon: {
      marginRight: theme.spacing(1),
    },
    description: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    clipLength: {
      position: 'absolute',
      right: 25,
      margin: 5,
      bottom: 5,
      fontSize: 11,
    },
    listenedCount: {
      position: 'absolute',
      left: 25,
      margin: 5,
      bottom: 5,
      fontSize: 11,
    },
    onTop: {
      zIndex: 2000,
    },
  })
)

interface Props {
  bookmark: Bookmark
  episode: EpisodeMedia
  podcast: Podcast
  onPlayed: (bookmark: Bookmark) => void
  RootContainer: React.ForwardRefExoticComponent<
    {
      bookmark: Bookmark
      children: React.ReactNode
      className?: string | undefined
    } & React.RefAttributes<unknown>
  >
  onRemoved: (bookmark: Bookmark) => void
  onUpdated: (newBookmarkData: Bookmark) => void
}

function BookmarkView({
  bookmark,
  episode,
  podcast,
  onPlayed,
  RootContainer,
  onUpdated,
  onRemoved,
}: Props) {
  const classes = useStyles()
  const player = usePlayer()
  const auth = useAuth()
  const sharingControls = useSharingControls()
  const {enqueueSnackbar} = useSnackbar()
  const {removeBookmark} = useBookmarks()
  const analytics = useAnalytics()
  const [editing, setEditing] = useState(false)
  const rootRef = useRef<any>()
  const user = useUser()

  const [alertOpen, setAlertOpen] = useState(false)

  const deleteBookmarkClicked = useCallback(() => {
    setAlertOpen(true)
  }, [setAlertOpen])

  const deleteAction = useCallback(
    (action) => {
      if (action === 'yes') {
        removeBookmark(bookmark)
        onRemoved(bookmark)
        enqueueSnackbar('Bookmark deleted', {
          variant: 'success',
        })
      }
      setAlertOpen(false)
    },
    [enqueueSnackbar, removeBookmark, onRemoved, bookmark]
  )

  const onShare = useCallback(() => {
    setBookmarkShareable(bookmark, true)
      .catch((err) =>
        console.log('Error while setting bookmark shareable', bookmark, err)
      )
      .then(() => console.log('Bookmark was shared'))

    const bookmarkIds = extractIdsFromBookmark(bookmark._path)
    if (!bookmarkIds) {
      enqueueSnackbar(
        'Unable to create sharing info from bookmark. Please try again later',
        {variant: 'error'}
      )
      // TODO report error
      return
    }

    sharingControls.share({
      url: `${window.location.origin}/episode/${bookmarkIds.episodeId}/shared/${bookmarkIds.authorId}/bookmark/${bookmarkIds.bookmarkId}`,
      title: 'Share Bookmark Via',
      sharingText: `${episode.title}`,
      bookmark,
    })
    analytics.logEvent({
      eventName: 'bookmark_share_clicked',
      params: {bookmarkType: bookmark.bookmarkType || ''},
    })
  }, [bookmark, analytics, sharingControls, episode, enqueueSnackbar])

  const onEdit = useCallback(() => setEditing(true), [setEditing])
  const onEditDone = useCallback(
    (newBookmark) => {
      setEditing(false)
      onUpdated(newBookmark)
    },
    [setEditing, onUpdated]
  )

  const addToMyLibrary = useCallback(() => {
    if (!auth.isLoggedIn()) {
      auth.authenticateUser({modalTitle: 'Signin to add media to your library'})
    } else {
      copyBookmarkToMyLibrary(bookmark)
        .then(() => {
          enqueueSnackbar(
            `${bookmark.endPositionSec ? 'Clip' : 'Bookmark'} added to my ${
              bookmark.endPositionSec ? 'Clips' : 'Bookmarks'
            }`,
            {
              variant: 'success',
            }
          )
          analytics.logEvent({
            eventName: 'add_to_library_clicked',
            params: {
              clipTitle: bookmark.description || '',
              clipId: bookmark.id,
              episodeId: episode.id,
            },
          })
        })
        .catch((e) => {
          console.warn("Error while adding to user's library", e)
          enqueueSnackbar(
            'Error while adding to my library, please try again',
            {
              variant: 'error',
            }
          )
        })
    }
  }, [bookmark, auth, analytics, enqueueSnackbar, episode.id])

  return (
    <RootContainer
      className={clsx(classes.root, editing ? classes.onTop : '')}
      ref={rootRef}
      bookmark={bookmark}
    >
      <AlertDialog
        title="Delete Bookmark"
        description="Do you really want to delete this bookmark?"
        open={alertOpen}
        data={bookmark}
        onClose={deleteAction}
      />
      <Grid container spacing={2}>
        <Grid className={classes.topRow} item xs={12}>
          <IconButton
            onClick={() => {
              onPlayed(bookmark)
              analytics.logEvent({
                eventName: 'playback_started',
                params: {
                  origin: bookmark.endPositionSec ? 'clip' : 'bookmark',
                },
              })

              // Fot user that is not the author, increment play count
              if (bookmark.authorId !== user?.uid) {
                console.log('Incrementing play count', bookmark)
                incrementPlayCount(bookmark)
                  .then((newBookmark) => {
                    console.log('Play count successfully incremented')
                    onUpdated(newBookmark)
                  })
                  .catch((e) => {
                    console.warn('Error while incrementing play count', e)
                  })
              }

              if (bookmark.endPositionSec) {
                player.playClip({
                  media: {episode, podcast},
                  startSec: bookmark.positionSec,
                  endSec: bookmark.endPositionSec,
                })
              } else {
                player.startPlaying({
                  media: {episode, podcast},
                  timeSec: bookmark.positionSec,
                })
              }
            }}
          >
            <PlayCircleOutlineIcon fontSize="large" />
          </IconButton>
          {bookmark.endPositionSec ? (
            <GraphicEqIcon className={classes.bookmarkIcon} />
          ) : (
            <BookmarkIcon className={classes.bookmarkIcon} />
          )}
          {!editing && (
            <Typography className={classes.time}>
              <ReadableTime inSeconds={bookmark.positionSec} />
              {bookmark.endPositionSec && (
                <>
                  {' - '}
                  <ReadableTime inSeconds={bookmark.endPositionSec} />
                </>
              )}
            </Typography>
          )}
          {editing && <Typography className={classes.time}>Editing</Typography>}
          {!editing && bookmark.authorId !== user?.uid && (
            <IconButton onClick={addToMyLibrary}>
              <AddIcon />
            </IconButton>
          )}
          {!editing && (
            <IconButton
              color={!!bookmark.sharedAtIsoDate ? 'secondary' : undefined}
              onClick={onShare}
            >
              <ShareIcon />
            </IconButton>
          )}
          {bookmark.authorId === user?.uid && (
            <div>
              {!editing && (
                <IconButton onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              )}
              <IconButton onClick={deleteBookmarkClicked}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </Grid>
        {!editing && (
          <Grid item xs={12}>
            <div className={bookmark.description}>
              <Typography>{bookmark.description}</Typography>
            </div>
          </Grid>
        )}

        {editing && (
          <EditBookmarkForm
            bookmarkData={{
              bookmark,
              episode,
              podcast,
            }}
            onFinish={onEditDone}
          />
        )}
      </Grid>
      <div className={classes.clipLength}>
        {bookmark.endPositionSec && (
          <div>{bookmark.endPositionSec - bookmark.positionSec} secs</div>
        )}
        {!!bookmark.sharedAtIsoDate && (
          <Tooltip
            title={`This bookmark was played ${bookmark.listenedCount} times by other users`}
          >
            <div>Played: {bookmark.listenedCount}</div>
          </Tooltip>
        )}
      </div>
      {/* <div className={classes.listenedCount}>Listened 15 times</div> */}
    </RootContainer>
  )
}

BookmarkView.defaultProps = {
  onPlayed: () => undefined,
  RootContainer: React.forwardRef(
    (
      {
        bookmark,
        className,
        children,
      }: {
        bookmark: Bookmark
        children: React.ReactNode
        className?: string
      },
      ref
    ) => (
      <Card ref={ref} className={className}>
        <CardContent>{children}</CardContent>
        <ClipProgress bookmark={bookmark} />
      </Card>
    )
  ),
  onRemoved: () => undefined,
  onUpdated: () => undefined,
}

export default BookmarkView

/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  makeStyles,
  createStyles,
  Hidden,
  CardMedia,
} from '@material-ui/core'
import moment from 'moment'
import excerpts from 'excerpts'
import {Link} from 'react-router-dom'
import {Episode, Podcast} from '../apis/listenNotes/models'
import {getLastPlayedTime} from '../db'
import ReadableTime from './ReadableTime'
import {resolveToSystemMedia} from './ResolveMedia'
import EpisodeMedia from './ResolveMedia/EpisodeMedia'
import {useAuth} from './AuthProvider'
import InteractivePlayButton from './InteractivePlayButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardMedia: {
      height: '20vh',
      minHeight: theme.spacing(20),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    image: {
      width: '100%',
      height: 'auto',
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    excerpt: {
      flexGrow: 1,
    },
    buttons: {
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    topImg: {
      backgroundSize: 'contain',
      backgroundColor: theme.palette.grey[900],
    },
    podcast: {
      textDecoration: 'none',
      color: 'inherit',
    },
  })
)

interface Props {
  episode: Episode
  podcast: Podcast
  lastPlayedTime?: number
}

function EpisodePreview({
  episode,
  podcast,
  lastPlayedTime: givenLastPlayedTime,
}: Props) {
  const classes = useStyles()
  const [lastPosition, setLastPosition] = useState<number>(0)
  const {
    authData: {isLoggedIn},
  } = useAuth()
  const [episodeMedia, setEpisodeMedia] = useState<EpisodeMedia | null>(null)

  useEffect(() => {
    if (givenLastPlayedTime) {
      setLastPosition(givenLastPlayedTime)
      return
    }

    // TODO: handle errors
    resolveToSystemMedia({
      ...episode,
      podcast,
    })
      .then((media) => {
        setEpisodeMedia(media)
        return isLoggedIn ? getLastPlayedTime({mediaId: media.id}) : 0
      })
      .then((lastPlayedTime) => {
        setLastPosition(lastPlayedTime || 0)
      })
  }, [
    setLastPosition,
    episode,
    podcast,
    givenLastPlayedTime,
    setEpisodeMedia,
    isLoggedIn,
  ])

  return (
    <Card>
      <Hidden lgUp>
        <CardMedia
          classes={{root: classes.topImg}}
          className={classes.cardMedia}
          image={episode.image}
        />
      </Hidden>
      <CardContent>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={12} sm={9} className={classes.textBlock}>
            <Typography className={classes.boldText} variant="overline">
              {moment(episode.pub_date_ms).format('MMMM DD YYYY')}
            </Typography>
            <Link className={classes.podcast} to={`/resolve/${episode.id}`}>
              <Typography className={classes.boldText} variant="h6">
                {episode.title}
              </Typography>
            </Link>

            <Typography className={classes.excerpt}>
              {excerpts(episode.description, {characters: '300'})}
            </Typography>
            <div className={classes.buttons}>
              <InteractivePlayButton
                originForAnalytics="podcast_screen"
                playFromPosition={lastPosition}
                podcast={podcast}
                episode={episodeMedia}
                buttonProps={{color: 'primary'}}
              />
              <Button color="primary">
                <Link className={classes.podcast} to={`/resolve/${episode.id}`}>
                  Show detail
                </Link>
              </Button>
            </div>
            <Typography>
              {lastPosition !== 0 && (
                <>
                  <ReadableTime inSeconds={lastPosition} /> /{' '}
                </>
              )}
              <ReadableTime inSeconds={episode.audio_length_sec} />
            </Typography>
          </Grid>
          <Hidden only={['sm', 'xs', 'md']}>
            <Grid item xs={12} sm={3}>
              <img className={classes.image} src={episode.thumbnail} alt="" />
            </Grid>
          </Hidden>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default EpisodePreview

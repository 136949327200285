import React from 'react'

import {
  makeStyles,
  createStyles,
  Container,
  Paper,
  Typography,
} from '@material-ui/core'

import {Podcast} from '../../../apis/listenNotes/models'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: 'white',
      flexGrow: 1,
    },
  })
)

interface Props {
  podcast: Podcast
}

function AboutInfo({podcast}: Props) {
  const classes = useStyles()
  return (
    <Paper className={classes.root} elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1">{podcast.description}</Typography>
        {/* TODO add socials links */}
      </Container>
    </Paper>
  )
}

export default AboutInfo

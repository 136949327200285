import React, {useContext, useMemo, useState} from 'react'
import {makeStyles, Slider, Typography} from '@material-ui/core'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
import playerContext, {PlayerContext} from '../context'
import ReadableTime from '../../ReadableTime'
import {useCurrentPosition} from '../index'
import {Bookmark} from '../../../db/models'

// const CustomSlider = withStyles({thumb: {height: '20px', width: '5px'}})(Slider)
const useStyles = makeStyles(() => ({
  duration: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marks: {
    paddingTop: '10px',
    backgroundColor: 'red',
  },
  bookmarksList: {
    cursor: 'pointer',
  },
  thumb: {
    width: '6px',
    height: '6px',
    transform: 'translateX(3px) translateY(3px)',
  },
}))

interface Props {
  player: PlayerContext
  bookmarks: Bookmark[]
  onBookmarkIconClicked: () => void
}

function PlayerProgress({player, bookmarks, onBookmarkIconClicked}: Props) {
  const classes = useStyles()
  const {currentTime, setCurrentTime} = useCurrentPosition()
  const [slideInteractionValue, setSlideInteractionValue] = useState<
    number | null
  >(null)

  const marks = useMemo(
    () => bookmarks.map((one) => ({value: one.positionSec})),
    [bookmarks]
  )

  const audioDuration = Math.ceil(player.playerControls.duration || 0)
  return (
    <div>
      <Slider
        value={slideInteractionValue || currentTime || 0}
        onChange={(_, newValue) => {
          setSlideInteractionValue(newValue as number)
        }}
        max={audioDuration}
        onChangeCommitted={(_, newValue) => {
          console.log(newValue)
          setCurrentTime(newValue as number)
          setSlideInteractionValue(null)
        }}
        marks={marks}
        classes={{mark: classes.marks, thumb: classes.thumb}}
      />
      <div className={classes.duration}>
        <Typography>
          {currentTime !== null && currentTime !== undefined && (
            <ReadableTime inSeconds={slideInteractionValue || currentTime} />
          )}
        </Typography>
        <div
          role="presentation"
          className={classes.bookmarksList}
          onClick={onBookmarkIconClicked}
        >
          <div>
            <BookmarksIcon color="primary" fontSize="small" />
          </div>
        </div>
        <Typography>
          <ReadableTime inSeconds={audioDuration} />
        </Typography>
      </div>
    </div>
  )
}

export default PlayerProgress

export function usePlayer() {
  return useContext(playerContext)
}

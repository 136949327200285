import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {useAsync} from 'react-async'
import LoadingIndicator from '../LoadingIndicator'
import RetryableError from '../RetryableError'
import EpisodeView from './component/EpisodeView'
import {
  getMediaById,
  getUserMediaDetails,
  useBookmarksForUser,
  useBookmarksForMedia,
} from '../../db'
import {useAuth} from '../AuthProvider'
import {Bookmark, BookmarkType} from '../../db/models'
import {useAnalytics} from '../../utils/analytics'
import {usePlayer} from '../PlayerProvider/components/PlayerProgress'
import {PlayingMedia} from '../PlayerProvider'
import {getPodcastDetail} from '../../apis/podcastResolver'

function useParamsInfo() {
  const params = useParams<{[key: string]: string}>()
  return {
    episodeId: params.episodeId || '',
    sharedUserId: params.userId || '',
    bookmarkId: params.bookmarkId || '',
  }
}

function EpisodeScreen() {
  const {episodeId, sharedUserId, bookmarkId} = useParamsInfo()

  const {
    authData: {isLoggedIn},
  } = useAuth()
  const [sharedMedia, setSharedMedia] = useState<PlayingMedia | null>(null)
  const auth = useAuth()
  const {startPlaying} = usePlayer()
  const myBookmarks = useBookmarksForMedia({mediaId: episodeId})
  const analytics = useAnalytics()
  // otherBookmarks can be shared user's bookmarks or editor choice bookmarks
  const otherBookmarks = useBookmarksForUser({
    mediaId: episodeId,
    userId: sharedUserId || auth.curatedUserId,
    bookmarkType: sharedUserId
      ? BookmarkType.SHARED_BOOKMARKS
      : BookmarkType.POPULAR_BOOKMARKS,
  })

  const otherBookmarksToUse: Bookmark[] = useMemo(() => {
    if (bookmarkId && otherBookmarks) {
      const selectedBookmark = otherBookmarks.find(
        (one) => one.id === bookmarkId
      )
      if (selectedBookmark) return [selectedBookmark]
    }
    return otherBookmarks || []
  }, [otherBookmarks, bookmarkId])

  const history = useHistory()
  const prevPageState: any = history.location.state

  const selectedTab = useMemo(() => {
    return !isLoggedIn ||
      (prevPageState && prevPageState.from === '/explore') ||
      sharedUserId
      ? 'tab2'
      : 'tab1'
  }, [isLoggedIn, prevPageState, sharedUserId])

  useEffect(() => {
    if (sharedUserId && sharedMedia) {
      startPlaying({
        media: sharedMedia,
        timeSec:
          otherBookmarks && otherBookmarks.length > 0
            ? otherBookmarks[0].positionSec
            : 0,
        pauseOnLoad: true,
      })
    }
  }, [sharedMedia, sharedUserId, startPlaying, otherBookmarks])

  const fetchEpisodeInfo = useCallback(async () => {
    const media = await getMediaById(episodeId)
    if (!media) throw new Error('Episode not found')

    const podcast = await getPodcastDetail({
      podcastId: media.listennotesPodcastUid,
    })

    const userMedia = await getUserMediaDetails({mediaId: episodeId})
    setSharedMedia({episode: media, podcast})
    return {
      episode: media,
      podcast,
      userMedia: {...userMedia},
    }
  }, [episodeId])
  const fetchEpisodeInfoTask = useAsync({promiseFn: fetchEpisodeInfo})

  const reportNotUserBookmarkPlayed = useCallback(
    (bookmark: Bookmark) => {
      analytics.logEvent({
        eventName: 'shared_bookmark_played',
        params: {
          origin: bookmark.endPositionSec ? 'clip' : 'bookmark',
          shared_from: sharedUserId ? 'user' : 'popular',
        },
      })
    },
    [sharedUserId, analytics]
  )

  const bookmarksLists = useMemo(() => {
    const myBookmarksTabLabel =
      myBookmarks && myBookmarks?.length > 0 ? `(${myBookmarks?.length})` : ''

    const otherBookmarksTabLabel =
      otherBookmarksToUse && otherBookmarksToUse?.length > 0
        ? `(${otherBookmarksToUse?.length})`
        : ''

    return [
      {
        items: isLoggedIn ? myBookmarks : [],
        label: `My Bookmarks ${isLoggedIn ? myBookmarksTabLabel : ''}`,
      },
      {
        items: otherBookmarksToUse,
        onBookmarkPlayed: reportNotUserBookmarkPlayed,
        label: `${
          sharedUserId ? 'Shared Bookmarks' : 'Popular Bookmarks'
        } ${otherBookmarksTabLabel}`,
      },
    ]
  }, [
    isLoggedIn,
    otherBookmarksToUse,
    myBookmarks,
    reportNotUserBookmarkPlayed,
    sharedUserId,
  ])

  return (
    <>
      {fetchEpisodeInfoTask.isLoading && (
        <LoadingIndicator text="Loading podcast" />
      )}
      {fetchEpisodeInfoTask.isRejected && fetchEpisodeInfoTask.error && (
        <RetryableError
          error={fetchEpisodeInfoTask.error}
          text="Unable to fetch Episode"
          onTryAgain={() => fetchEpisodeInfoTask.reload()}
        />
      )}
      {fetchEpisodeInfoTask.isResolved && fetchEpisodeInfoTask.data && (
        <EpisodeView
          episode={fetchEpisodeInfoTask.data.episode}
          podcast={fetchEpisodeInfoTask.data.podcast}
          userMedia={fetchEpisodeInfoTask.data.userMedia}
          bookmarksLists={bookmarksLists}
          initialSelectedTab={selectedTab}
        />
      )}
    </>
  )
}

export default EpisodeScreen

import React from 'react'
import {makeStyles, createStyles, Theme, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Podcast} from '../../../apis/listenNotes/models'
import {generatePodcastLink} from '../../../utils/routes'

const useStyles = makeStyles<Theme, {backgroundImage: string}>((theme) =>
  createStyles({
    root: (props) => ({
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundSize: 'contain',
      display: 'block',
      width: '100%',
      height: '100%',
      '&&': {
        display: 'block',
      },
      textDecoration: 'none',
      '&:hover #info': {
        display: 'flex',
      },
      cursor: 'pointer',
    }),
    info: {
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, .75)',
      color: 'white',
      flexDirection: 'column',
      padding: theme.spacing(2),
      display: 'none',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    spacer: {
      flex: 1,
    },
    showMore: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)

interface Props {
  podcast: Podcast
  onClick: () => void
}

function PodcastCell({podcast, onClick}: Props) {
  const classes = useStyles({backgroundImage: podcast.image || 'todo'})
  return (
    <Link
      onClick={onClick}
      to={generatePodcastLink({podcastId: podcast.id})}
      className={classes.root}
    >
      <div id="info" className={classes.info}>
        <Typography className={classes.title} variant="h6">
          {podcast.title}
        </Typography>
        <Typography>{podcast.total_episodes} episodes</Typography>
        <div className={classes.spacer} />
        <Typography className={classes.showMore}>Show more</Typography>
      </div>
    </Link>
  )
}

PodcastCell.defaultProps = {
  onClick: () => undefined,
}

export default PodcastCell

import {parseString} from 'xml2js'
import moment from 'moment'
import md5 from 'md5'
import axios from 'axios'
import {Media} from '../../db/models'
import {
  Episode,
  Podcast,
  PodcastWithEpisode,
} from '../../apis/listenNotes/models'

const YOUTUBE_KEY = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
  .apiKey

function parseStringPromise(xmlString: string): Promise<any> {
  return new Promise((resolve, reject) => {
    parseString(xmlString, (err, result) => {
      if (err) reject(err)
      else resolve(result)
    })
  })
}

function durationFromTimeNotation(dur: string) {
  const split = dur.split(':')
  console.log(split)
  return split.reduce((acc, cur, i) => {
    const curNumber = Number(cur)
    if (isNaN(curNumber)) return acc
    return acc + curNumber * 60 ** (split.length - i - 1)
  }, 0)
}

function generateEpisodeId(episdoe: Media) {
  const {
    title,
    publishedAt,
    created,
    episodeLength,
    episodeDescription,
    thumbnail,
    episodeAudio,
  } = episdoe
  return `custom-${md5(
    JSON.stringify({
      title,
      publishedAt,
      created,
      episodeLength,
      episodeDescription,
      thumbnail,
      episodeAudio,
    })
  )}`
}

function processRssItemObject(
  item: any,
  defaultImage?: any,
  podcastId?: string
) {
  const publishedAt = item.pubDate ? moment(item.pubDate[0]) : moment()

  const mediaWithoutId: Media = {
    id: '',
    podcastId: podcastId || 'none',
    title: item.title?.[0] || '',
    publishedAt: publishedAt.toISOString(false),
    created: publishedAt.valueOf(),
    episodeLength: durationFromTimeNotation(item['itunes:duration']?.[0] || 0),
    episodeDescription: item.description?.[0] || null,
    thumbnail: item['itunes:image']?.[0]?.$?.href || defaultImage || null,
    episodeAudio: item.enclosure?.[0].$.url || '',
    episodeMediaUrl: item.enclosure?.[0].$.url || '',
    publisher: '',
    podcastName: null,
    podcastRssFeed: null,
    listennotesPodcastUid: 'custom-default',
    listennotesEpisodeUid: 'custom-defaut',
    hashKey: '',
    addedManually: true,
  }

  const id = generateEpisodeId(mediaWithoutId)
  mediaWithoutId.listennotesEpisodeUid = id
  mediaWithoutId.listennotesPodcastUid = podcastId || 'custom-default'
  mediaWithoutId.id = id

  return mediaWithoutId
}

export function mediaToEpisode(media: Media): Episode {
  return {
    id: media.id,
    link: media.episodeMediaUrl,
    // This is the URI of the episode provided by podcast host. This is not *ListenNotes* URL.
    audio: media.episodeAudio,
    // This is the ListenNotes URL for the audio of the podcast. This is redirected to another URL that hosts the audio of the podcast.
    image: media.thumbnail,
    title: media.title || '',
    thumbnail: media.thumbnail,
    description: media.episodeDescription || '',
    pub_date_ms: media.created,
    audio_length_sec: media.episodeLength,
    podcastId: media.podcastId || null,
  }
}

export async function parseRssItem(xmlString: string): Promise<Media> {
  const value = await parseStringPromise(xmlString)

  const {item} = value

  return processRssItemObject(item)
}

function generatePodcastId(podcast: Podcast) {
  const {title, image, description} = podcast
  return `custom-${md5(JSON.stringify({title, image, description}))}`
}

export async function parseRssFeed(
  xmlString: string,
  rssUrl: string | undefined = undefined
): Promise<PodcastWithEpisode> {
  console.log(xmlString)
  const value = await parseStringPromise(xmlString)
  const podcastRss = value.rss.channel[0]

  const podcast: PodcastWithEpisode = {
    id: 'custom',
    title: podcastRss.title?.[0] || null,
    image: podcastRss['itunes:image']?.[0]?.$?.href || null,
    description: podcastRss.description?.[0] || null,
    thumbnail: podcastRss['itunes:image']?.[0]?.$?.href || null,
    rss: rssUrl || podcastRss['atom:link']?.[0]?.$.href || null,
    type: null,
    email: null,
    extra: null,
    country: null,
    website: null,
    language: null,
    genre_ids: null,
    itunes_id: null,
    publisher: null,
    isClaimed: null,
    looking_for: null,
    total_episodes: podcastRss.item?.length || null,
    latest_pub_date_ms: null,
    earliest_pub_date_ms: null,
    next_episode_pub_date: 0,
    episodes: [],
  }

  podcast.id = generatePodcastId(podcast)

  podcast.episodes = (podcastRss.item || [])
    .map((item: any) =>
      processRssItemObject(
        item,
        podcastRss['itunes:image'][0]?.$?.href,
        podcast.id
      )
    )
    .map(mediaToEpisode)

  return podcast
}

export function readTextFile(file: File): Promise<string> {
  return new Promise((resolve) => {
    const fl = new FileReader()
    fl.onload = () => {
      resolve(String(fl.result))
    }
    fl.readAsText(file)
  })
}

export async function getYoutubeVideoInfo({
  id,
}: {
  id: string
}): Promise<{durationSec: number; title: string}> {
  const {data: durationData} = await axios.get(
    'https://www.googleapis.com/youtube/v3/videos',
    {
      params: {
        key: YOUTUBE_KEY,
        id,
        part: 'contentDetails',
      },
    }
  )

  console.log(durationData)
  const durationSec = moment
    .duration(durationData.items[0].contentDetails.duration)
    .asSeconds()

  const {
    data: {title},
  } = await axios.get(
    `https://us-central1-audiobook-bookmarks.cloudfunctions.net/getRss?link=https://www.youtube.com/oembed?url=https://youtu.be/${id}&format=json`
  )

  return {durationSec, title}
}

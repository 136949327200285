import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import {auth} from 'firebase'
import {useHistory} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'
import * as firebase from 'firebase/app'
import authContext, {AuthContext, AuthProps} from './context'
import AuthModal from './AuthModal'

const remoteConfig = firebase.remoteConfig()

interface Props {
  children: React.ReactNode
}

function getUserDeviceIdentifier() {
  let deviceIdentifier = localStorage.getItem('deviceIdentifier')
  if (!deviceIdentifier) {
    deviceIdentifier = uuidv4()
    localStorage.setItem('deviceIdentifier', deviceIdentifier)
  }
  return deviceIdentifier
}

function AuthProvider({children}: Props) {
  const history = useHistory()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const {currentUser} = auth()
  const [user, setUser] = useState<firebase.User | null>(currentUser)
  const loggedIn = useMemo(() => !!user, [user])
  const deviceId = useMemo(() => getUserDeviceIdentifier(), [])
  const [authModalTitle, setAuthModalTitle] = useState('Login to Continue')

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((newUser) => {
      setUser(newUser)
      setShowAuthModal(false)
    })
    return unsubscribe
  }, [])

  const authenticate = useCallback(
    ({modalTitle, onAuthResult}: AuthProps) => {
      setShowAuthModal(true)
      if (modalTitle) setAuthModalTitle(modalTitle)
      if (onAuthResult) onAuthResult()
    },
    [showAuthModal]
  )

  const logout = useCallback(() => {
    auth()
      .signOut()
      .then(() => {
        setUser(null)
        history.replace('/')
      })
  }, [])

  const authValue: AuthContext = {
    authData: {isLoggedIn: loggedIn, user, deviceId},
    authenticateUser: authenticate,
    isLoggedIn: () => loggedIn,
    curatedUserId: remoteConfig.getValue('guest_user_id').asString(),
    logout,
  }

  return (
    <authContext.Provider value={authValue}>
      <AuthModal
        showModal={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        title={authModalTitle}
      />
      {children}
    </authContext.Provider>
  )
}

export default AuthProvider

export function useAuth() {
  return useContext(authContext)
}

import React, {useState} from 'react'
import {makeStyles, createStyles, Container} from '@material-ui/core'
import BestPodcastsList from './components/BestPodcastsList'
import SearchResults from '../SearchScreen/components/SearchResults'
import SearchBar from '../SearchBar'

const useStyles = makeStyles(() =>
  createStyles({
    searchBar: {
      position: 'sticky',
      top: 65,
      background: '#fafafa',
    },
  })
)

function ExploreScreen() {
  const classes = useStyles()
  const [searchString, setSearchString] = useState<string | null>(null)
  return (
    <>
      <div className={classes.searchBar}>
        <Container>
          <SearchBar
            onChangeSearchString={(newSearch) => {
              setSearchString(newSearch)
            }}
          />
        </Container>
      </div>
      {searchString ? (
        <SearchResults key={searchString} searchString={searchString} />
      ) : (
        <BestPodcastsList />
      )}
    </>
  )
}

export default ExploreScreen

import React, {useCallback} from 'react'
import InfiniteLoadingList from '../../InfiniteLoadingList'
import * as api from '../../../apis/listenNotes/index'
import {Podcast} from '../../../apis/listenNotes/models'
import PodcastsGrid from '../../PodcastGridList'
import {useAnalytics} from '../../../utils/analytics'

interface Props {
  searchString: string
}

function SearchResults({searchString}: Props) {
  const analytics = useAnalytics()

  const fetchNextPage = useCallback(
    async (previousResponse: api.SearchPodcastsResponse | null) => {
      const response = previousResponse
        ? await api.searchPodcasts({
            offset: previousResponse.next_offset,
            search: searchString,
          })
        : await api.searchPodcasts({search: searchString})
      return {result: response.data, isLast: !response.data.next_offset}
    },
    [searchString]
  )

  return (
    <>
      <InfiniteLoadingList
        loadMore={fetchNextPage}
        resultToItems={(result) => result.results}
      >
        {(podcasts: Podcast[]) => (
          <PodcastsGrid
            onItemClick={({index}) => {
              analytics.logEvent({
                eventName: 'search_item_clicked',
                params: {index, query: searchString},
              })
            }}
            podcasts={podcasts}
          />
        )}
      </InfiniteLoadingList>
    </>
  )
}

export default SearchResults

import {createContext} from 'react'
import {Bookmark} from '../../db/models'

const emptyBookmark: Bookmark = {
  id: '',
  positionSec: 0,
  listenedCount: 0,
  description: '',
  mediaId: '',
  created: '',
  authorId: '',
}

export interface BookmarksContext {
  addBookmark: (bookmark: {
    description: string
    type: 'bookmark' | 'clip'
  }) => Promise<Bookmark>
  removeBookmark: (bookmark: Bookmark) => Promise<string>
  updateBookmark: (bookmark: Bookmark) => Promise<Bookmark>
}

const emptyContextValue: BookmarksContext = {
  addBookmark: () => Promise.resolve(emptyBookmark),
  updateBookmark: () => Promise.resolve(emptyBookmark),
  removeBookmark: () => Promise.resolve(''),
}

const bookmarksContext = createContext<BookmarksContext>(emptyContextValue)

export default bookmarksContext

import React from 'react'
import * as api from '../../../apis/listenNotes/index'
import PodcastsGrid from '../../PodcastGridList'
import InfiniteLoadingList from '../../InfiniteLoadingList'
import {Podcast} from '../../../apis/listenNotes/models'
import {useAnalytics} from '../../../utils/analytics'

async function fetchNextPodcasts(previous: api.BestPodcastsResponse | null) {
  const response = previous
    ? await api.bestPodcasts({page: previous.next_page_number})
    : await api.bestPodcasts()
  return {result: response.data, isLast: !response.data.has_next}
}

function BestPodcastsList() {
  const analytics = useAnalytics()
  return (
    <>
      <InfiniteLoadingList
        resultToItems={(result) => result.podcasts}
        loadMore={fetchNextPodcasts}
      >
        {(podcasts: Podcast[]) => (
          <PodcastsGrid
            onItemClick={({index}) => {
              analytics.logEvent({
                eventName: 'most_popular_click',
                params: {index},
              })
            }}
            podcasts={podcasts}
          />
        )}
      </InfiniteLoadingList>
    </>
  )
}

export default BestPodcastsList

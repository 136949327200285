import React, {useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core'
import TopBar from './components/TopBar'
import NavigationDrawer from './components/NavigationDrawer'
import NavigationList from './components/NavigationList'
import sections from '../../sections'
import InsideRouter from './components/InsideRouter'
import AppTourGuide from '../AppTourGuide'
import PlayerProvider, {PlayerBar} from '../PlayerProvider'
import BookMarksProvider from '../BookMarksProvider'
import SettingsProvider, {useSettings} from '../SettingsProvider'
import SharingControlsProvider from '../SharingControlsProvider'
import LoadLatestPlayback from '../LoadLatestPlayback'
import BookmarkPopupProvider from '../EditBookmarkPopup'
import LayoutSwitch, {Layout} from './components/LayoutSwitch'
import {useAuth} from '../AuthProvider'

export const DRAWER_WIDTH = 300
export const ROOT_CONTENT_CONTAINER_ID = 'root-content-container'

const useStyles = makeStyles<Theme, {layout: Layout}>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,
    pageContent: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: ({layout}) => (layout === Layout.RIGHT ? '50%' : ''),
        marginRight: ({layout}) => (layout === Layout.LEFT ? '50%' : ''),
      },
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    playerBar: {
      [theme.breakpoints.up('lg')]: {
        left: DRAWER_WIDTH,
      },
    },
  })
)

function InsideScreen() {
  const {
    settingsData: {layout},
    setLayout,
  } = useSettings()
  const {authData} = useAuth()

  const classes = useStyles({layout})
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <SettingsProvider>
      <SharingControlsProvider>
        <PlayerProvider>
          <BookMarksProvider>
            <BookmarkPopupProvider>
              <LoadLatestPlayback />
              <div className={classes.root}>
                <TopBar
                  drawerWidth={DRAWER_WIDTH}
                  onMenuButtonClicked={() => setDrawerOpen((value) => !value)}
                />
                <NavigationDrawer
                  mobileOpen={drawerOpen}
                  onToggleOpen={() => setDrawerOpen((value) => !value)}
                  drawerWidth={DRAWER_WIDTH}
                >
                  <NavigationList
                    sections={sections}
                    toggleDrawer={(open) => setDrawerOpen(open)}
                  />
                </NavigationDrawer>
                <main
                  className={classes.content}
                  id={ROOT_CONTENT_CONTAINER_ID}
                >
                  <div className={classes.toolbar} />
                  {authData.isLoggedIn && (
                    <LayoutSwitch value={layout} onChange={setLayout} />
                  )}
                  <div className={classes.pageContent}>
                    <InsideRouter sections={sections} />
                  </div>
                  <PlayerBar className={classes.playerBar} />
                </main>
              </div>
            </BookmarkPopupProvider>
          </BookMarksProvider>
        </PlayerProvider>
      </SharingControlsProvider>
      <AppTourGuide />
    </SettingsProvider>
  )
}

export default () => (
  <SettingsProvider>
    <InsideScreen />
  </SettingsProvider>
)

import React, {useCallback} from 'react'
import clsx from 'clsx'
import {createStyles, makeStyles} from '@material-ui/core'
import {useAsync} from 'react-async'
import axios from 'axios'
import {parseRssFeed} from '../utils'
import LoadingIndicator from '../../LoadingIndicator'
import RetryableError from '../../RetryableError'
import PodcastToAddOverview from './PodcastToAddOverview'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
)

interface Props {
  className?: string
}

function LoadFromUrl({className}: Props): JSX.Element {
  const classes = useStyles()

  const loadPodcastTask = useAsync({
    promiseFn: useCallback(async () => {
      const link = window.location.search.replace('?', '')
      const response = await axios.get(
        `https://us-central1-audiobook-bookmarks.cloudfunctions.net/getRss?link=${link}`
      )

      return parseRssFeed(response.data, link)
    }, []),
  })

  return (
    <div className={clsx(classes.root, className)}>
      {loadPodcastTask.isLoading && <LoadingIndicator />}
      {loadPodcastTask.isRejected && (
        <RetryableError
          onTryAgain={loadPodcastTask.reload}
          error={loadPodcastTask.error}
        />
      )}
      {loadPodcastTask.isFulfilled && (
        <PodcastToAddOverview podcast={loadPodcastTask.data} />
      )}
    </div>
  )
}

export default LoadFromUrl

import {useCallback, useEffect, useRef} from 'react'
import {useControlledPlayer} from '../../ControlledPlayer'
import {useAnalytics} from '../../../utils/analytics'
import {setMediaLastPlayed} from '../../../db'
import {PlayingMedia} from '../index'
import {useAuth} from '../../AuthProvider'

function useSaveProgress({playingMedia}: {playingMedia: PlayingMedia | null}) {
  const controlledPlayer = useControlledPlayer()
  const analytics = useAnalytics()
  const auth = useAuth()
  const lastTimeOnPlayEmitted = useRef<number | null>(null)

  const playedAtLeastOnce = useRef<boolean>(false)

  const episodeId = playingMedia?.episode.id

  useEffect(() => {
    playedAtLeastOnce.current = false
  }, [playingMedia?.episode.id])

  const saveProgress = useCallback(
    (state: 'playing' | 'paused') => {
      if (!playedAtLeastOnce.current) {
        if (state === 'playing') {
          playedAtLeastOnce.current = true
          lastTimeOnPlayEmitted.current = Date.now()
        }
        return
      }
      const currentPosition = controlledPlayer.getPosition()

      if (currentPosition && episodeId) {
        if (auth.isLoggedIn()) {
          setMediaLastPlayed({
            mediaId: episodeId,
            timeSec: currentPosition,
          })
        } else {
          // TODO store media location in local storage
        }

        if (lastTimeOnPlayEmitted.current) {
          const duration = Math.round(
            (Date.now() - lastTimeOnPlayEmitted.current) / 1000
          )
          console.log('Player', 'Logging played duration', {
            position: Math.ceil(currentPosition),
            episodeId,
            duration,
          })
          analytics.logEvent({
            eventName: 'played_duration',
            params: {
              position: Math.ceil(currentPosition),
              episodeId,
              duration,
            },
            description: 'Event to store duration of played podcast',
          })
        }
      }

      if (state === 'playing') {
        lastTimeOnPlayEmitted.current = Date.now()
      } else {
        lastTimeOnPlayEmitted.current = null
      }
    },
    [episodeId, controlledPlayer.getPosition]
  )

  useEffect(() => {
    return controlledPlayer.registerOnPlaybackInteractionListener(
      ({currentState}) => {
        console.log('Playback interaction')
        saveProgress(currentState)
      }
    )
  }, [controlledPlayer.registerOnPlaybackInteractionListener, saveProgress])

  useEffect(() => {
    if (controlledPlayer.state !== 'playing') {
      // saveProgress('paused')
      return
    }
    // saveProgress('playing')
    const intervalId = setInterval(
      () =>
        saveProgress(
          controlledPlayer.state === 'playing' ? 'playing' : 'paused'
        ),
      5000
    )
    return () => clearInterval(intervalId)
  }, [saveProgress, controlledPlayer.state])
}

export default useSaveProgress

import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/performance'
import 'firebase/remote-config'

try {
  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '')
  firebase.initializeApp(firebaseConfig)
  firebase.analytics()
  firebase.performance()
  const remoteConfig = firebase.remoteConfig()
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 1000,
    fetchTimeoutMillis: 1,
  }

  remoteConfig.defaultConfig = {
    guest_user_id: 'EQwQs0Uf1TcFTYGw97Zo4Xfo4ol2',
    app_name: 'Clipvo',
  }

  remoteConfig
    .fetchAndActivate()
    .then(() => {
      console.log(`activated firebase remote config`)
    })
    .catch((error) => {
      console.log(`error while fetching remote config`, error)
    })

  console.info('ℹ️', 'firebase', '✅ Initialized successfully')
} catch (e) {
  console.error(
    'Unable to initialize firebase. Make sure you include firebase config in `REACT_APP_FIREBASE_CONFIG` env variable',
    e
  )
  throw e
}

import React from 'react'
import {Container, makeStyles, createStyles} from '@material-ui/core'
import {PodcastWithEpisode, Episode} from '../../../apis/listenNotes/models'
import EpisodePreview from '../../EpisodePreview'
import InfiniteLoadingList from '../../InfiniteLoadingList'
import {getPodcastDetail} from '../../../apis/podcastResolver'

async function loadMoreEpisodes({
  podcastId,
  nextEpisodePubDate,
}: {
  podcastId: string
  nextEpisodePubDate: number
}) {
  try {
    return await getPodcastDetail({podcastId, nextEpisodePubDate})
  } catch (e) {
    console.log(e)
    throw e
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listContainer: {
      margin: theme.spacing(0, 'auto', -2),
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
    root: {
      width: '100%',
    },
  })
)

interface Props {
  podcast: PodcastWithEpisode
}

function EpisodesList({podcast}: Props) {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md">
      <InfiniteLoadingList
        listContainerProps={{
          className: classes.listContainer,
        }}
        loadMore={async (lastFetched: PodcastWithEpisode | null) => {
          const loaded = lastFetched
            ? await loadMoreEpisodes({
                podcastId: lastFetched.id,
                nextEpisodePubDate: lastFetched.next_episode_pub_date,
              })
            : podcast
          return {result: loaded, isLast: !loaded.next_episode_pub_date}
        }}
        resultToItems={(fetchedPodcast) => fetchedPodcast.episodes}
      >
        {(episodes: Episode[]) => {
          return episodes.map((episode) => (
            <EpisodePreview
              key={episode.id}
              podcast={podcast}
              episode={episode}
            />
          ))
        }}
      </InfiniteLoadingList>
    </Container>
  )
}

export default EpisodesList

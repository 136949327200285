import {useEffect, useState} from 'react'
import {auth} from 'firebase'

/**
 * Hook for getting currently signed in user
 *
 * @returns null user is not signed in
 * @returns undefined user is not loaded yet.
 * @returns firebase.User - current user
 */
export function useUser() {
  const [user, setUser] = useState<firebase.User | undefined | null>()
  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((newUser) => setUser(newUser))
    return unsubscribe
  }, [])

  return user
}

import React, {useCallback, useState} from 'react'
import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import BookmarkControls from './components/BookmarkControls'
import {Bookmark} from '../../db/models'
import EpisodeMedia from '../ResolveMedia/EpisodeMedia'
import {Podcast} from '../../apis/listenNotes/models'
import {DEFAULT_CLIP_LENGTH, useBookmarks} from '../BookMarksProvider'
import {usePlayer} from '../PlayerProvider/components/PlayerProgress'
import {useAnalytics} from '../../utils/analytics'

const PREVIEW_START_END_DURATION_SEC = 3

function valueInBoundary({
  value,
  min,
  max,
}: {
  value: number
  min: number
  max: number
}) {
  return Math.max(Math.min(value, max), min)
}

const useStyles = makeStyles((theme) =>
  createStyles({
    inner: {
      margin: '0 auto',
      textAlign: 'center',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    timeButtons: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px',
    },
    speedControlButton: {
      height: '50px',
      margin: '5px',
    },
    timeInput: {
      width: '70px',
    },
    timeIcon: {
      fontSize: '3rem',
    },
    buttons: {
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
)

interface Props {
  bookmarkData: {
    bookmark: Bookmark
    episode: EpisodeMedia
    podcast: Podcast
  }
  onFinish: (updatedBookmark: Bookmark) => void
  onPopulated: () => void
}

function EditBookmarkForm({bookmarkData, onFinish, onPopulated}: Props) {
  const {bookmark, episode, podcast} = bookmarkData
  const classes = useStyles()
  const [updatedBookmark, setUpdatedBookmark] = useState(bookmark)
  const player = usePlayer()
  const {updateBookmark} = useBookmarks()

  const {enqueueSnackbar} = useSnackbar()
  const analytics = useAnalytics()

  const playFromBookmarkTime = useCallback(() => {
    onPopulated()
    if (!updatedBookmark || !bookmarkData) return
    const timeToPlay = updatedBookmark.positionSec
    const timeToStop = updatedBookmark.endPositionSec
    if (!timeToStop) {
      player.startPlaying({
        media: {
          episode,
          podcast,
        },
        timeSec: timeToPlay,
      })
    } else {
      player.playClip({
        startSec: timeToPlay,
        endSec: timeToStop,
        media: {
          episode,
          podcast,
        },
      })
    }
  }, [
    player,
    updatedBookmark,
    bookmarkData,
    player.playerControls.state,
    episode,
    onPopulated,
    podcast,
  ])

  const handlePlayStart = useCallback(
    (position: number) => {
      onPopulated()
      if (!updatedBookmark || !bookmarkData) return

      player.playClip({
        startSec: position,
        endSec: position + PREVIEW_START_END_DURATION_SEC,
        media: {
          episode,
          podcast,
        },
        forceStop: true,
      })
    },
    [
      updatedBookmark,
      player,
      bookmarkData,
      enqueueSnackbar,
      episode,
      onPopulated,
      podcast,
    ]
  )

  const handlePlayEnd = useCallback(
    (position: number) => {
      onPopulated()
      if (!updatedBookmark?.endPositionSec || !bookmarkData) return

      player.playClip({
        startSec: Math.max(position - PREVIEW_START_END_DURATION_SEC, 0),
        endSec: position,
        media: {
          episode,
          podcast,
        },
        forceStop: true,
      })
    },
    [
      updatedBookmark,
      player,
      bookmarkData,
      enqueueSnackbar,
      episode,
      onPopulated,
      podcast,
    ]
  )

  const handlePositionStep = useCallback(
    (step: number) => () => {
      onPopulated()
      const newPosition = valueInBoundary({
        value: updatedBookmark.positionSec + step,
        min: 0,
        max:
          (updatedBookmark.endPositionSec
            ? updatedBookmark.endPositionSec - 1
            : episode?.episodeLength) || Number.MAX_VALUE,
      })

      setUpdatedBookmark((current) => {
        if (!current) return current
        return {
          ...current,
          positionSec: newPosition,
        }
      })
      handlePlayStart(newPosition)
    },
    [setUpdatedBookmark, updatedBookmark, handlePlayStart, episode, onPopulated]
  )

  const handleEndPositionStep = useCallback(
    (step: number) => () => {
      onPopulated()
      if (!updatedBookmark.endPositionSec) return

      const newEndPosition = valueInBoundary({
        value: updatedBookmark.endPositionSec + step,
        min: updatedBookmark.positionSec + 1,
        max: episode?.episodeLength || Number.MAX_VALUE,
      })

      setUpdatedBookmark((current) => {
        if (!current || !current.endPositionSec) return current
        return {
          ...current,
          endPositionSec: newEndPosition,
        }
      })
      handlePlayEnd(newEndPosition)
    },
    [setUpdatedBookmark, updatedBookmark, handlePlayEnd, episode, onPopulated]
  )

  const convertToClip = useCallback(() => {
    onPopulated()
    setUpdatedBookmark((old) => {
      if (!old) return old
      return {...old, endPositionSec: old.positionSec + DEFAULT_CLIP_LENGTH}
    })
  }, [setUpdatedBookmark, onPopulated])

  const convertToBookmark = useCallback(() => {
    onPopulated()
    setUpdatedBookmark((old) => {
      if (!old) return old
      return {...old, endPositionSec: undefined}
    })
  }, [setUpdatedBookmark, onPopulated])

  const handleEndPositionChange = useCallback(
    (time) => {
      onPopulated()
      const newPosition = Math.max(time, updatedBookmark.positionSec + 1)
      setUpdatedBookmark((old) => {
        if (!old) return old
        return {...old, endPositionSec: newPosition}
      })
      handlePlayEnd(newPosition)
    },
    [setUpdatedBookmark, handlePlayEnd, updatedBookmark, onPopulated]
  )

  const handlePositionChange = useCallback(
    (endTime) => {
      onPopulated()
      if (!updatedBookmark.endPositionSec) return

      const newPosition = Math.min(
        endTime,
        updatedBookmark.endPositionSec ? updatedBookmark.endPositionSec - 1 : 0
      )

      setUpdatedBookmark((old) => {
        if (!old) return old
        return {
          ...old,
          positionSec: newPosition,
        }
      })

      handlePlayEnd(newPosition)
    },
    [
      setUpdatedBookmark,
      bookmarkData,
      handlePlayStart,
      updatedBookmark,
      onPopulated,
      handlePlayEnd,
    ]
  )

  const onDone = useCallback(() => {
    onPopulated()
    analytics.logEvent({
      eventName: 'bookmark_edited',
      params: {
        adjusted_amount: bookmarkData
          ? updatedBookmark.positionSec - bookmark.positionSec
          : 'Should not happen',
        text_adjusted:
          updatedBookmark.description !== bookmarkData?.bookmark.description,
      },
    })

    updateBookmark(updatedBookmark)
      .then(() => {
        enqueueSnackbar(
          `${updatedBookmark?.endPositionSec ? 'Clip' : 'Bookmark'} updated`,
          {
            variant: 'success',
          }
        )
        onFinish(updatedBookmark)
      })
      .catch((e) => {
        console.error('Error while updating bookmark', e)
        enqueueSnackbar(
          `Error while updating ${
            updatedBookmark?.endPositionSec ? 'clip' : 'bookmark'
          }. Please try again`,
          {
            variant: 'error',
          }
        )
      })
  }, [
    analytics,
    bookmarkData,
    updatedBookmark,
    onFinish,
    updateBookmark,
    enqueueSnackbar,
    bookmark.positionSec,
  ])

  return (
    <div className={classes.inner}>
      <div>
        <Typography variant="h5">
          {!updatedBookmark?.endPositionSec ? 'Update bookmark' : 'Update clip'}
        </Typography>
      </div>
      {!updatedBookmark?.endPositionSec && (
        <BookmarkControls
          mediaPosition={updatedBookmark?.positionSec || 0}
          timeLabel="Time"
          bookmarkData={bookmarkData}
          handleStepChange={handlePositionStep}
          handlePreview={() => handlePlayStart(updatedBookmark.positionSec)}
          step5
        />
      )}

      {updatedBookmark?.endPositionSec && (
        <div>
          <BookmarkControls
            mediaPosition={updatedBookmark?.positionSec || 0}
            timeLabel="Starts At"
            bookmarkData={bookmarkData}
            handleStepChange={handlePositionStep}
            handlePositionChange={handlePositionChange}
            handlePreview={() => handlePlayStart(updatedBookmark.positionSec)}
            step5
          />
          <BookmarkControls
            mediaPosition={updatedBookmark?.endPositionSec || 0}
            timeLabel="Ends At"
            bookmarkData={bookmarkData}
            handleStepChange={handleEndPositionStep}
            handlePositionChange={handleEndPositionChange}
            handlePreview={() => {
              if (updatedBookmark.endPositionSec)
                handlePlayEnd(updatedBookmark.endPositionSec)
            }}
            step5
          />
        </div>
      )}

      <Button variant="outlined" color="primary" onClick={playFromBookmarkTime}>
        {updatedBookmark?.endPositionSec ? 'Start clip' : 'Start Bookmark'}
      </Button>

      <TextField
        value={updatedBookmark?.description}
        onFocus={onPopulated}
        onChange={(e) => {
          onPopulated()
          const {value} = e.target
          setUpdatedBookmark((current: Bookmark) => {
            return {
              ...current,
              description: value,
            }
          })
        }}
        variant="outlined"
        fullWidth
        label="Description"
        multiline
        rows="2"
      />
      <div className={classes.buttons}>
        {updatedBookmark?.endPositionSec ? (
          <Button
            onClick={convertToBookmark}
            variant="outlined"
            color="primary"
          >
            Convert to bookmark
          </Button>
        ) : (
          <Button onClick={convertToClip} variant="outlined" color="primary">
            Convert to clip
          </Button>
        )}
        <Button onClick={onDone} variant="contained" color="primary">
          Done
        </Button>
      </div>
    </div>
  )
}

export default EditBookmarkForm

EditBookmarkForm.defaultProps = {
  onPopulated: () => undefined,
}

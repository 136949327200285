import {createContext, useContext} from 'react'
import {Podcast} from '../../apis/listenNotes/models'
import EpisodeMedia from '../ResolveMedia/EpisodeMedia'
import {Bookmark} from '../../db/models'

interface EditBookmarkPopupContext {
  openBookmarkDialog: (args: {
    bookmark: Bookmark
    episode: EpisodeMedia
    podcast: Podcast
    autoHide: boolean
  }) => void
  editing: boolean
  close: () => void
}

const editBookmarkPopupContext = createContext<EditBookmarkPopupContext>({
  openBookmarkDialog: () => undefined,
  editing: false,
  close: () => undefined,
})

export default editBookmarkPopupContext

export function useEditBookmarkPopup() {
  return useContext(editBookmarkPopupContext)
}

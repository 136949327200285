import React, {useState, useEffect} from 'react'
import {Chip, createStyles, makeStyles, Typography} from '@material-ui/core'
import {UserRecord} from '../models'
import UserMediaItem from './UserMediaItem'
import {MediaType} from '..'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    filters: {
      maxWidth: '100%',
      margin: theme.spacing(0, -1),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    listContainer: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
    searchBar: {
      position: 'sticky',
      top: 65,
      background: '#fafafa',
      zIndex: 100,
    },
  })
)

type FilterState =
  | 'all'
  | 'notFinished'
  | 'finished'
  | 'favorite'
  | 'addedManually'
interface Props {
  media: UserRecord[]
  mediaType: MediaType
  searchTerm?: string
}

function UserMediaList({media, mediaType}: Props) {
  const classes = useStyles()
  const [currentFilter, setCurrentFilter] = useState<FilterState>('all')
  const [finalMedia, setFinalMedia] = useState(media)

  useEffect(() => {
    let updatedMedia = []
    if (currentFilter === 'all') updatedMedia = media
    else
      updatedMedia = media.filter((one) => {
        if (currentFilter === 'finished')
          return one.lastPlayedLocation >= one.episode.episodeLength
        if (currentFilter === 'notFinished')
          return one.lastPlayedLocation <= one.episode.episodeLength
        if (currentFilter === 'favorite') return one.favorite === true
        if (currentFilter === 'addedManually') return one.episode.addedManually
        return false
      })
    setFinalMedia(updatedMedia)
  }, [media, currentFilter])

  // useEffect(() => {
  //   if (searchString) {
  //     const updatedMedia = finalMedia.filter(
  //       (item) =>
  //         item.episode.title?.toLowerCase().includes(searchString) ||
  //         item.episode.podcastName?.toLowerCase().includes(searchString) ||
  //         item.bookmarks?.find((bookmark) =>
  //           bookmark.description.toLowerCase().includes(searchString)
  //         )
  //     )
  //     setFinalMedia(updatedMedia)
  //     history.replace(`/library?search=${searchString}`, {
  //       searchTerm: searchString,
  //     })
  //   } else {
  //     setFinalMedia(media)
  //   }
  // }, [searchString])

  return (
    <div className={classes.root}>
      {mediaType === MediaType.MY_LIBRARY && (
        <div className={classes.filters}>
          <Chip
            color={currentFilter === 'all' ? 'primary' : 'default'}
            label="All"
            onClick={() => setCurrentFilter('all')}
          />
          <Chip
            color={currentFilter === 'notFinished' ? 'primary' : 'default'}
            label="Not finished"
            onClick={() => setCurrentFilter('notFinished')}
          />
          <Chip
            color={currentFilter === 'finished' ? 'primary' : 'default'}
            label="Finished"
            onClick={() => setCurrentFilter('finished')}
          />
          <Chip
            color={currentFilter === 'favorite' ? 'primary' : 'default'}
            label="Favorite"
            onClick={() => setCurrentFilter('favorite')}
          />
          <Chip
            color={currentFilter === 'addedManually' ? 'primary' : 'default'}
            label="Manually added"
            onClick={() => setCurrentFilter('addedManually')}
          />
        </div>
      )}
      {mediaType === MediaType.POPULAR && (
        <Typography variant="h6">
          Explore curated bookmarks and clips created by our editors.
        </Typography>
      )}
      <div className={classes.listContainer}>
        {finalMedia.map((oneMedia) => (
          <UserMediaItem
            key={oneMedia.episode.id}
            record={oneMedia}
            mediaType={mediaType}
          />
        ))}
      </div>
    </div>
  )
}

export default UserMediaList

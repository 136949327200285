import React, {useCallback} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface Props {
  title: string
  description: string
  open: boolean
  onClose: (action: string, data: any) => void
  data: any
  actionPositiveText?: string
  actionNegativeText?: string
}
export default function AlertDialog({
  title,
  description,
  open,
  onClose,
  data,
  actionPositiveText = 'yes',
  actionNegativeText = 'no',
}: Props) {
  const handleClose = useCallback(
    (action: string) => {
      onClose(action, data)
    },
    [onClose, data]
  )
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(actionNegativeText)}
            color="primary"
          >
            {actionNegativeText}
          </Button>
          <Button
            onClick={() => handleClose(actionPositiveText)}
            color="primary"
            autoFocus
          >
            {actionPositiveText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React, {useRef, useEffect, useCallback, useState} from 'react'

interface Props {
  className?: string
  children: React.ReactNode
  mode: 'calculateHeight' | 'calculateWidth'
}

function RectangularBox({children, className, mode}: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [calculatedDimension, setCalculatedDimension] = useState(0)

  const onResize = useCallback(() => {
    const ref = containerRef.current
    if (!ref) return

    setCalculatedDimension(
      (mode === 'calculateHeight' ? ref.offsetWidth : ref.offsetHeight) || 0
    )
  }, [containerRef, setCalculatedDimension, mode])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return (
    <div
      ref={containerRef}
      style={{
        [`${
          mode === 'calculateHeight' ? 'height' : 'width'
        }`]: calculatedDimension,
      }}
      className={className}
    >
      {children}
    </div>
  )
}

export default RectangularBox

import {createContext} from 'react'
import {Layout} from '../InsideScreen/components/LayoutSwitch'

interface VideoPosition {
  top: number
  bottom: number
  left: number
  right: number
}

export interface Settings {
  playbackSpeed: number
  navigateToBookmarks: boolean
  clipLength: number
  sharing: boolean
  forwardStepSizeSec: number
  backwardStepSizeSec: number
  expandedVideoPositionPercent: VideoPosition
  layout: Layout
}
export interface SettingsContext {
  settingsData: Settings
  setPlaybackSpeed: (playbackSpeed: number) => void
  increasePlaybackSpeed: () => void
  decreasePlaybackSpeed: () => void
  showSettingsDialog: (show: boolean) => void
  navigateToBookmarks: (navigate: boolean) => void
  stopAfterClipEnds: boolean
  setStopAfterClipEnds: (value: boolean) => void
  setClipLength: (value: number) => void
  setForwardStepSizeSec: (value: number) => void
  setBackwardStepSizeSec: (value: number) => void
  setExpandedVideoPosition: (v: VideoPosition) => void
  setLayout: (v: Layout) => void
}

const emptyValue: SettingsContext = {
  settingsData: {
    playbackSpeed: 1,
    navigateToBookmarks: false,
    clipLength: 30,
    sharing: false,
    forwardStepSizeSec: 5,
    backwardStepSizeSec: 5,
    expandedVideoPositionPercent: {top: 0, bottom: 0, left: 0, right: 0},
    layout: Layout.FULL,
  },
  setPlaybackSpeed: () => {},
  increasePlaybackSpeed: () => {},
  decreasePlaybackSpeed: () => {},
  showSettingsDialog: () => {},
  navigateToBookmarks: () => {},
  stopAfterClipEnds: false,
  setStopAfterClipEnds: () => {},
  setClipLength: () => {},
  setBackwardStepSizeSec: () => {},
  setForwardStepSizeSec: () => {},
  setExpandedVideoPosition: () => {},
  setLayout: () => {},
}

const settingsContext = createContext<SettingsContext>(emptyValue)

export default settingsContext

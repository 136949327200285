import React, {useCallback, useContext, useEffect, useState} from 'react'
import myContext, {SettingsContext} from './context'
import SettingsModal from '../SettingsScreen/SettingsModal'
import {getUserSettings, setUserSettings} from '../../db'
import {useAuth} from '../AuthProvider'
import {UserRecordFromDb} from '../../db/models'
import {Layout} from '../InsideScreen/components/LayoutSwitch'

interface Props {
  children: React.ReactNode
}

const PLAYBACK_STEP = 0.1
const PLAYBACK_MAX_SPEED = 3
const PLAYBACK_MIN_SPEED = 0.5
const DEFAULT_PLAYBACK_SPEED = 1.0
export const DEFAULT_CLIP_LENGTH = 30
const FORWARD_STEP_SIZE_SEC = 5
const BACKWARD_STEP_SIZE_SEC = 5
const EXPANDED_VIDEO_POSITION = {top: 100, bottom: 0, left: 50, right: 100}

// This should be handled by listening to firebase changes and not storing the values in state...

function SettingsProvider({children}: Props) {
  const [settings, setSettings] = useState<UserRecordFromDb | undefined>(
    undefined
  )

  const {
    authData: {isLoggedIn},
  } = useAuth()

  const [navigateToBookmarks, setNavigateToBookmarks] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [stopAfterClipEnds, setStopAfterClipEnds] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    getUserSettings().then(setSettings)
  }, [isLoggedIn, setSettings])

  useEffect(() => {
    if (settings) setUserSettings(settings)
  }, [settings])

  const increasePlaybackSpeed = useCallback(() => {
    setSettings((old) => {
      if (!old) return
      const playbackSpeed = old.playbackRate
      if (playbackSpeed !== undefined) {
        let newPlaybackRate =
          playbackSpeed >= PLAYBACK_MAX_SPEED
            ? playbackSpeed
            : playbackSpeed + PLAYBACK_STEP
        newPlaybackRate = Number(newPlaybackRate.toFixed(2))
        return {...old, playbackRate: newPlaybackRate}
      }
    })
  }, [setSettings])

  const decreasePlaybackSpeed = useCallback(() => {
    setSettings((old) => {
      if (!old) return
      const playbackSpeed = old.playbackRate
      if (playbackSpeed !== undefined) {
        let newPlaybackRate =
          playbackSpeed <= PLAYBACK_MIN_SPEED
            ? playbackSpeed
            : playbackSpeed - PLAYBACK_STEP
        newPlaybackRate = Number(newPlaybackRate.toFixed(2))
        return {...old, playbackRate: newPlaybackRate}
      }
    })
  }, [setSettings])

  const contextValue: SettingsContext = {
    stopAfterClipEnds:
      stopAfterClipEnds === undefined ? true : stopAfterClipEnds,
    setStopAfterClipEnds,
    settingsData: {
      playbackSpeed: settings?.playbackRate || DEFAULT_PLAYBACK_SPEED,
      navigateToBookmarks,
      clipLength: settings?.defaultClipLength || DEFAULT_CLIP_LENGTH,
      sharing: false,
      forwardStepSizeSec: settings?.forwardStepSizeSec || FORWARD_STEP_SIZE_SEC,
      backwardStepSizeSec:
        settings?.backwardStepSizeSec || BACKWARD_STEP_SIZE_SEC,
      expandedVideoPositionPercent: {
        top:
          settings?.expandedVideoTop !== undefined
            ? settings?.expandedVideoTop
            : EXPANDED_VIDEO_POSITION.top,
        bottom:
          settings?.expandedVideoBottom !== undefined
            ? settings?.expandedVideoBottom
            : EXPANDED_VIDEO_POSITION.bottom,
        left:
          settings?.expandedVideoLeft !== undefined
            ? settings?.expandedVideoLeft
            : EXPANDED_VIDEO_POSITION.left,
        right:
          settings?.expandedVideoRight !== undefined
            ? settings?.expandedVideoRight
            : EXPANDED_VIDEO_POSITION.right,
      },
      layout: settings?.layout || Layout.FULL,
    },
    setPlaybackSpeed: (newValue: number) => {
      setSettings((old) => {
        if (!old) return
        return {...old, playbackRate: newValue}
      })
    },
    increasePlaybackSpeed,
    decreasePlaybackSpeed,
    setClipLength: useCallback(
      (value) => {
        setSettings((old) => {
          if (!old) return
          return {...old, defaultClipLength: value}
        })
      },
      [setSettings]
    ),
    showSettingsDialog: setShowDialog,
    navigateToBookmarks: setNavigateToBookmarks,
    setExpandedVideoPosition: useCallback(
      ({top, bottom, left, right}) => {
        setSettings((old) => {
          if (!old) return undefined
          return {
            ...old,
            expandedVideoTop: top,
            expandedVideoBottom: bottom,
            expandedVideoLeft: left,
            expandedVideoRight: right,
          }
        })
      },
      [setSettings]
    ),
    setForwardStepSizeSec: useCallback(
      (value) => {
        setSettings((old) => {
          if (!old) return
          return {...old, forwardStepSizeSec: value}
        })
      },
      [setSettings]
    ),
    setBackwardStepSizeSec: useCallback(
      (value) => {
        setSettings((old) => {
          if (!old) return
          return {...old, backwardStepSizeSec: value}
        })
      },
      [setSettings]
    ),
    setLayout: useCallback(
      (v) =>
        setSettings((old) => {
          if (!old) return
          console.log('AAA', v)
          return {...old, layout: v}
        }),
      [setSettings]
    ),
  }

  return (
    <myContext.Provider value={contextValue}>
      <SettingsModal
        showModal={showDialog}
        onClose={useCallback(() => setShowDialog(false), [])}
      />
      {children}
    </myContext.Provider>
  )
}

export default SettingsProvider

export function useSettings() {
  return useContext(myContext)
}

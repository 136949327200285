import React from 'react'
import {NavLink} from 'react-router-dom'
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core'
import {Section} from '../../../sections'
import {useAuth} from '../../AuthProvider'
import {useAnalytics} from '../../../utils/analytics'
import {useEditBookmarkPopup} from '../../EditBookmarkPopup'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    filler: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    active: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    externalLink: {
      textDecoration: 'none',
      color: 'black',
    },
    versionName: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
    versionNameText: {
      color: 'gray',
    },
  })
)

function SectionsList({sections}: {sections: Section[]}) {
  const classes = useStyles()
  const auth = useAuth()
  const analytics = useAnalytics()

  const bookmarkPopup = useEditBookmarkPopup()

  return (
    <List>
      {sections.map((section) => {
        if (!section.navigation) return null
        if (section.loginRequired && !auth.isLoggedIn()) return null

        if (section.navigation.external) {
          const {externalLink} = section.navigation
          if (!externalLink) return null

          return (
            <ListItem
              key={section.paths.join()}
              button
              onClick={() => {
                analytics.logEvent({
                  eventName: 'external_link_clicked',
                  params: {link: externalLink},
                })
                window.open(externalLink, '_blank')
              }}
            >
              <ListItemIcon>
                <section.navigation.Icon />
              </ListItemIcon>

              <ListItemText>{section.navigation.label}</ListItemText>
            </ListItem>
          )
        }

        return (
          <ListItem
            key={section.paths.join()}
            button
            component={NavLink}
            exact={section.exact}
            onClick={bookmarkPopup.close}
            activeClassName={classes.active}
            to={section.navigation.path}
            data-tut={section.tourSelector}
          >
            <ListItemIcon>
              <section.navigation.Icon />
            </ListItemIcon>
            <ListItemText>{section.navigation.label}</ListItemText>
          </ListItem>
        )
      })}
    </List>
  )
}

interface Props {
  sections: Section[]
  toggleDrawer: (open: boolean) => void
}

function NavigationList({sections, toggleDrawer}: Props) {
  const classes = useStyles()
  // eslint-disable-next-line no-restricted-globals

  const mainSections = sections.filter(
    (section) => section.navigation?.section === 'main'
  )
  const bottomSections = sections.filter(
    (section) => section.navigation?.section === 'bottom'
  )
  return (
    <div
      className={classes.root}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <SectionsList sections={mainSections} />
      </List>
      <div className={classes.filler} />
      <Divider />
      <List>
        <SectionsList sections={bottomSections} />
      </List>
      {process.env.REACT_APP_CI_COMMIT_SHORT_SHA && (
        <div className={classes.versionName}>
          <Typography className={classes.versionNameText} variant="caption">
            Version: {process.env.REACT_APP_CI_COMMIT_SHORT_SHA}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default NavigationList

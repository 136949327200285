/* eslint-disable react/jsx-curly-newline */
import React, {useState, useEffect, useCallback} from 'react'
import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  createStyles,
  Switch,
  Paper,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {useSettings} from '../SettingsProvider'

const useStyles = makeStyles(() =>
  createStyles({
    clipLengthInput: {
      width: '50px',
    },
    settingData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    settingValue: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    error: {
      position: 'absolute',
      right: 10,
      margin: '5px',
    },
  })
)

function SettingsScreen() {
  const classes = useStyles()
  const settings = useSettings()
  const [clipLength, setClipLength] = useState<number>(
    settings.settingsData.clipLength
  )

  useEffect(() => {
    setClipLength(settings.settingsData.clipLength)
  }, [settings.settingsData.clipLength])

  const onFasterClicked = useCallback(() => {
    settings.increasePlaybackSpeed()
  }, [settings.increasePlaybackSpeed])

  const onSlowerClicked = useCallback(() => {
    settings.decreasePlaybackSpeed()
  }, [settings.decreasePlaybackSpeed])

  const onClipLengthChanged = (e: any) => {
    if (e.target.value > 5) settings.setClipLength(e.target.value)
    setClipLength(e.target.value)
  }

  return (
    <Paper elevation={0}>
      <Container>
        <Box my={2}>
          <Typography variant="h5">Settings</Typography>
        </Box>

        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">Pick a playback speed</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingValue}>
              <IconButton onClick={onSlowerClicked}>
                <ArrowDownwardIcon />
              </IconButton>
              <Typography variant="h5">
                {settings.settingsData.playbackSpeed.toFixed(1)}x
              </Typography>
              <IconButton onClick={onFasterClicked}>
                <ArrowUpwardIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">Clip length</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={clipLength}
                  className={classes.clipLengthInput}
                  type="number"
                  onChange={onClipLengthChanged}
                />
                <Typography variant="caption"> seconds</Typography>
              </div>
              {clipLength < 5 && (
                <Typography variant="caption" color="error">
                  Can not be smaller than 5s
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Stop playing after clip ends{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingValue}>
              <Switch
                checked={settings.stopAfterClipEnds}
                color="primary"
                onChange={(e) =>
                  settings.setStopAfterClipEnds(e.target.checked)
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">Forward step size in seconds</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={settings.settingsData.forwardStepSizeSec}
                  className={classes.clipLengthInput}
                  type="number"
                  onChange={(e) =>
                    settings.setForwardStepSizeSec(Number(e.target.value))
                  }
                />
                <Typography variant="caption"> seconds</Typography>
              </div>
              {settings.settingsData.forwardStepSizeSec < 1 && (
                <Typography variant="caption" color="error">
                  Can not be smaller than 1s
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Backward step size in seconds
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={settings.settingsData.backwardStepSizeSec}
                  className={classes.clipLengthInput}
                  type="number"
                  onChange={(e) =>
                    settings.setBackwardStepSizeSec(Number(e.target.value))
                  }
                />
                <Typography variant="caption"> seconds</Typography>
              </div>
              {settings.settingsData.backwardStepSizeSec < 1 && (
                <Typography variant="caption" color="error">
                  Can not be smaller than 1s
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Top position of expanded video in percents
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={settings.settingsData.expandedVideoPositionPercent.top}
                  className={classes.clipLengthInput}
                  onChange={(e) => {
                    const top = Number(e.target.value)
                    settings.setExpandedVideoPosition({
                      top,
                      bottom:
                        settings.settingsData.expandedVideoPositionPercent
                          .bottom,
                      left:
                        settings.settingsData.expandedVideoPositionPercent.left,
                      right:
                        settings.settingsData.expandedVideoPositionPercent
                          .right,
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Bottom position of expanded video in percents
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={
                    settings.settingsData.expandedVideoPositionPercent.bottom
                  }
                  className={classes.clipLengthInput}
                  onChange={(e) => {
                    const bottom = Number(e.target.value)
                    settings.setExpandedVideoPosition({
                      bottom,
                      top:
                        settings.settingsData.expandedVideoPositionPercent.top,
                      left:
                        settings.settingsData.expandedVideoPositionPercent.left,
                      right:
                        settings.settingsData.expandedVideoPositionPercent
                          .right,
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Left position of expanded video in percents
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={
                    settings.settingsData.expandedVideoPositionPercent.left
                  }
                  className={classes.clipLengthInput}
                  onChange={(e) => {
                    const left = Number(e.target.value)
                    settings.setExpandedVideoPosition({
                      left,
                      bottom:
                        settings.settingsData.expandedVideoPositionPercent
                          .bottom,
                      top:
                        settings.settingsData.expandedVideoPositionPercent.top,
                      right:
                        settings.settingsData.expandedVideoPositionPercent
                          .right,
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Box my={2}>
              <Typography variant="h6">
                Right position of expanded video in percents
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.settingData}>
              <div className={classes.settingValue}>
                <TextField
                  value={
                    settings.settingsData.expandedVideoPositionPercent.right
                  }
                  className={classes.clipLengthInput}
                  onChange={(e) => {
                    const right = Number(e.target.value)
                    settings.setExpandedVideoPosition({
                      right,
                      bottom:
                        settings.settingsData.expandedVideoPositionPercent
                          .bottom,
                      left:
                        settings.settingsData.expandedVideoPositionPercent.left,
                      top:
                        settings.settingsData.expandedVideoPositionPercent.top,
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default SettingsScreen

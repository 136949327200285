import {useCallback, useEffect, useRef} from 'react'
import {ControlledPlayerContext} from '../../ControlledPlayer/context'
import {PlayingMedia} from '../index'
import {useSettings} from '../../SettingsProvider'

interface Clip {
  start: number
  end: number
  media: PlayingMedia
}

function useStopClipManager({
  currentlyPlayingMedia,
  player,
}: {
  currentlyPlayingMedia: PlayingMedia | null
  player: ControlledPlayerContext
}) {
  const playingClip = useRef<{clip: Clip; forceStop?: boolean} | null>(null)
  const {stopAfterClipEnds} = useSettings()

  useEffect(() => {
    return player.registerOnPlayedListener((position) => {
      if (!position || !playingClip.current) return

      if (position > playingClip.current.clip.end) {
        if (stopAfterClipEnds || playingClip.current.forceStop) player.pause()
        playingClip.current = null
      }
    })
  }, [player, playingClip, stopAfterClipEnds])

  useEffect(() => {
    return player.registerOnUserSeekingListener(() => {
      playingClip.current = null
    })
  }, [player, playingClip])

  useEffect(() => {
    if (
      playingClip.current &&
      playingClip.current.clip.media.episode.id !==
        currentlyPlayingMedia?.episode.id
    ) {
      playingClip.current = null
    }
  }, [playingClip, currentlyPlayingMedia])

  const registerToStop = useCallback(
    (clip: Clip, forceStop?: boolean) => {
      playingClip.current = {clip, forceStop}
    },
    [currentlyPlayingMedia, player]
  )

  const clearStop = useCallback(() => {
    playingClip.current = null
  }, [playingClip])

  return {registerToStop, clearStop}
}

export default useStopClipManager

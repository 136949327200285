import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {SnackbarProvider} from 'notistack'
import {ThemeProvider, createMuiTheme, CssBaseline} from '@material-ui/core'
import {firestore, auth} from 'firebase'
import RootRouter from './components/RootRouter'
import AuthProvider from './components/AuthProvider'
import DisplayLoadingUtilUserIsSet from './components/DisplayLoadingUtilUserIsSet'

const muiTheme = createMuiTheme({})

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Router>
          <CssBaseline />
          <DisplayLoadingUtilUserIsSet>
            <AuthProvider>
              <RootRouter />
            </AuthProvider>
          </DisplayLoadingUtilUserIsSet>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App

// @ts-ignore
window.firestore = firestore
// @ts-ignore
window.auth = auth

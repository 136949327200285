import {createContext} from 'react'
import {remoteConfig} from 'firebase'

export interface AuthProps {
  modalTitle?: string
  onAuthResult?: () => {}
}

export interface AuthContext {
  authData: {
    isLoggedIn: boolean
    user: firebase.User | null
    deviceId: string
  }
  isLoggedIn: () => boolean
  authenticateUser: (authProps: AuthProps) => void
  curatedUserId: string
  logout: () => void
}

const authContextValue: AuthContext = {
  authData: {isLoggedIn: false, user: null, deviceId: ''},
  authenticateUser: () => {},
  curatedUserId: remoteConfig().getValue('guest_user_id').asString(),
  isLoggedIn: () => false,
  logout: () => {},
}

const authContext = createContext<AuthContext>(authContextValue)

export default authContext

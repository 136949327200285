import React, {useMemo} from 'react'

function parseTime(timeSec: number) {
  const sec = Math.floor(timeSec % 60)
  const mins = Math.floor(timeSec / 60) % 60
  const hours = Math.floor(timeSec / 3600)

  const secToDisplay = sec < 10 ? `0${sec}` : sec
  const minsToDisplay = mins < 10 ? `0${mins}` : mins
  const hoursToDisplay = hours < 10 ? `0${hours}` : hours

  return `${hoursToDisplay}:${minsToDisplay}:${secToDisplay}`
}

interface Props {
  className?: string
  inSeconds: number
}

function ReadableTime({className, inSeconds}: Props) {
  const toDisplay = useMemo(() => {
    return parseTime(inSeconds)
  }, [inSeconds])
  return <span className={className}>{toDisplay}</span>
}

export default ReadableTime

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {TextField} from '@material-ui/core'
import {
  convertStringToSeconds,
  convertToReadableString,
} from '../../EpisodeScreen/utils'

interface Props {
  valueSec: number
  onChange: (sec: number) => void
  label?: string
  className?: string
}

function FormattedTimeInput({valueSec, label, onChange, className}: Props) {
  const formattedValue = useMemo(() => convertToReadableString(valueSec), [
    valueSec,
  ])

  const [value, setValue] = useState(formattedValue)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setValue(convertToReadableString(valueSec))
  }, [setValue, valueSec])

  const applyChange = useCallback(() => {
    if (hasError) return
    const numberSec = convertStringToSeconds(value)
    onChange(numberSec)
  }, [onChange, value])

  const handleChange = useCallback(
    (e: any) => {
      const {value: newValue} = e.target
      setValue(newValue)

      const numberSec = convertStringToSeconds(newValue)
      if (isNaN(numberSec)) {
        setHasError(true)
      } else {
        setHasError(false)
      }
    },
    [setValue, setHasError]
  )

  return (
    <TextField
      error={hasError}
      onBlur={applyChange}
      label={label}
      helperText={hasError ? 'Please format the time in [hh:]mm:ss' : undefined}
      className={className}
      value={value}
      onChange={handleChange}
    />
  )
}

export default FormattedTimeInput

import React, {useState, useCallback, useContext} from 'react'
import sharingControlsContext, {
  SharingControlsContext,
  SharingModal as SharingModalData,
} from './context'
import SharingModal from './SharingModal'

interface Props {
  children: React.ReactNode
}

function SharingControlsProvider({children}: Props) {
  const [showDialog, setShowDialog] = useState(false)
  const [sharingModal, setSharingModal] = useState<
    SharingModalData | undefined
  >(undefined)
  const share = useCallback(
    (data: SharingModalData) => {
      setShowDialog(true)
      setSharingModal(data)
    },
    [setShowDialog, setSharingModal]
  )

  const contextValue: SharingControlsContext = {
    share,
  }

  return (
    <sharingControlsContext.Provider value={contextValue}>
      <SharingModal
        showModal={showDialog}
        sharingModal={sharingModal}
        onClose={useCallback(() => setShowDialog(false), [])}
      />
      {children}
    </sharingControlsContext.Provider>
  )
}

export default SharingControlsProvider

export function useSharingControls() {
  return useContext(sharingControlsContext)
}

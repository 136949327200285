import {createContext} from 'react'
import {
  emptyControlledPlayerContext,
  ControlledPlayerContext,
} from '../ControlledPlayer/context'

import {PlayingMedia} from './index'
import {Podcast} from '../../apis/listenNotes/models'
import EpisodeMedia from '../ResolveMedia/EpisodeMedia'

export interface PlayerContext {
  playingMedia: {podcast: Podcast; episode: EpisodeMedia} | null
  setPlayingMedia: (playingMedia: PlayingMedia) => void
  startPlaying: (props: {
    media: PlayingMedia
    timeSec?: number
    pauseOnLoad?: boolean
  }) => void
  playClip: (args: {
    startSec: number
    endSec: number
    media: PlayingMedia
    forceStop?: boolean
  }) => void
  isPlaying: boolean
  playerControls: ControlledPlayerContext
}

const emptyValue: PlayerContext = {
  playingMedia: null,
  startPlaying: () => undefined,
  setPlayingMedia: () => {},
  playClip: () => undefined,
  playerControls: emptyControlledPlayerContext,
  isPlaying: false,
}

const playerContext = createContext<PlayerContext>(emptyValue)

export default playerContext

import React from 'react'
import {Link} from 'react-router-dom'
import {Typography, makeStyles} from '@material-ui/core'
import {generatePodcastLink} from '../utils/routes'

interface Props {
  title: string
  id?: string
}

const useStyles = makeStyles(() => ({
  title: {
    textDecoration: 'none',
    color: 'inherit',
  },
  container: {
    maxWidth: '180px',
    wordWrap: 'break-word',
  },
}))

export default function PodcastTitle({title, id = ''}: Props) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.container}>
        {id !== '' && (
          <Typography color="textSecondary" noWrap>
            <Link
              className={classes.title}
              to={generatePodcastLink({podcastId: id})}
            >
              {title}
            </Link>
          </Typography>
        )}
        {id === '' && (
          <Typography color="textSecondary" noWrap>
            {title}
          </Typography>
        )}
      </div>
    </>
  )
}

import React from 'react'
import {Typography, Button, makeStyles, createStyles} from '@material-ui/core'
import ReadableTime from '../../ReadableTime'
import {Bookmark} from '../../../db/models'
import {Podcast} from '../../../apis/listenNotes/models'
import EpisodeMedia from '../../ResolveMedia/EpisodeMedia'
import FormattedTimeInput from './FormattedTimeInput'

interface Props {
  mediaPosition: number
  timeLabel?: string
  bookmarkData: {
    bookmark: Bookmark
    episode: EpisodeMedia
    podcast: Podcast
  } | null
  handlePositionChange?: (time: number) => void
  handlePreview?: () => void
  step5?: boolean
  handleStepChange: (step: number) => () => void
}
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    timeButtons: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px',
    },
    speedControlButton: {
      height: '50px',
      margin: '5px',
      textTransform: 'none',
      padding: '0px',
      minWidth: '50px',
    },
    timeInput: {
      width: '70px',
    },
    timeIcon: {
      fontSize: '3rem',
    },
    buttons: {
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
)

export default function BookmarkControls({
  mediaPosition,
  timeLabel,
  handlePositionChange,
  handleStepChange,
  handlePreview,
  step5,
}: Props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {step5 && (
        <Button
          variant="outlined"
          onClick={handleStepChange(-5)}
          color="primary"
          className={classes.speedControlButton}
        >
          -5s
        </Button>
      )}
      <Button
        onClick={handleStepChange(-1)}
        variant="outlined"
        color="primary"
        className={classes.speedControlButton}
      >
        -1s
      </Button>
      <div>
        {handlePositionChange ? (
          <FormattedTimeInput
            valueSec={mediaPosition || 0}
            onChange={handlePositionChange}
            label={timeLabel}
            className={classes.timeInput}
          />
        ) : (
          <div>
            <b>{timeLabel}</b>
            <Typography>
              <ReadableTime inSeconds={mediaPosition || 0} />
            </Typography>
          </div>
        )}
      </div>

      <Button
        onClick={handleStepChange(+1)}
        variant="outlined"
        color="primary"
        size="small"
        className={classes.speedControlButton}
      >
        +1s
      </Button>
      {step5 && (
        <Button
          onClick={handleStepChange(+5)}
          variant="outlined"
          color="primary"
          size="small"
          className={classes.speedControlButton}
        >
          +5s
        </Button>
      )}
      <Button onClick={handlePreview} size="small" color="primary">
        Preview
      </Button>
    </div>
  )
}

import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PauseIcon from '@material-ui/icons/Pause'
import ReplayIcon from '@material-ui/icons/Replay'
import BookMarkIcon from '@material-ui/icons/Bookmark'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import {Link, useHistory} from 'react-router-dom'
import useDimensions from 'react-use-dimensions'
import clsx from 'clsx'
import PlayerProgress, {usePlayer} from './PlayerProgress'
import {useBookmarks} from '../../BookMarksProvider'
import {useAnalytics} from '../../../utils/analytics'
import {useSettings} from '../../SettingsProvider'
import {useCurrentPosition} from '..'
import {useBookmarksForMedia} from '../../../db'
import {useAuth} from '../../AuthProvider'
import PodcastTitle from '../../PodcastTitle'
import {useEditBookmarkPopup} from '../../EditBookmarkPopup'
import {generateEpisodeLink} from '../../../utils/routes'

const COMPACT_MODE_KEY = 'compactMode@playerBar'
const EXPAND_TOGGLE_HEIGHT = '20px'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    textAlign: 'center',
    position: 'fixed',
    paddingTop: EXPAND_TOGGLE_HEIGHT,
    left: 0,
    right: 0,
    bottom: 0,
    boxShadow: theme.shadows[10],
    zIndex: 1200,
  },
  barContainer: {
    padding: theme.spacing(1, 2),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: '2.5rem',
  },
  controlls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  podcast: {
    textDecoration: 'none',
    color: 'inherit',
  },
  podcastTitleContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '25px',
    marginLeft: '25px',
  },
  podcastTitle: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  episode: {
    textDecoration: 'none',
    color: 'inherit',
  },
  expandIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: `calc(100% - ${EXPAND_TOGGLE_HEIGHT})`,

    '& > *': {
      width: '100%',
      height: '100%',
      borderRadius: 0,
    },

    '& svg': {
      fontSize: '30px',
    },
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '3px',
    background: 'white',
  },
  innerProgressBar: {
    display: 'flex',
    height: '100%',
  },
  bookmarksListIcon: {
    marginRight: '5px',
  },
  bookmarksList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  waitingProgress: {
    display: 'inline-flex',
    padding: theme.spacing(1),
  },
  forwardIcon: {
    transform: 'scale(-1, 1)',
  },
  jumpNumber: {
    position: 'absolute',
    fontSize: 9,
    top: 'calc(50% + 2px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  jumpContainer: {
    position: 'relative',
  },
}))

function PlayPauseButton({
  waiting,
  playing,
  play,
  pause,
}: {
  waiting: boolean
  playing: boolean
  play: () => void
  pause: () => void
}) {
  const classes = useStyles()
  if (waiting)
    return (
      <div className={classes.waitingProgress}>
        <CircularProgress />
      </div>
    )
  return (
    <IconButton
      onClick={() => {
        if (playing) pause()
        else play()
      }}
      className={classes.iconButton}
    >
      {playing ? (
        <PauseIcon className={classes.icon} />
      ) : (
        <PlayArrowIcon className={classes.icon} />
      )}
    </IconButton>
  )
}
interface Props {
  className?: string
}

function PlayerComponent({className}: Props) {
  const classes = useStyles()
  const player = usePlayer()
  const theme = useTheme()
  const history = useHistory()
  const auth = useAuth()

  const bookmarkIconRef = useRef(null)
  const [barRef, barDimensions] = useDimensions()
  // const {openBookmarkDialog} = useEditBookmarkPopup()
  const {addBookmark} = useBookmarks()
  const [compactMode, setCompactMode] = useState<undefined | boolean>(undefined)
  // const [marks, setMarks] = useState<Array<{value: number}>>([])
  // const [marksCount, setMarksCount] = useState(0)

  const {currentTime} = useCurrentPosition()
  const settings = useSettings()
  const analytics = useAnalytics()
  const editBookmarkPopup = useEditBookmarkPopup()

  const bookmarksForMedia = useBookmarksForMedia({
    // use 'noting'. That should result in getting empty value since we can
    // somehow safely assume, that there wont be any episode with id 'nothing'
    mediaId: player.playingMedia?.episode.id || 'nothing',
  })

  useEffect(() => {
    if (compactMode === undefined) return
    localStorage.setItem(COMPACT_MODE_KEY, compactMode ? 'true' : 'false')
  }, [compactMode])

  useEffect(() => {
    if (compactMode !== undefined) return
    setCompactMode(localStorage.getItem(COMPACT_MODE_KEY) === 'true')
  }, [setCompactMode])

  const createBookmark = useCallback(
    async (type: 'bookmark' | 'clip') => {
      if (!player.playingMedia || player.playerControls.getPosition() === 0)
        return

      if (!auth.isLoggedIn()) {
        auth.authenticateUser({modalTitle: 'Login to create bookmark'})
        return
      }
      const bookmark = await addBookmark({description: '', type})
      analytics.logEvent({eventName: 'bookmark_created'})
      editBookmarkPopup.openBookmarkDialog({
        bookmark,
        episode: player.playingMedia.episode,
        podcast: player.playingMedia.podcast,
        autoHide: true,
      })
    },
    [player, addBookmark, analytics, editBookmarkPopup]
  )

  const onBookmarkClicked = useCallback(() => {
    return createBookmark('bookmark')
  }, [createBookmark])

  const onClipClicked = useCallback(() => {
    return createBookmark('clip')
  }, [createBookmark])

  const bookmarksListClicked = useCallback(() => {
    if (player.playingMedia != null)
      history.push(
        generateEpisodeLink({episodeId: player.playingMedia.episode.id})
      )
    settings.navigateToBookmarks(true)
  }, [player.playingMedia, history, settings.navigateToBookmarks])

  if (!player.playingMedia) return null
  // if (isYoutubeUrl(player.playingMedia.episode.episodeAudio)) return null

  const audioDuration = player.playerControls.duration || 1

  return (
    <>
      <div
        style={{
          height: barDimensions.height || 0,
          width: '100%',
          marginTop: '20px',
        }}
      />
      <div ref={barRef} className={`${classes.root} ${className}`}>
        {compactMode && (
          <div className={classes.progressBar}>
            <div
              className={classes.innerProgressBar}
              style={{
                width: `${((currentTime || 0) / audioDuration) * 100}%`,
                backgroundColor: theme.palette.primary.main,
              }}
            />
          </div>
        )}

        <div className={classes.barContainer}>
          <div className={classes.expandIcon}>
            <IconButton onClick={() => setCompactMode(!compactMode)}>
              {compactMode === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>

          <Slide direction="up" in={!compactMode}>
            {!compactMode ? (
              <div className={classes.podcastTitleContainer}>
                <Link
                  className={classes.podcast}
                  to={generateEpisodeLink({
                    episodeId: player.playingMedia.episode.id,
                  })}
                >
                  <Typography variant="h6" noWrap>
                    {player.playingMedia?.episode.title}
                  </Typography>
                </Link>

                <div className={classes.podcastTitle}>
                  <PodcastTitle
                    title={player.playingMedia?.podcast.title}
                    id={player.playingMedia?.podcast.id}
                  />
                </div>
              </div>
            ) : (
              <div />
            )}
          </Slide>

          {player.playerControls.duration === undefined ? (
            <CircularProgress />
          ) : (
            <>
              <div>
                {!compactMode && (
                  <PlayerProgress
                    onBookmarkIconClicked={bookmarksListClicked}
                    player={player}
                    bookmarks={bookmarksForMedia || []}
                  />
                )}
              </div>
              <div className={classes.controlls}>
                <IconButton
                  onClick={() => {
                    settings.showSettingsDialog(true)
                  }}
                  className={classes.iconButton}
                >
                  {settings.settingsData.playbackSpeed.toFixed(1)}x
                </IconButton>
                <IconButton
                  onClick={() => {
                    const currentPosition = player.playerControls.getPosition()
                    if (!currentPosition && currentPosition !== 0) return
                    const newTime =
                      currentPosition -
                      settings.settingsData.backwardStepSizeSec
                    player.playerControls.seek(newTime)
                  }}
                  className={clsx(classes.iconButton, classes.jumpContainer)}
                >
                  <span className={classes.jumpNumber}>
                    {settings.settingsData.backwardStepSizeSec}
                  </span>
                  <ReplayIcon className={classes.icon} />
                </IconButton>
                <PlayPauseButton
                  playing={player.playerControls.state === 'playing'}
                  waiting={player.playerControls.waiting}
                  play={player.playerControls.play}
                  pause={player.playerControls.pause}
                />
                <IconButton
                  onClick={() => {
                    const currentPosition = player.playerControls.getPosition()
                    if (!currentPosition && currentPosition !== 0) return
                    const newTime =
                      currentPosition + settings.settingsData.forwardStepSizeSec
                    player.playerControls.seek(newTime)
                  }}
                  className={clsx(classes.iconButton, classes.jumpContainer)}
                >
                  <span className={classes.jumpNumber}>
                    {settings.settingsData.forwardStepSizeSec}
                  </span>
                  <ReplayIcon
                    className={clsx(classes.icon, classes.forwardIcon)}
                  />
                </IconButton>
                <IconButton
                  ref={bookmarkIconRef}
                  data-tut="reactour__iso"
                  onClick={onBookmarkClicked}
                  className={classes.iconButton}
                >
                  <BookMarkIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  onClick={onClipClicked}
                  className={classes.iconButton}
                >
                  <GraphicEqIcon className={classes.icon} />
                </IconButton>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PlayerComponent

import React, {useState} from 'react'
import Truncate from 'react-truncate'
import {Button, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  moreLessButton: {
    display: 'inline',
  },
}))

interface Props {
  numLines: number
  children: React.ReactNode
}
function ClippedContent({numLines, children}: Props) {
  const classes = useStyles()
  const [clipped, setClipped] = useState(true)

  const toggleLines = () => {
    setClipped((old) => !old)
  }

  return (
    <div>
      <Truncate
        lines={clipped && numLines}
        ellipsis={
          <span>
            <Button className={classes.moreLessButton} onClick={toggleLines}>
              [...Show More...]
            </Button>{' '}
          </span>
        }
      >
        {children}
      </Truncate>
      {!clipped && (
        <span>
          <Button onClick={toggleLines}>[...Show Less...]</Button>{' '}
        </span>
      )}
    </div>
  )
}

export default ClippedContent

import {useTheme, makeStyles} from '@material-ui/core'
import React, {useCallback, useEffect, useState} from 'react'
import Tour from 'reactour'
import {remoteConfig} from 'firebase'
import {useAuth} from './AuthProvider'

const tourConfig = [
  {
    selector: '[data-tut="reactour__iso"]',
    content: `Create a bookmark from here.`,
  },
  {
    selector: '[data-tut="my_library"]',
    content: `Find your favorite media and bookmarks here`,
  },
  {
    selector: '[data-tut="editor_choice"]',
    content: `Find popular and curated bookmarks here`,
  },
]

const signinGuide = [
  {
    selector: '[data-tut="guide_signin"]',
    content: `Login to get personalized podcast recommendations`,
  },
]

const useStyles = makeStyles(() => ({
  nextPrevButton: {
    padding: '5px',
  },
}))

export default function AppTourGuide() {
  const theme = useTheme()
  const styles = useStyles()
  const auth = useAuth()
  const [tour, setTour] = useState(false)
  const accentColor = theme.palette.primary.main
  const closeTour = useCallback(() => {
    setTour(false)
  }, [setTour])

  const openTour = useCallback(() => {
    setTour(true)
  }, [setTour])

  useEffect(() => {
    const introTourShown = localStorage.getItem('intro_tour_shown')
    const loginGuideShown = localStorage.getItem('login_guide_shown')
    const remoteTourFlag = remoteConfig()
      .getValue('show_intro_tour')
      .asBoolean()
    if (!introTourShown && remoteTourFlag && auth.isLoggedIn()) {
      setTimeout(() => {
        openTour()
        localStorage.setItem('intro_tour_shown', 'true')
      }, 8000)
    }

    if (!auth.isLoggedIn() && !loginGuideShown && remoteTourFlag) {
      setTimeout(() => {
        openTour()
        localStorage.setItem('login_guide_shown', 'true')
      }, 8000)
    }
  }, [auth.isLoggedIn, auth.authData, openTour])

  return (
    <div>
      <Tour
        onRequestClose={closeTour}
        steps={auth.isLoggedIn() ? tourConfig : signinGuide}
        isOpen={tour}
        rounded={5}
        nextButton={<div className={styles.nextPrevButton}>Next</div>}
        prevButton={<div className={styles.nextPrevButton}>Prev</div>}
        disableDotsNavigation
        accentColor={accentColor}
      />
    </div>
  )
}

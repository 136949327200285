import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import HomeIcon from '@material-ui/icons/Home'
import FeedbackIcon from '@material-ui/icons/Feedback'
import ExploreIcon from '@material-ui/icons/Explore'
import SettingsIcon from '@material-ui/icons/Settings'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import {AddBox} from '@material-ui/icons'
import ExploreScreen from './components/ExploreScreen'
import LibraryScreen from './components/LibraryScreen'
import PodcastScreen from './components/PodcastScreen'
import EpisodeScreen from './components/EpisodeScreen'
import ResolveMedia from './components/ResolveMedia'
import SettingsScreen from './components/SettingsScreen'
import BookmarksFeedScreen from './components/FeedScreen'
import AddMediaScreen from './components/AddMediaScreen'
import ManuallyAddedListScreen from './components/ManuallyAddedListScreen'
import LoadFromUrl from './components/AddMediaScreen/components/LoadFromUrl'

export interface Section {
  paths: string[]
  Component: React.ElementType
  exact?: boolean
  loginRequired?: boolean
  tourSelector?: string
  /**
   * Regarding navigation
   */
  navigation?: {
    path: string
    label: string
    Icon: React.ElementType
    section: 'bottom' | 'main'
    external?: boolean
    externalLink?: string
  }
}
/**
 * We should not define sections twice (in routing and in navigation). So we use this sections definition instead.
 */
const sections: Section[] = [
  {
    paths: ['/'],
    Component: ExploreScreen,
    exact: true,
    navigation: {
      path: '/',
      label: 'Search & Explore',
      Icon: HomeIcon,
      section: 'main',
    },
  },
  {
    paths: ['/manually-added'],
    Component: ManuallyAddedListScreen,
    exact: true,
    navigation: {
      path: '/manually-added',
      label: 'Manually added podcasts',
      Icon: HomeIcon,
      section: 'main',
    },
  },
  {
    paths: ['/feed'],
    exact: true,
    Component: BookmarksFeedScreen,
    navigation: {
      path: '/feed',
      label: 'Feed',
      Icon: DynamicFeedIcon,
      section: 'main',
    },
  },
  {
    paths: ['/library'],
    Component: LibraryScreen,
    loginRequired: true,
    tourSelector: 'my_library',
    navigation: {
      path: '/library',
      label: 'My Library',
      Icon: LibraryBooksIcon,
      section: 'main',
    },
  },
  {
    paths: ['/explore'],
    Component: LibraryScreen,
    tourSelector: 'editor_choice',
    navigation: {
      path: '/explore',
      label: "Editor's Choice",
      Icon: ExploreIcon,
      section: 'main',
    },
  },
  {
    paths: ['/settings'],
    Component: SettingsScreen,
    loginRequired: true,
    navigation: {
      path: '/settings',
      label: 'Settings',
      Icon: SettingsIcon,
      section: 'main',
    },
  },
  {
    paths: ['/feedback'],
    Component: LibraryScreen,
    navigation: {
      path: '/feedback',
      label: 'Give Feedback',
      Icon: FeedbackIcon,
      section: 'main',
      external: true,
      externalLink: 'https://forms.gle/W2rRe5V2t6AKRA3o9',
    },
  },
  {
    paths: ['/episode/:episodeId'],
    exact: true,
    Component: EpisodeScreen,
  },
  {
    paths: ['/podcast/:podcastId'],
    Component: PodcastScreen,
  },
  {
    paths: ['/resolve/:episodeId'],
    Component: ResolveMedia,
  },
  {
    paths: [
      '/episode/:episodeId/shared/:userId',
      '/episode/:episodeId/shared/:userId/bookmark/:bookmarkId',
    ],
    exact: true,
    Component: EpisodeScreen,
  },
  {
    Component: AddMediaScreen,
    paths: ['/add-media'],
    navigation: {
      path: '/add-media',
      label: 'Add media',
      Icon: AddBox,
      section: 'main',
    },
    exact: true,
  },
  {
    Component: LoadFromUrl,
    paths: ['/add-media/from-url'],
  },
]

export default sections

import {useState, useEffect} from 'react'
import {firestore} from 'firebase'
import randToken from 'rand-token'
import moment from 'moment'
import {Bookmark, BookmarkType} from './models'
import {addBookmark} from './index'
import extractIdsFromBookmark from '../utils/extractIdsFromBookmarkPath'

/**
 *
 * @param ref
 * @returns Data from the snapshot, or null if loading
 */
export default function useSnapshot<T>(
  ref: firestore.CollectionReference | firestore.Query<firestore.DocumentData>,
  modifyItem: (value: any) => T = (item) => item
): T[] | null {
  const [data, setData] = useState<T[] | null>(null)

  useEffect(() => {
    const unregister = ref.onSnapshot((querySnapshot) => {
      console.log('onSnapshot')
      const receivedData: any[] = []

      querySnapshot.forEach((rawItem) => {
        const itemData = rawItem.data()
        const itemWithId = {
          ...itemData,
          id: rawItem.id,
          raw: rawItem,
        }

        receivedData.push(modifyItem(itemWithId))
      })
      setData(receivedData)
    })
    return unregister
  }, [ref, setData, modifyItem])

  return data
}

export async function copyBookmarkToMyLibrary(
  bookmark: Bookmark
): Promise<Bookmark> {
  const id = randToken.generate(4)
  const bookmarkToAdd: Bookmark = {
    id,
    listenedCount: bookmark.listenedCount,
    positionSec: bookmark.positionSec,
    description: bookmark.description,
    bookmarkType: BookmarkType.MY_BOOKMARKS,
    created: moment().toString(),
    mediaId: bookmark.mediaId,
    endPositionSec: bookmark.endPositionSec,
    authorId: '', // this is set automatically,
  }
  return addBookmark(bookmarkToAdd)
}

export function extractBookmarkData(
  documentSnapshot:
    | firestore.QueryDocumentSnapshot
    | firestore.DocumentSnapshot,
  additionalData: {
    bookmarkType?: BookmarkType
  }
): Bookmark {
  const data = documentSnapshot.data()
  // example path value users/7IANlmbthOU5dCw8Vf9fW0gMoSt1/media/c184d78771/bookmarks/DRDl
  const {path} = documentSnapshot.ref

  const bookmarkIds = extractIdsFromBookmark(path)

  if (!bookmarkIds) throw new Error('Bookmark does not have correct path')

  return {
    ...data,
    ...additionalData,
    listenedCount: data?.listenedCount || 0,
    authorId: bookmarkIds.authorId,
    mediaId: bookmarkIds.episodeId,
    _path: path,
  } as Bookmark
}

import React, {useState} from 'react'
import {TextField, TextFieldProps} from '@material-ui/core'
import {useDebounceCallback} from '@react-hook/debounce'

interface Props {
  defaultValue: string
  onDebouncedChange: (value: string) => void
  wait: number
  textFieldProps?: TextFieldProps
}

function DebouncedInput({
  defaultValue,
  onDebouncedChange,
  textFieldProps,
  wait,
}: Props) {
  const [value, setValue] = useState(defaultValue)
  const debounceChange = useDebounceCallback(onDebouncedChange, wait)

  return (
    <TextField
      {...textFieldProps}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        debounceChange(e.target.value)
      }}
    />
  )
}

export default DebouncedInput

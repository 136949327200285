import React, {useEffect, useState} from 'react'
import {createStyles, makeStyles} from '@material-ui/core'
import {Bookmark} from '../db/models'
import {usePlayer} from './PlayerProvider'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    progressBorder: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'orange',
      height: '1px',
      transition: '100ms linear',
    },
  })
)

interface Props {
  className?: string
  bookmark: Bookmark
}

function ClipProgress({bookmark, className}: Props) {
  const classes = useStyles()
  const [clipProgress, setClipProgress] = useState<null | number>(null)
  const player = usePlayer()

  useEffect(() => {
    if (!bookmark.endPositionSec) return
    return player.playerControls.registerOnPlayedListener((position) => {
      if (!position || !bookmark.endPositionSec) {
        setClipProgress(null)
        return
      }
      // if clip is currently playing
      if (
        position >= bookmark.positionSec &&
        position <= bookmark.endPositionSec
      ) {
        const progress = Math.round(
          ((position - bookmark.positionSec) /
            (bookmark.endPositionSec - bookmark.positionSec)) *
            100
        )
        setClipProgress(progress)
      } else {
        setClipProgress(null)
      }
    })
  }, [player, bookmark, setClipProgress])

  return (
    <div
      className={`${className} ${classes.progressBorder}`}
      style={{width: `${clipProgress || 0}%`}}
    />
  )
}

export default ClipProgress

/* eslint-disable camelcase */
import React, {useCallback, useState, useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {useAsync} from 'react-async'
import {sha256} from 'js-sha256'
import moment from 'moment'
import LoadingIndicator from '../LoadingIndicator'
import RetryableError from '../RetryableError'
import {EpisodeWithPodcast} from '../../apis/listenNotes/models'
import {Media} from '../../db/models'
import {getMediaIdByUrl, addMedia, getMediaById} from '../../db'
import EpisodeMedia from './EpisodeMedia'
import {generateEpisodeLink} from '../../utils/routes'
import {getEpisodeDetail} from '../../apis/podcastResolver'

const SEPARATOR_IN_ID = '---'

function useParamsInfo() {
  const params = useParams<{[key: string]: string}>()
  return {
    episodeId: params.episodeId || '',
    podcastId: params.podcastId || '',
  }
}

async function generateIdForMedia(
  url: string,
  id: string | null
): Promise<string> {
  let proposedId = id || sha256(url).substring(0, 10)

  const proposedIdQuery = await getMediaById(proposedId)

  if (!proposedIdQuery) {
    return proposedId
  }
  // otherwise, the proposedId is not okay. Increase the number by 1.
  const idx = proposedId.lastIndexOf(SEPARATOR_IN_ID)
  const idxNum = idx + SEPARATOR_IN_ID.length
  if (idx < 0) {
    return generateIdForMedia(url, `${proposedId}${SEPARATOR_IN_ID}1`)
  }
  const num = Number(proposedId.substring(idxNum))
  if (!num) {
    // This should be a number, but something is wrong
    proposedId = `${proposedId}${SEPARATOR_IN_ID}1`
    return generateIdForMedia(url, proposedId)
  }
  const finalMediaId = `${proposedId.substring(0, idxNum)}${num + 1}`
  return generateIdForMedia(url, finalMediaId)
}

const sanitizeUrl = (url: string): string => {
  let sanitizedUrl = url.split('?')[0]
  sanitizedUrl = sanitizedUrl.replace('http://', '')
  sanitizedUrl = sanitizedUrl.replace('https://', '')
  return sanitizedUrl
}

const registerMedia = async (media: Media) => {
  const {episodeAudio} = media
  const episodeMediaUrl = sanitizeUrl(episodeAudio)
  let mediaId = await getMediaIdByUrl(episodeMediaUrl)

  if (mediaId) {
    return mediaId
  }
  // new media found which needs to be stored
  if (!mediaId) mediaId = await generateIdForMedia(episodeMediaUrl, null)

  await addMedia({...media, id: mediaId, episodeMediaUrl})
  return mediaId
}

export async function resolveToSystemMedia(episode: EpisodeWithPodcast) {
  const {podcast} = episode
  const {
    title,
    description,
    pub_date_ms,
    link,
    thumbnail,
    audio_length_sec,
    audio,
  } = episode
  const {publisher, title: collectionName, rss, id: podcastId} = podcast
  const media: EpisodeMedia = {
    title,
    episodeDescription: description,
    episodeMediaUrl: link || audio,
    publisher,
    podcastName: collectionName,
    publishedAt: pub_date_ms.toString(),
    podcastRssFeed: rss,
    listennotesEpisodeUid: episode.id,
    listennotesPodcastUid: podcast.id,
    created: moment.now(),
    thumbnail,
    episodeLength: audio_length_sec,
    id: '',
    episodeAudio: audio,
    hashKey: 'audio',
    podcastId,
  }
  const mediaId = await registerMedia(media)
  return {...media, id: mediaId}
}

function ResolveMedia() {
  const {episodeId} = useParamsInfo()
  const history = useHistory()
  const [episode, setEpisode] = useState<EpisodeWithPodcast | null>(null)

  const fetchEpisodeInfo = useCallback(async () => {
    const episodeResponse = await getEpisodeDetail({episodeId})

    setEpisode(episodeResponse)

    return {
      episode: episodeResponse,
    }
  }, [episodeId])

  useEffect(() => {
    if (episode !== null) {
      resolveToSystemMedia(episode).then((media: Media) => {
        history.replace({pathname: generateEpisodeLink({episodeId: media.id})})
      })
    }
  }, [episode])

  const fetchEpisodeInfoTask = useAsync({promiseFn: fetchEpisodeInfo})
  return (
    <>
      {fetchEpisodeInfoTask.isLoading && (
        <LoadingIndicator text="Loading episode" />
      )}
      {fetchEpisodeInfoTask.isRejected && fetchEpisodeInfoTask.error && (
        <RetryableError
          error={fetchEpisodeInfoTask.error}
          text="Unable to fetch Episode"
          onTryAgain={() => fetchEpisodeInfoTask.reload()}
        />
      )}
      {fetchEpisodeInfoTask.isResolved && fetchEpisodeInfoTask.data && (
        <LoadingIndicator text="Redirecting to episode" />
      )}
    </>
  )
}

export default ResolveMedia

import {useEffect, useRef} from 'react'
import {getLatestPlayback, getMediaById} from '../db'
import {usePlayer} from './PlayerProvider'
import {useAuth} from './AuthProvider'
import {getPodcastDetail} from '../apis/podcastResolver'

export function useLoadLatestPlayback() {
  const player = usePlayer()
  const {startPlaying} = player
  const startPlayingRef = useRef(startPlaying)
  const {
    authData: {isLoggedIn},
  } = useAuth()

  useEffect(() => {
    ;(async () => {
      if (!isLoggedIn || player.playingMedia) return

      const latest = await getLatestPlayback()
      if (!latest) return

      const media = await getMediaById(latest.id)
      if (!media) return

      const podcast = await getPodcastDetail({
        podcastId: media.listennotesPodcastUid,
      })

      if (!podcast) return

      // we don't want to call this function every time startPlayingRef is called
      startPlayingRef.current({
        media: {episode: media, podcast},
        timeSec: latest.lastPlayedLocation,
        pauseOnLoad: true,
      })
    })()
  }, [startPlayingRef, isLoggedIn])
}

export default function LoadLatestPlayback() {
  useLoadLatestPlayback()
  return null
}

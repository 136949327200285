import axios from 'axios'

if (!process.env.REACT_APP_LISTEN_API_KEY) {
  const error = new Error(
    '`REACT_APP_LISTEN_API_KEY` env var is not defined. Listen notes service will not work'
  )
  console.error('⚠️', 'listenNotesService', error)
  throw error
}

const listenNotesService = axios.create({
  baseURL: 'https://listen-api.listennotes.com/api/v2/',
  headers: {
    'X-ListenAPI-Key': process.env.REACT_APP_LISTEN_API_KEY,
  },
})

export default listenNotesService

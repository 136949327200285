import React, {useEffect, useState} from 'react'
import {auth} from 'firebase'
import {Typography} from '@material-ui/core'

interface Props {
  children: React.ReactNode
}

function DisplayLoadingUtilUserIsSet({children}: Props) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    return auth().onAuthStateChanged(() => setLoaded(true))
  }, [setLoaded])

  if (!loaded) return <Typography>Loading...</Typography>
  return <>{children}</>
}

export default DisplayLoadingUtilUserIsSet

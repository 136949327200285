import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect,
} from 'react'
import {useCallbackRef} from 'use-callback-ref'
import ReactPlayer from 'react-player'
import {createStyles, makeStyles, Theme} from '@material-ui/core'
import clsx from 'clsx'
import {throttle} from 'throttle-debounce'
import controlledPlayerContext, {
  ControlledPlayerContext,
  ControlledPlayerState,
} from './context'
import {DRAWER_WIDTH, ROOT_CONTENT_CONTAINER_ID} from '../InsideScreen'
import {formatUrlIfVideo, isVideoUrl} from '../EpisodeScreen/utils'
import {useSettings} from '../SettingsProvider'

const BOTTOM_OFFSET_PX = 205
const TOP_OFFSET_PX = 64

const useStyles = makeStyles<
  Theme,
  {left: number; right: number; top: number; bottom: number}
>((theme) =>
  createStyles({
    playerContainer: {
      position: 'fixed',
      display: 'flex',
      bottom: 0,
      zIndex: 502,
      alignItems: 'center',
      transition: '200ms ease',
      justifyContent: 'center',
      height: '14.5rem',
      width: DRAWER_WIDTH,
      left: 0,

      // [theme.breakpoints.up('lg')]: {
      //   left: '0 !important',
      // },
      // [theme.breakpoints.down('md')]: {
      //   bottom: '14.5rem',
      //   // height: '10rem !important',
      //   // width: '10rem !important',
      //   transformOrigin: 'bottom, right',
      //   maxWidth: '100vh',
      // },
      transformOrigin: 'bottom, right',
    },
    hidden: {
      [theme.breakpoints.down('md')]: {
        left: `-${DRAWER_WIDTH}px !important`,
        bottom: '14.5rem',
      },
      // [theme.breakpoints.up('lg')]: {
      //   left: '0 !important',
      // },
    },
    extended: {
      [theme.breakpoints.down('md')]: {
        top: 'auto',
        bottom: '14.5rem',
        // height: '10rem !important',
        // width: '10rem !important',
        transformOrigin: 'bottom, right',
        maxWidth: '100vh',
      },
      [theme.breakpoints.up('lg')]: {
        // top: (props) =>
        //   `calc(64px + ${props.settings.settingsData.expandedVideoPosition.xFromTop})`,
        // bottom: '205PX',
        // left: DRAWER_WIDTH,
        // right: (props) =>
        //   `${props.settings.settingsData.expandedVideoPosition.yFromTop}`,
        left: (props) => props.left,
        right: (props) => props.right,
        bottom: (props) => props.bottom,
        top: (props) => props.top,
        width: 'auto !important',
        height: 'auto !important',
      },
    },
    player: {},
    showHideButton: {
      position: 'absolute',
      right: '0',
      top: '0',
      background: 'black',
      color: 'white',
      padding: '.25rem .25rem',
      cursor: 'pointer',

      [theme.breakpoints.down('md')]: {
        transform: 'rotate(90deg) translate(6.5rem, -3rem)',
      },
      [theme.breakpoints.up('lg')]: {},
      // [theme.breakpoints.up('lg')]: {
      //   display: 'none',
      // },
    },
  })
)

function PlayerProvider({children}: {children: React.ReactNode}) {
  const settings = useSettings()
  const [availableSpace, setAvailableSpace] = useState({width: 0, height: 0})

  const left =
    DRAWER_WIDTH +
    availableSpace.width *
      (settings.settingsData.expandedVideoPositionPercent.left / 100)
  const right =
    availableSpace.width *
    (settings.settingsData.expandedVideoPositionPercent.right / 100)
  const top =
    TOP_OFFSET_PX +
    availableSpace.height *
      (settings.settingsData.expandedVideoPositionPercent.top / 100)
  const bottom =
    BOTTOM_OFFSET_PX +
    availableSpace.height *
      (settings.settingsData.expandedVideoPositionPercent.bottom / 100)

  console.log('ahahaha', {
    left,
    right,
    top,
    bottom,
    settings: settings.settingsData.expandedVideoPositionPercent,
  })

  const classes = useStyles({top, left, right, bottom})
  const [youtubeHidden, setYoutubeHidden] = useState(false)
  const onPlayedListenersRef = useRef<
    Array<(position: number | undefined) => void>
  >([])

  const onUserSeekingListenersRef = useRef<Array<(position: number) => void>>(
    []
  )

  const onPlaybackInteractionListenersRef = useRef<
    Array<
      (args: {
        interactionType: 'seek' | 'play' | 'pause'
        currentState: 'playing' | 'paused'
      }) => void
    >
  >([])

  const [source, setSource] = useState<null | {url: string; autoplay: boolean}>(
    null
  )
  const [state, setState] = useState<ControlledPlayerState>('initial')
  const [waiting, setWaiting] = useState(false)
  const [volume, setVolume] = useState(1)
  const [rate, setRate] = useState(1)
  const [duration, setDuration] = useState<number | undefined>(undefined)

  useEffect(() => {
    function updateAvailableSpace() {
      const rootContentContainer = document.getElementById(
        ROOT_CONTENT_CONTAINER_ID
      )

      if (rootContentContainer == null) {
        // alert('We need to try again')
        setTimeout(200, updateAvailableSpace)
        return
      }

      const availableWidth = rootContentContainer.clientWidth
      const availableHeight =
        rootContentContainer.clientHeight - BOTTOM_OFFSET_PX - BOTTOM_OFFSET_PX

      setAvailableSpace({
        width: availableWidth,
        height: availableHeight,
      })
    }

    updateAvailableSpace()
    window.addEventListener('resize', throttle(500, updateAvailableSpace))
  }, [setAvailableSpace])

  const seekToOnceLoaded = useRef<number | undefined>(undefined)

  const audioRef = useCallbackRef<ReactPlayer>(null, (audioComponent) => {
    if (!audioComponent) return
    // @ts-ignore
    window.audio = audioComponent

    setDuration(audioComponent.getDuration)

    function checkAndSetWaiting() {
      if (!audioComponent) return

      console.log(
        'ControlledPlayer',
        'Checking ready state - seconds loaded',
        audioComponent.getSecondsLoaded()
      )

      // See how this works https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
      if (audioComponent.getSecondsLoaded() > 0) {
        setWaiting(false)
      } else {
        setWaiting(true)
      }
    }

    checkAndSetWaiting()
  })

  useEffect(() => {
    onPlaybackInteractionListenersRef.current.forEach((one) =>
      one({
        interactionType: state === 'playing' ? 'play' : 'pause',
        currentState: state === 'playing' ? 'playing' : 'paused',
      })
    )
  }, [state, onPlaybackInteractionListenersRef])

  // useEffect(() => {
  //   if (audioRef.current) audioRef.current.load()
  // }, [source, audioRef])

  const contextValue: ControlledPlayerContext = {
    source: source?.url || null,
    state,
    waiting,
    volume,
    rate,
    duration,

    play: useCallback(() => {
      setState('playing')
    }, [audioRef]),
    pause: useCallback(() => {
      setState('paused')
    }, [audioRef]),
    setSource: useCallback(
      (url, args) => {
        console.log('ControlledPlayer', 'setting source', {url, args})

        if (source?.url === url) {
          console.log(
            'ControlledPlayer',
            'already playing url, just seeking and resuming.'
          )
          if (args?.seek !== undefined) contextValue.seek(args.seek)
          if (args?.autoplayIfPossible) contextValue.play()
          return
        }

        if (args?.seek) seekToOnceLoaded.current = args.seek
        else seekToOnceLoaded.current = undefined

        setDuration(undefined)
        setSource({url, autoplay: args?.autoplayIfPossible || false})
      },
      // eslint-disable-next-line
      [audioRef, setDuration, setSource, source]
    ),
    seek: useCallback(
      (to) => {
        console.log('ControlledPlayer', 'Seeking', to)
        if (audioRef.current) audioRef.current.seekTo(to, 'seconds')
        onUserSeekingListenersRef.current.forEach((listener) => listener(to))
        onPlayedListenersRef.current.forEach((listener) => listener(to))
      },
      [audioRef]
    ),
    setVolume: useCallback(
      (value) => {
        setVolume(value)
      },
      [audioRef]
    ),
    setRate: useCallback(
      (value) => {
        setRate(value)
      },
      [audioRef]
    ),

    getPosition: () => audioRef.current?.getCurrentTime(),

    registerOnPlayedListener: useCallback(
      (listenerToRegister) => {
        onPlayedListenersRef.current.push(listenerToRegister)
        return () => {
          onPlayedListenersRef.current = onPlayedListenersRef.current.filter(
            (listener) => listener !== listenerToRegister
          )
        }
      },
      [onPlayedListenersRef]
    ),

    registerOnPlaybackInteractionListener: useCallback(
      (listenerToRegister) => {
        onPlaybackInteractionListenersRef.current.push(listenerToRegister)
        return () => {
          onPlaybackInteractionListenersRef.current = onPlaybackInteractionListenersRef.current.filter(
            (listener) => listener !== listenerToRegister
          )
        }
      },
      [onPlaybackInteractionListenersRef]
    ),

    registerOnUserSeekingListener: useCallback(
      (listenerToRegister) => {
        onUserSeekingListenersRef.current.push(listenerToRegister)
        return () => {
          onUserSeekingListenersRef.current = onUserSeekingListenersRef.current.filter(
            (listener) => listener !== listenerToRegister
          )
        }
      },
      [onUserSeekingListenersRef]
    ),
  }

  return (
    <controlledPlayerContext.Provider value={contextValue}>
      {children}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <div
        className={clsx(
          classes.playerContainer,
          youtubeHidden ? classes.hidden : classes.extended
        )}
        style={{
          display: isVideoUrl(source?.url || '') ? 'block' : 'none',
        }}
      >
        {source && (
          <ReactPlayer
            width="100%"
            height="100%"
            className={classes.player}
            ref={audioRef}
            url={formatUrlIfVideo(source.url)}
            playing={state === 'playing'}
            controls
            progressInterval={1000}
            volume={volume}
            playbackRate={rate}
            onPause={() => {
              console.log('controlledPlayer', 'onPause called')
              setState('paused')
            }}
            onPlay={() => {
              console.log('controlledPlayer', 'onPlay called')
              setState('playing')
            }}
            onBuffer={() => {
              console.log('controlledPlayer', 'onBuffer called')
              setWaiting(true)
            }}
            onBufferEnd={() => {
              console.log('controlledPlayer', 'onBufferEnd called')
              setWaiting(false)
              if (seekToOnceLoaded.current && audioRef.current) {
                audioRef.current.seekTo(seekToOnceLoaded.current, 'seconds')
                seekToOnceLoaded.current = undefined
              }
            }}
            onReady={() => {
              console.log('controlledPlayer', 'onReady called')
              setWaiting(false)
              if (seekToOnceLoaded.current && audioRef.current) {
                audioRef.current.seekTo(seekToOnceLoaded.current, 'seconds')
                seekToOnceLoaded.current = undefined
              }
            }}
            onProgress={({playedSeconds}) => {
              console.log(
                'controlledPlayer',
                'timeupdate called',
                playedSeconds
              )
              onPlayedListenersRef.current.forEach((listener) =>
                listener(playedSeconds)
              )
            }}
            onDuration={(value) => {
              console.log('controlledPlayer', 'onDurationCalled')
              setDuration(value)
            }}
            onSeek={(seconds) => {
              console.log('controlledPlayer', 'seeking called', seconds)
              onPlaybackInteractionListenersRef.current.forEach((one) =>
                one({
                  interactionType: 'seek',
                  currentState: state === 'playing' ? 'playing' : 'paused',
                })
              )
            }}
          />
        )}
        {isVideoUrl(source?.url || '') && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className={classes.showHideButton}
            onClick={() => setYoutubeHidden((v) => !v)}
          >
            {youtubeHidden ? 'show' : 'hide'} video
          </div>
        )}
      </div>
    </controlledPlayerContext.Provider>
  )
}

export default PlayerProvider

export function useControlledPlayer() {
  return useContext(controlledPlayerContext)
}

import React, {useMemo, useState} from 'react'
import {createStyles, makeStyles} from '@material-ui/core'
import InfiniteLoadingList from '../InfiniteLoadingList'
import BookmarksList from './components/BookmarksList'
import Container from '../Container'
import {StandaloneBookmarkData} from './components/StandaloneBookmark'
import {createFetchFunction} from './utils'
import BookmarkTypeFilter, {FilterType} from '../BookmarkTypeFilter'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    spacer: {
      height: theme.spacing(2),
    },
  })
)

interface Props {
  className?: string
}

function BookmarksFeedScreen({className}: Props) {
  const classes = useStyles()
  const [filterType, setFilterType] = useState<FilterType>('all')

  const fetchBookmarks = useMemo(() => {
    return createFetchFunction({type: filterType, notEmpty: false})
  }, [filterType])

  return (
    <Container className={`${classes.root} ${className}`}>
      <BookmarkTypeFilter value={filterType} onChange={setFilterType} />
      <div className={classes.spacer} />
      <InfiniteLoadingList
        key={filterType}
        resultToItems={(result) => result.data}
        loadMore={fetchBookmarks}
      >
        {(data, modifyItems) => (
          <BookmarksList
            data={data as StandaloneBookmarkData[]}
            onBookmarkRemoved={(bookmark) => {
              modifyItems((i) => {
                const items = i as StandaloneBookmarkData[] | null
                if (!items) return null
                return items.filter((one) => one.bookmark.id !== bookmark.id)
              })
            }}
            onBookmarkUpdated={(bookmark) => {
              modifyItems((i) => {
                const items = i as StandaloneBookmarkData[] | null
                if (!items) return null

                // swap out the old item for the new one
                const itemIndex = items.findIndex(
                  (one) => one.bookmark.id === bookmark.id
                )
                if (itemIndex === -1) return items
                const newItems = [...items]
                newItems[itemIndex].bookmark = bookmark

                return newItems
              })
            }}
          />
        )}
      </InfiniteLoadingList>
    </Container>
  )
}

export default BookmarksFeedScreen

import React, {useCallback, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import {useAsync} from 'react-async'
import {useHistory} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import Container from '../Container'
import {Media} from '../../db/models'
import {addMedia, setMediaLastPlayed} from '../../db'
import RetryableError from '../RetryableError'
import {
  getYoutubeVideoInfo,
  parseRssFeed,
  parseRssItem,
  readTextFile,
} from './utils'
import {PodcastWithEpisode} from '../../apis/listenNotes/models'
import PodcastToAddOverview from './components/PodcastToAddOverview'
import {addPodcast} from '../../apis/podcastResolver/db'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
)

interface Props {
  className?: string
}

function generateEmptyMedia() {
  return {
    id: `custom-${String(moment.now())}`,
    episodeMediaUrl: '',
    title: '',
    publisher: '',
    episodeDescription: '',
    publishedAt: null,
    podcastName: null,
    podcastRssFeed: null,
    listennotesPodcastUid: 'custom-default',
    listennotesEpisodeUid: 'custom-defaut',
    created: moment.now(),
    thumbnail: '',
    episodeLength: 0,
    episodeAudio: '',
    hashKey: '',
    podcastId: 'test',
    addedManually: true,
  }
}

function AddMediaScreen({className}: Props): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()

  const [data, setData] = useState<Media>(generateEmptyMedia())

  const onChange = (fieldName: keyof Media) => (e: any) => {
    const {value} = e.target
    setData((prev) => ({...prev, [fieldName]: value}))
  }

  const submitTask = useAsync({
    deferFn: useCallback(async () => {
      await addMedia(
        {
          ...data,
          listennotesEpisodeUid: data.id,
          listennotesPodcastUid: 'custom-default',
          episodeAudio: data.episodeMediaUrl,
        },
        true
      )
      const {id} = data

      await setMediaLastPlayed({mediaId: id, timeSec: 0})
      setData(generateEmptyMedia)
      history.push(`/episode/${id}`)
    }, [data, setData, history]),
  })

  const [rssItem, setRssItem] = useState('')

  function parseFromRssItem(value: string): void {
    parseRssItem(value).then((parsedMedia) => {
      setData(parsedMedia)
    })
  }

  const fileRef = useRef<HTMLInputElement>(null)
  const [podcast, setPodcast] = useState<PodcastWithEpisode | null>(null)

  async function parseFromRssFeed() {
    if (!fileRef.current) return
    const file = fileRef.current.files?.item(0)
    if (!file) return
    const rss = await readTextFile(file)

    const parsedPodcast = await parseRssFeed(rss)
    setPodcast(parsedPodcast)
    addPodcast(parsedPodcast)
  }

  const [rssFeedLink, setRssFeedLink] = useState('')

  function prefillYoutubeVideo() {
    getYoutubeVideoInfo({
      id: data.episodeMediaUrl.replace('https://youtu.be/', ''),
    })
      .then(({durationSec, title}) =>
        setData((prev) => ({...prev, episodeLength: durationSec, title}))
      )
      .catch((e) => {
        console.log('Error while getting youtube video info', e)
        enqueueSnackbar(
          'There was an error while fetching video info. Is the link correct?',
          {variant: 'error'}
        )
      })
  }

  if (podcast) return <PodcastToAddOverview podcast={podcast} />

  return (
    <Container maxWidth="md" className={clsx(classes.root, className)}>
      <div>
        <Typography variant="h3">Add your media</Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            history.push(`/add-media/from-url?${rssFeedLink}`)
          }}
        >
          <Typography variant="h4">Import podcast by rss link</Typography>
          <TextField
            required
            fullWidth
            margin="normal"
            variant="outlined"
            label="Rss feed link"
            value={rssFeedLink}
            onChange={(e) => {
              setRssFeedLink(e.target.value)
            }}
          />
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </form>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            parseFromRssFeed()
          }}
        >
          <input type="file" ref={fileRef} />
          <Button variant="contained" color="primary" type="submit">
            Analyze feed and go to next step
          </Button>
        </form>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            parseFromRssItem(rssItem)
          }}
        >
          <Typography variant="h4">Add one episode from rss feed</Typography>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="<item> rss feed"
            value={rssItem}
            onChange={(e) => {
              setRssItem(e.target.value)
            }}
          />
          <Button variant="contained" color="primary" type="submit">
            Prefill from rss feed
          </Button>
        </form>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitTask.run()
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            helperText=""
            label="Title"
            value={data.title}
            onChange={onChange('title')}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Media link"
            helperText="Mp3 link or Youtube link in format https://youtu.be/xxxxxxx"
            value={data.episodeMediaUrl}
            onChange={onChange('episodeMediaUrl')}
            required
          />
          <Button onClick={prefillYoutubeVideo} type="button">
            Prefill from youtube video
          </Button>
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            helperText="thumbnail (optional)"
            label="Thumbnail"
            value={data.thumbnail}
            onChange={onChange('thumbnail')}
          />
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Audio length"
            helperText="Audio length in seconds"
            value={data.episodeLength}
            onChange={onChange('episodeLength')}
            required
          />

          <RetryableError
            error={submitTask.error}
            onTryAgain={() => submitTask.run}
          />

          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={submitTask.isLoading}
          >
            {submitTask.isLoading ? <CircularProgress /> : 'submit'}
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default AddMediaScreen

import React, {useState} from 'react'
import {Container, makeStyles, createStyles} from '@material-ui/core'
import DebouncedInput from './DeboucedInput'

const useStyles = makeStyles((theme) =>
  createStyles({
    fieldContainer: {
      margin: theme.spacing(2, 'auto'),
      background: '#fafafa',
    },
  })
)

interface Props {
  onChangeSearchString: (value: string) => void
  inputLabel?: string
  searchTerm?: string
}

export default function SearchBar({
  onChangeSearchString,
  inputLabel,
  searchTerm,
}: Props) {
  const classes = useStyles()
  const [searchString, setSearchString] = useState(searchTerm || '')

  return (
    <>
      <Container className={classes.fieldContainer} maxWidth="md">
        <DebouncedInput
          defaultValue={searchString}
          wait={1000}
          textFieldProps={{
            fullWidth: true,
            label: inputLabel || 'Search for podcast',
            variant: 'filled',
          }}
          onDebouncedChange={(value) => {
            onChangeSearchString(value)
            setSearchString(value)
          }}
        />
      </Container>
    </>
  )
}

import React from 'react'
import {
  Container,
  Modal,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import {auth} from 'firebase/app'
import {useSnackbar} from 'notistack'
import GoogleButton from 'react-google-button'

const useStyles = makeStyles((theme) =>
  createStyles({
    modalBody: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
      maxHeight: '90vh',
      overflow: 'scroll',
    },
    inner: {
      '& > *': {
        marginBottom: theme.spacing(2),
      },
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    timeButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    timeIcon: {
      fontSize: '3rem',
    },
    buttons: {
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    playbackSpeed: {
      margin: '8px',
    },
  })
)

interface Props {
  showModal: boolean
  onClose: () => void
  title?: string
}

const provider = new auth.GoogleAuthProvider()

function AuthModal({showModal, onClose, title = 'Login to Continue'}: Props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  async function login() {
    try {
      await auth().signInWithPopup(provider)
    } catch (e) {
      enqueueSnackbar('Unable to login user', {
        variant: 'error',
      })
    }
  }

  return (
    <Modal open={showModal} onClose={onClose}>
      <Container className={classes.modalBody} maxWidth="sm">
        <div className={classes.inner}>
          <div>
            <Typography variant="h5">{title}</Typography>
          </div>
          <GoogleButton onClick={login} />
        </div>
      </Container>
    </Modal>
  )
}

export default AuthModal

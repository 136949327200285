import React, {useCallback, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useAsync} from 'react-async'
import {Tabs, Tab, makeStyles, createStyles, Paper} from '@material-ui/core'
import LoadingIndicator from '../LoadingIndicator'
import RetryableError from '../RetryableError'
import PodcastHeader from './components/PodcastHeader'
import AboutInfo from './components/AboutInfo'
import EpisodesList from './components/EpisodesList'
import {getPodcastDetail} from '../../apis/podcastResolver'

const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      marginTop: theme.spacing(1),
    },
  })
)

async function fetchPodcastDetail({podcastId}: {podcastId: string}) {
  const result = await getPodcastDetail({podcastId})
  return result
}

function PodcastScreen() {
  const classes = useStyles()
  const {podcastId} = useParams()
  const [selectedSection, setSelectedSection] = useState<'episodes' | 'about'>(
    'episodes'
  )

  const fetchPodcast = useCallback(() => fetchPodcastDetail({podcastId}), [
    podcastId,
  ])

  const fetchPodcastTask = useAsync({promiseFn: fetchPodcast})
  return (
    <>
      {fetchPodcastTask.isLoading && (
        <LoadingIndicator text="Loading podcast data" />
      )}
      {fetchPodcastTask.isRejected && fetchPodcastTask.error && (
        <RetryableError
          text="Unable to fetch podcast"
          error={fetchPodcastTask.error}
          onTryAgain={() => fetchPodcastTask.run()}
        />
      )}
      {fetchPodcastTask.isResolved && fetchPodcastTask.data && (
        <>
          <PodcastHeader podcast={fetchPodcastTask.data} />
          <Paper elevation={0}>
            <Tabs
              className={classes.tabs}
              centered
              value={selectedSection}
              onChange={(e, newSection) => {
                setSelectedSection(newSection)
              }}
            >
              <Tab label="Episodes" value="episodes" />
              <Tab label="About" value="about" />
            </Tabs>
          </Paper>
          {selectedSection === 'about' && (
            <AboutInfo podcast={fetchPodcastTask.data} />
          )}
          {selectedSection === 'episodes' && (
            <EpisodesList podcast={fetchPodcastTask.data} />
          )}
        </>
      )}
    </>
  )
}

export default PodcastScreen

import React, {useCallback, useEffect, useRef} from 'react'
import {
  Container,
  Modal,
  Typography,
  makeStyles,
  createStyles,
  IconButton,
  Button,
} from '@material-ui/core'
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  WhatsappIcon,
  TwitterIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import {useLocation} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {SharingModal as SharingModalData} from './context'
import {useAnalytics} from '../../utils/analytics'
import {setBookmarkShareable} from '../../db'
import {useUser} from '../../utils/auth'

const useStyles = makeStyles((theme) =>
  createStyles({
    modalBody: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
      maxHeight: '90vh',
      overflow: 'scroll',
    },
    inner: {
      textAlign: 'center',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    shareButtons: {
      display: 'flex',
      justifyContent: 'center',
      margin: '5px',
    },
    shareButton: {
      margin: '5px',
    },
  })
)

interface Props {
  className?: string
  autoHide?: boolean
  onClose: () => void
  showModal: boolean
  sharingModal?: SharingModalData
}

const SHARE_ICON_SIZE = 40
const DEFAULT_SHARING_TEXT =
  'Hey, checkout this clip on clipvo.com! I liked it, you might like it too'

function SharingModal({
  className,
  showModal,
  onClose,
  autoHide,
  sharingModal,
}: Props) {
  const analytics = useAnalytics()
  const classes = useStyles()
  const location = useLocation()
  const timeoutRef = useRef<any | null>(null)
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    if (!autoHide) return
    timeoutRef.current = setTimeout(() => {
      onClose()
    }, 5000)
  }, [autoHide, onClose])

  const sharingUrl = sharingModal?.url || location.pathname
  const sharingTitle = sharingModal?.sharingText || DEFAULT_SHARING_TEXT
  const user = useUser()

  const bookmark = sharingModal?.bookmark

  const onDisableSharing = useCallback(() => {
    if (!bookmark) return
    setBookmarkShareable(bookmark, false)
    onClose()
  }, [bookmark, onClose, sharingModal])

  return (
    <Modal className={`${className}`} open={showModal} onClose={onClose}>
      <Container className={classes.modalBody} maxWidth="sm">
        <div className={classes.inner}>
          <div>
            <Typography variant="h5">{` ${
              sharingModal?.title || 'Share Via'
            }`}</Typography>
            <Typography>Bookmark is also visible on public feed now</Typography>
          </div>
          <div className={classes.shareButtons}>
            <FacebookShareButton
              url={sharingUrl}
              title={sharingTitle}
              quote={sharingTitle}
              hashtag="#clipvo"
              className={classes.shareButton}
              beforeOnClick={() => {
                analytics.logEvent({
                  eventName: 'sharing_platform_clicked',
                  params: {platformType: 'facebook'},
                })
              }}
            >
              <FacebookIcon size={SHARE_ICON_SIZE} round />
            </FacebookShareButton>
            <WhatsappShareButton
              url={sharingUrl}
              title={sharingTitle}
              className={classes.shareButton}
              beforeOnClick={() => {
                analytics.logEvent({
                  eventName: 'sharing_platform_clicked',
                  params: {platformType: 'whatsapp'},
                })
              }}
            >
              <WhatsappIcon size={SHARE_ICON_SIZE} round />
            </WhatsappShareButton>
            <TwitterShareButton
              url={sharingUrl}
              title={sharingTitle}
              className={classes.shareButton}
              beforeOnClick={() => {
                analytics.logEvent({
                  eventName: 'sharing_platform_clicked',
                  params: {platformType: 'twitter'},
                })
              }}
            >
              <TwitterIcon size={SHARE_ICON_SIZE} round />
            </TwitterShareButton>
            <EmailShareButton
              url={sharingUrl}
              className={classes.shareButton}
              title={sharingTitle}
              subject={sharingTitle}
              body={DEFAULT_SHARING_TEXT}
              beforeOnClick={() => {
                analytics.logEvent({
                  eventName: 'sharing_platform_clicked',
                  params: {platformType: 'email'},
                })
              }}
            >
              <EmailIcon size={SHARE_ICON_SIZE} round />
            </EmailShareButton>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            or
            <div>
              copy link
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(sharingModal?.url || '')
                  enqueueSnackbar('Link Copied to the clipboard', {
                    variant: 'success',
                  })
                  analytics.logEvent({
                    eventName: 'sharing_platform_clicked',
                    params: {platformType: 'copy'},
                  })
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </div>
            {bookmark?.authorId === user?.uid && (
              <Button onClick={onDisableSharing}>Disable sharing</Button>
            )}
          </div>
        </div>
      </Container>
    </Modal>
  )
}

export default React.memo(SharingModal)

import {createContext} from 'react'
import {Bookmark} from '../../db/models'

export interface SharingModal {
  url: string
  title?: string
  sharingText?: string
  bookmark: Bookmark
}

export interface SharingControlsContext {
  share: (sharingModel: SharingModal) => void
}

const emptyValue: SharingControlsContext = {
  share: () => {},
}

const sharingControlsContext = createContext<SharingControlsContext>(emptyValue)

export default sharingControlsContext

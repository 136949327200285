import React from 'react'
import clsx from 'clsx'
import {Button, createStyles, makeStyles, Typography} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {PodcastWithEpisode} from '../../../apis/listenNotes/models'
import Container from '../../Container'
import PodcastHeader from '../../PodcastScreen/components/PodcastHeader'
import EpisodePreview from './EpisodePreview'
import {addEpisode, addPodcast} from '../../../apis/podcastResolver/db'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    list: {
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
  })
)

interface Props {
  className?: string
  podcast: PodcastWithEpisode
}

function PodcastToAddOverview({className, podcast}: Props): JSX.Element {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  return (
    <Container maxWidth="md" className={clsx(classes.root, className)}>
      <PodcastHeader podcast={podcast} />
      <Typography>{podcast.description}</Typography>
      <Typography>podcastId: {podcast.id}</Typography>
      <div>
        <Button
          onClick={async () => {
            await addPodcast(podcast)
            await Promise.all(podcast.episodes.map(addEpisode))
            enqueueSnackbar('Added', {variant: 'success'})
          }}
        >
          Add all episodes
        </Button>
      </div>
      <div className={classes.list}>
        {podcast.episodes.map((episode) => (
          <EpisodePreview episode={episode} podcast={podcast} />
        ))}
      </div>
    </Container>
  )
}

export default PodcastToAddOverview

import React, {useMemo, useState} from 'react'
import {
  makeStyles,
  createStyles,
  Container,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import BookmarkView from '../../BookmarkView'
import LoadingIndicator from '../../LoadingIndicator'
import {Podcast} from '../../../apis/listenNotes/models'
import EpisodeMedia from '../../ResolveMedia/EpisodeMedia'
import {Bookmark} from '../../../db/models'
import {useSettings} from '../../SettingsProvider'
import BookmarkTypeFilter from '../../BookmarkTypeFilter'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    list: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
    bookmarksListTitle: {
      textAlign: 'center',
    },
  })
)

interface Props {
  episode: EpisodeMedia
  podcast: Podcast
  bookmarks: Bookmark[] | null
  onBookmarkPlayed: (bookmark: Bookmark) => void
  onBookmarkEdited?: (bookmark: Bookmark) => void
  onBookmarkDeleted?: (bookmark: Bookmark) => void
  showSettings?: boolean
}

function BookmarksList({
  episode,
  podcast,
  bookmarks,
  onBookmarkPlayed,
  showSettings = true,
  onBookmarkEdited,
  onBookmarkDeleted,
}: Props) {
  const classes = useStyles()
  const settings = useSettings()
  const [filter, setFilter] = useState<'bookmarks' | 'clips' | 'all'>('all')

  const bookmarksToShow = useMemo(() => {
    if (!bookmarks) return null
    if (filter === 'clips')
      return bookmarks.filter((one) => !!one.endPositionSec)
    if (filter === 'bookmarks')
      return bookmarks.filter((one) => !one.endPositionSec)
    return bookmarks
  }, [bookmarks, filter])

  if (!bookmarks) return <LoadingIndicator text="Loading bookmarks" />

  return (
    <Container maxWidth="md">
      <div className={classes.list}>
        {bookmarks.length === 0 ? (
          <Typography>
            <i>No bookmarks / clips added yet</i>
          </Typography>
        ) : (
          <>
            {showSettings && (
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.stopAfterClipEnds}
                    onChange={(e) => {
                      settings.setStopAfterClipEnds(e.target.checked)
                    }}
                    color="primary"
                  />
                }
                label="Stop playing after clip ends"
              />
            )}
            <BookmarkTypeFilter value={filter} onChange={setFilter} />

            {bookmarksToShow?.map((bookmark) => (
              <BookmarkView
                onPlayed={onBookmarkPlayed}
                key={bookmark.id}
                episode={episode}
                podcast={podcast}
                bookmark={bookmark}
                onUpdated={onBookmarkEdited}
                onRemoved={onBookmarkDeleted}
              />
            ))}
            {bookmarksToShow?.length === 0 && (
              <Typography>No bookmarks/clips to show</Typography>
            )}
          </>
        )}
      </div>
    </Container>
  )
}

BookmarksList.defaultProps = {
  onBookmarkPlayed: () => undefined,
}

export default BookmarksList

import React from 'react'
import {Chip, createStyles, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, -1),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
  })
)

export type FilterType = 'all' | 'bookmarks' | 'clips'

interface Props {
  className?: string
  value: FilterType
  onChange: (value: FilterType) => void
}

function BookmarkTypeFilter({className, value: filter, onChange}: Props) {
  const classes = useStyles()
  return (
    <div className={`${className} ${classes.root}`}>
      <Chip
        color={filter === 'all' ? 'primary' : 'default'}
        label="all"
        onClick={() => onChange('all')}
      />
      <Chip
        color={filter === 'bookmarks' ? 'primary' : 'default'}
        label="bookmarks"
        onClick={() => onChange('bookmarks')}
      />
      <Chip
        color={filter === 'clips' ? 'primary' : 'default'}
        label="clips"
        onClick={() => onChange('clips')}
      />
    </div>
  )
}

export default BookmarkTypeFilter

export interface BookmarkIds {
  bookmarkId: string
  authorId: string
  episodeId: string
}

/**
 * ids
 *
 */
export default function extractIdsFromBookmark(
  path?: string
): BookmarkIds | null {
  if (!path) return null

  const splitPath = path.split('/')
  if (splitPath.length < 5) return null

  const authorId = splitPath[1]
  const episodeId = splitPath[3]
  const bookmarkId = splitPath[5]

  return {authorId, episodeId, bookmarkId}
}

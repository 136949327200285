import {
  EpisodeWithPodcast,
  Podcast,
  PodcastWithEpisode,
} from '../listenNotes/models'
import {episodeDetail, podcastDetail} from '../listenNotes'
import {getEpisode, getPodcast} from './db'

export const userAddedPodcast: Podcast = {
  id: 'addedByUser',
  title: 'Added by user',
  rss: null,
  type: null,
  email: null,
  extra: null,
  image: null,
  country: null,
  website: null,
  language: null,
  genre_ids: null,
  itunes_id: null,
  publisher: null,
  thumbnail: null,
  isClaimed: null,
  description: null,
  looking_for: null,
  total_episodes: null,
  latest_pub_date_ms: null,
  earliest_pub_date_ms: null,
}

// function mediaToEpisode(media: Media): Episode {
//   return {
//     id: media.id,
//     link: media.episodeAudio,
//     // This is the URI of the episode provided by podcast host. This is not *ListenNotes* URL.
//     audio: media.episodeAudio,
//     // This is the ListenNotes URL for the audio of the podcast. This is redirected to another URL that hosts the audio of the podcast.
//     image: media.thumbnail,
//     title: media.title || 'not set',
//     thumbnail: media.thumbnail,
//     description: media.episodeDescription || 'no description',
//     pub_date_ms: media.publishedAt
//       ? moment(media.publishedAt).milliseconds()
//       : 0,
//     audio_length_sec: media.episodeLength,
//   }
// }

export async function getPodcastDetail({
  podcastId,
  nextEpisodePubDate,
  sort,
}: {
  podcastId?: string | null
  nextEpisodePubDate?: number
  sort?: 'recent_first' | 'oldest_first'
}): Promise<PodcastWithEpisode> {
  if (!podcastId) {
    return {
      ...userAddedPodcast,
      next_episode_pub_date: 0,
      episodes: [],
    }
  }

  if (podcastId?.startsWith('custom-') || podcastId === 'test') {
    const podcast = await getPodcast(podcastId || '')
    if (podcast) return podcast
  }

  return (
    await podcastDetail({podcastId: podcastId || '', nextEpisodePubDate, sort})
  ).data
}

export async function getEpisodeDetail({
  episodeId,
}: {
  episodeId: string
}): Promise<EpisodeWithPodcast> {
  if (episodeId.startsWith('custom-') || !isNaN(Number(episodeId))) {
    const episode = await getEpisode(episodeId)
    if (!episode) throw new Error('Not found')
    return {
      podcast: userAddedPodcast,
      ...episode,
    }
  }

  return (await episodeDetail({episodeId})).data
}

import React, {useEffect, useRef} from 'react'
import {
  Container,
  Modal,
  Typography,
  makeStyles,
  createStyles,
  IconButton,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {useSettings} from '../SettingsProvider'

const useStyles = makeStyles((theme) =>
  createStyles({
    modalBody: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
      maxHeight: '90vh',
      overflow: 'scroll',
    },
    inner: {
      textAlign: 'center',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    timeButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    timeIcon: {
      fontSize: '3rem',
    },
    buttons: {
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    playbackSpeed: {
      margin: '8px',
    },
  })
)

interface Props {
  className?: string
  autoHide?: boolean
  onClose: () => void
  showModal: boolean
}

function SettingsModal({className, showModal, onClose, autoHide}: Props) {
  const classes = useStyles()

  const settings = useSettings()
  const timeoutRef = useRef<any | null>(null)

  const onFasterClicked = () => {
    settings.increasePlaybackSpeed()
  }

  const onSlowerClicked = () => {
    settings.decreasePlaybackSpeed()
  }

  useEffect(() => {
    if (!autoHide) return
    console.log('Setting up auto hide')
    timeoutRef.current = setTimeout(() => {
      onClose()
    }, 5000)
  }, [autoHide, onClose])

  return (
    <Modal className={`${className}`} open={showModal} onClose={onClose}>
      <Container className={classes.modalBody} maxWidth="sm">
        <div className={classes.inner}>
          <div>
            <Typography variant="h5">Playback Speed</Typography>
          </div>
          <div className={classes.timeButtons}>
            <IconButton onClick={onSlowerClicked}>
              <ArrowDownwardIcon />
            </IconButton>
            <Typography variant="h5" className={classes.playbackSpeed}>
              {settings.settingsData.playbackSpeed.toFixed(1)}x{' '}
            </Typography>
            <IconButton onClick={onFasterClicked}>
              <ArrowUpwardIcon />
            </IconButton>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

export default React.memo(SettingsModal)

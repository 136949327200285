import React, {useState, useCallback} from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
// import HomeIcon from '@material-ui/icons/Home'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {Link, useHistory} from 'react-router-dom'
import {useUser} from '../../../utils/auth'
import {useAuth} from '../../AuthProvider'

export const APP_BAR_CONTAINER_ID = 'app-bar-container'
const useStyles = makeStyles<Theme, {drawerWidth: string | number}>((theme) =>
  createStyles({
    appBar: (props) => ({
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${props.drawerWidth}px)`,
      },
    }),
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    titleLink: {
      flexGrow: 1,
      textDecoration: 'none',
    },
    title: {
      color: 'white',
    },
  })
)

interface Props {
  drawerWidth: string | number
  onMenuButtonClicked: () => void
}

function TopBar({drawerWidth, onMenuButtonClicked}: Props) {
  const classes = useStyles({drawerWidth})
  const user = useUser()
  const authorization = useAuth()
  const history = useHistory()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(
    null
  )
  const openUserMenu = useCallback(
    (event) => {
      setUserMenuAnchorEl(event.currentTarget)
    },
    [setUserMenuAnchorEl]
  )

  const closeUserMenu = useCallback(() => {
    setUserMenuAnchorEl(null)
  }, [setUserMenuAnchorEl])

  const handleLogoutClicked = useCallback(() => {
    closeUserMenu()
    authorization.logout()
  }, [closeUserMenu])

  const signInClick = useCallback(() => {
    authorization.authenticateUser({})
  }, [])

  const onBackClick = useCallback(() => {
    history.goBack()
  }, [])

  return (
    <AppBar
      id={APP_BAR_CONTAINER_ID}
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={onMenuButtonClicked}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        {history.location.pathname !== '/' && (
          <IconButton color="inherit" onClick={onBackClick}>
            <ArrowBackIosIcon />
          </IconButton>
        )}

        <Link to="/" className={classes.titleLink}>
          {/* <HomeIcon htmlColor="white" fontSize="large" /> */}
          <Typography className={classes.title} variant="h6">
            ClipVo
          </Typography>
          <Typography className={classes.title} variant="caption">
            Discover, Save, Share
          </Typography>
        </Link>
        {user && (
          <>
            <IconButton
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={openUserMenu}
            >
              <Avatar
                alt={user.displayName || user.email || ''}
                src={user.photoURL || ''}
              />
            </IconButton>
            <Menu
              id="user-menu"
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={!!userMenuAnchorEl}
              anchorEl={userMenuAnchorEl}
              onClose={closeUserMenu}
            >
              <MenuItem disabled>
                Version: {process.env.REACT_APP_CI_COMMIT_SHORT_SHA || 'local'}
              </MenuItem>
              <MenuItem disabled>
                Logged as {user.displayName} ( {user.email} )
              </MenuItem>
              <MenuItem onClick={handleLogoutClicked}>Logout</MenuItem>
            </Menu>
          </>
        )}
        {!authorization.isLoggedIn() && (
          <IconButton onClick={signInClick} data-tut="guide_signin">
            <Typography className={classes.title} variant="h6" noWrap>
              Sign In
            </Typography>
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar

import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core'
import StandaloneBookmark, {StandaloneBookmarkData} from './StandaloneBookmark'
import {Bookmark} from '../../../db/models'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(-2, 0),
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
  })
)

interface Props {
  data: StandaloneBookmarkData[]
  className?: string
  onBookmarkRemoved: (bookmark: Bookmark) => void
  onBookmarkUpdated: (bookmark: Bookmark) => void
}

function BookmarksList({
  data,
  className,
  onBookmarkRemoved,
  onBookmarkUpdated,
}: Props) {
  const classes = useStyles()
  return (
    <div className={`${classes.root} ${className}`}>
      {data.map((one) => (
        <StandaloneBookmark
          onRemoved={onBookmarkRemoved}
          onUpdated={onBookmarkUpdated}
          data={one}
        />
      ))}
    </div>
  )
}

export default BookmarksList

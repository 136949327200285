import React, {useCallback} from 'react'
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Link as MuiLink,
} from '@material-ui/core'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Bookmark, Media} from '../../../db/models'
import {PodcastWithEpisode} from '../../../apis/listenNotes/models'
import BookmarkView from '../../BookmarkView'
import ClipProgress from '../../ClipProgress'
import {generateEpisodeLink, generatePodcastLink} from '../../../utils/routes'
import {useAnalytics} from '../../../utils/analytics'

export interface StandaloneBookmarkData {
  bookmark: Bookmark
  episode: Media
  podcast: PodcastWithEpisode
  authorEmail?: string
  authorName?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    author: {
      width: '100%',
      textAlign: 'right',
      fontSize: '.75rem',
      color: '#8f8f8f',
    },
    date: {
      width: '100%',
      textAlign: 'right',
      fontSize: '.75rem',
      color: '#8f8f8f',
    },
    podcastName: {
      color: '#8f8f8f',
      textDecoration: 'none',
    },
    episodeName: {
      color: 'inherit',
      textDecoration: 'none',
    },
  })
)

interface Props {
  className?: string
  data: StandaloneBookmarkData
  onRemoved: (bookmark: Bookmark) => void
  onUpdated: (newBookmarkData: Bookmark) => void
}

const PlainBookmarkRootComponent = React.forwardRef(
  (
    {
      className,
      children,
    }: {
      children: React.ReactNode
      className?: string
    },
    ref: any
  ) => (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
)

function StandaloneBookmark({
  data: {bookmark, podcast, episode, authorName},
  onRemoved,
  onUpdated,
  className,
}: Props) {
  const classes = useStyles()
  const analytics = useAnalytics()

  const reportPlayed = useCallback(() => {
    analytics.logEvent({
      eventName: 'feed_bookmark_played',
      params: {
        origin: bookmark.endPositionSec ? 'clip' : 'bookmark',
      },
    })
  }, [analytics, bookmark.endPositionSec])

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiLink
              className={classes.episodeName}
              component={Link}
              to={generateEpisodeLink({episodeId: episode.id})}
            >
              <Typography variant="h6">{episode.title}</Typography>
            </MuiLink>
            <MuiLink
              className={classes.podcastName}
              component={Link}
              to={generatePodcastLink({podcastId: episode.podcastId})}
            >
              <Typography>{episode.podcastName}</Typography>
            </MuiLink>
          </Grid>
        </Grid>
        <BookmarkView
          onRemoved={onRemoved}
          onUpdated={onUpdated}
          onPlayed={reportPlayed}
          podcast={podcast}
          bookmark={bookmark}
          episode={episode}
          RootContainer={PlainBookmarkRootComponent}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.author} align="right">
              by: {authorName || 'unknown'}
            </Typography>
            <Typography className={classes.date} align="right">
              Shared {moment(bookmark.sharedAtIsoDate).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <ClipProgress bookmark={bookmark} />
    </Card>
  )
}

export default StandaloneBookmark

import React, {useCallback, useEffect, useRef} from 'react'
import {Container, Modal, makeStyles, createStyles} from '@material-ui/core'

import {useSnackbar} from 'notistack'
import {Bookmark} from '../../../db/models'
import {Podcast} from '../../../apis/listenNotes/models'
import EpisodeMedia from '../../ResolveMedia/EpisodeMedia'
import EditBookmarkForm from '../../EditBookmarkForm'

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      zIndex: 0,
    },
    modalBody: {
      zIndex: 3000,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
      maxHeight: '90vh',
      overflow: 'scroll',
    },
    inner: {
      textAlign: 'center',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
  })
)

interface Props {
  className?: string
  autoHide?: boolean
  bookmarkData: {
    bookmark: Bookmark
    episode: EpisodeMedia
    podcast: Podcast
  } | null
  onClose: () => void
}

function NewBookmarkModal({className, bookmarkData, onClose, autoHide}: Props) {
  const classes = useStyles()

  const {enqueueSnackbar} = useSnackbar()
  const timeoutRef = useRef<any | null>(null)

  const cancelAutoHide = useCallback(() => {
    clearTimeout(timeoutRef.current)
  }, [timeoutRef])

  useEffect(() => {
    if (!autoHide || !bookmarkData) return
    console.log('Setting up auto hide')
    timeoutRef.current = setTimeout(() => {
      onClose()
      enqueueSnackbar('Bookmark added', {
        variant: 'success',
      })
    }, 5000)
  }, [autoHide, bookmarkData, onClose])

  if (!bookmarkData) return null

  return (
    <Modal
      disableBackdropClick
      className={`${className} ${classes.modal}`}
      open={!!bookmarkData}
      onClose={onClose}
    >
      <Container className={classes.modalBody} maxWidth="sm">
        <EditBookmarkForm
          bookmarkData={bookmarkData}
          onFinish={onClose}
          onPopulated={cancelAutoHide}
        />
      </Container>
    </Modal>
  )
}

export default React.memo(NewBookmarkModal)

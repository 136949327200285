export function convertToReadableString(timeSec: number) {
  const sec = Math.floor(timeSec % 60)
  const mins = Math.floor(timeSec / 60) % 60
  const hours = Math.floor(timeSec / 3600)

  const secToDisplay = sec < 10 ? `0${sec}` : sec
  const minsToDisplay = mins < 10 ? `0${mins}` : mins
  const hoursToDisplay = hours < 10 ? `0${hours}` : hours

  return `${hoursToDisplay}:${minsToDisplay}:${secToDisplay}`
}

export function convertStringToSeconds(string: string) {
  const split = string.split(':')

  if (split.length === 1) return Number(split[0])
  if (split.length === 2) return Number(split[0]) * 60 + Number(split[1])
  if (split.length === 3) {
    return Number(split[0]) * 60 * 60 + Number(split[1]) * 60 + Number(split[2])
  }
  return Number.NaN
}

export function isYoutubeUrl(url: string) {
  return url.match(/^((http|https):\/\/)?(www\.youtube\.com|youtu\.?be).*$/)
}

export function isDropboxMp4(url: string) {
  return url.match(/dropbox.com.*\.mp4$/)
}

export function isVideoUrl(url: string) {
  return isYoutubeUrl(url) || isDropboxMp4(url)
}

export function formatUrlIfVideo(url: string) {
  if (isDropboxMp4(url)) return `${url}?raw=1`
  return url
}

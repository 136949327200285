import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'

import ReactHtmlParser from 'react-html-parser'
import {Podcast} from '../../../apis/listenNotes/models'
import EpisodeMedia from '../../ResolveMedia/EpisodeMedia'
import {Bookmark, UserMedia} from '../../../db/models'
import {useSettings} from '../../SettingsProvider'
import BookmarksList from './BookmarksList'
import PodcastTitle from '../../PodcastTitle'
import ClippedContent from '../../ClippedContent'
import {useAuth} from '../../AuthProvider'
import InteractivePlayButton from '../../InteractivePlayButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    episodeTitle: {
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(2, 0, 0),
    },
    episodeTitleContainer: {
      position: 'sticky',
      top: '50px',
      zIndex: 300,
      background: '#FAFAFA',
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    podcastTitle: {
      margin: theme.spacing(0, 0, 2),
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    podcastImage: {
      maxWidth: '120px',
      marginTop: '10px',
    },
    bookmarks: {
      width: '100%',
      marginTop: '20px',
    },
    excerpt: {
      flexGrow: 1,
      margin: '5px',
    },
    tabsContainer: {
      background: '#FAFAFA',
    },
  })
)

interface BookmarkList {
  items: Bookmark[] | null
  label: string
  onBookmarkPlayed?: (bookmark: Bookmark) => void
}

interface Props {
  episode: EpisodeMedia
  podcast: Podcast
  userMedia: UserMedia | undefined
  bookmarksLists: Array<BookmarkList>
  initialSelectedTab: 'tab1' | 'tab2'
}

function EpisodeView({
  episode,
  podcast,
  userMedia,
  bookmarksLists,
  initialSelectedTab,
}: Props) {
  const classes = useStyles()
  const auth = useAuth()
  const bookmarksRef = useRef(null)
  const settings = useSettings()

  const [selectedSection, setSelectedSection] = useState(
    initialSelectedTab || 'tab1'
  )

  const scrollToBookmarks = useCallback(
    (ref: any) => {
      if (ref.current) {
        ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    },
    [bookmarksRef]
  )

  useEffect(() => {
    // TODO this wont work in case that loading takes more than 800ms...
    setTimeout(() => {
      if (settings.settingsData.navigateToBookmarks === true) {
        scrollToBookmarks(bookmarksRef)
        settings.navigateToBookmarks(false)
      }
    }, 800)
  }, [settings.settingsData.navigateToBookmarks])

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <img src={episode.thumbnail} alt="" className={classes.podcastImage} />
        <div className={classes.episodeTitleContainer}>
          <Typography className={classes.episodeTitle} variant="h6">
            {episode.title}
          </Typography>
        </div>
        <div className={classes.podcastTitle}>
          <PodcastTitle title={podcast.title} id={podcast.id} />
        </div>
        <ClippedContent numLines={1}>
          {ReactHtmlParser(episode.episodeDescription || '')}
        </ClippedContent>

        <InteractivePlayButton
          episode={episode}
          podcast={podcast}
          playFromPosition={userMedia?.lastPlayedLocation}
          originForAnalytics="episode_screen"
          buttonProps={{color: 'primary', variant: 'contained'}}
        />
      </Container>
      <div ref={bookmarksRef} className={classes.bookmarks}>
        <Paper elevation={0}>
          <Tabs
            centered
            value={selectedSection}
            className={classes.tabsContainer}
            onChange={(e, newSection) => {
              if (newSection === 'tab1' && !auth.isLoggedIn())
                auth.authenticateUser({
                  modalTitle: 'Login to see your bookmarks',
                })
              else setSelectedSection(newSection)
            }}
          >
            <Tab label={bookmarksLists[0].label} value="tab1" />
            <Tab label={bookmarksLists[1].label} value="tab2" />
          </Tabs>
        </Paper>
        <Container maxWidth="md">
          {selectedSection === 'tab1' && (
            <BookmarksList
              episode={episode}
              podcast={podcast}
              bookmarks={bookmarksLists[0].items}
              onBookmarkPlayed={
                bookmarksLists[0].onBookmarkPlayed || (() => undefined)
              }
            />
          )}
          {selectedSection === 'tab2' && (
            <BookmarksList
              episode={episode}
              podcast={podcast}
              bookmarks={bookmarksLists[1].items}
              onBookmarkPlayed={
                bookmarksLists[1].onBookmarkPlayed || (() => undefined)
              }
            />
          )}
        </Container>
      </div>
    </div>
  )
}

export default EpisodeView

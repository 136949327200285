import React, {useCallback, useState} from 'react'
import newBookmarkContext, {useEditBookmarkPopup} from './context'
import NewBookmarkModal from './components/NewBookmarkModal'
import {Bookmark} from '../../db/models'
import EpisodeMedia from '../ResolveMedia/EpisodeMedia'
import {Podcast} from '../../apis/listenNotes/models'

interface Props {
  children: React.ReactNode
}

function BookmarkPopupProvider({children}: Props) {
  const [modalData, setModalData] = useState<{
    bookmark: Bookmark
    podcast: Podcast
    episode: EpisodeMedia
    autoHide: boolean
  } | null>(null)

  const handleOpenBookmarkDialog = useCallback(
    (data: {
      bookmark: Bookmark
      podcast: Podcast
      episode: EpisodeMedia
      autoHide: boolean
    }) => {
      setModalData(data)
    },
    []
  )

  return (
    <newBookmarkContext.Provider
      value={{
        openBookmarkDialog: handleOpenBookmarkDialog,
        editing: !!modalData,
        close: useCallback(() => {
          setModalData(null)
        }, [setModalData]),
      }}
    >
      <NewBookmarkModal
        bookmarkData={
          modalData
            ? {
                bookmark: modalData.bookmark,
                podcast: modalData.podcast,
                episode: modalData.episode,
              }
            : null
        }
        autoHide={modalData?.autoHide}
        onClose={useCallback(() => setModalData(null), [setModalData])}
      />
      {children}
    </newBookmarkContext.Provider>
  )
}

export default BookmarkPopupProvider
export {useEditBookmarkPopup}

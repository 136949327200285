import {useEffect} from 'react'
import firebase from 'firebase'
import md5 from 'md5'
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../components/AuthProvider'

// TODO change this
const analyticsUrl =
  'https://us-central1-audiobook-bookmarks.cloudfunctions.net/analytics'

const analytics = firebase.analytics()
let userId = 'loggedOut'

/**
 * Use this wrapper in order to make it easier if we decide to move to a different analytics tool or add a new one
 */

function logEvent({
  eventName,
  params,
  description,
}: {
  eventName: string
  params?: {[key: string]: string | number | boolean}
  description?: string
}) {
  console.info('Analytics event', {eventName, params, description})
  analytics.logEvent(eventName, {...params, description})

  // lets do a simple this. Let's test it later...
  // @ts-ignore
  // eslint-disable-next-line no-undef
  window.gtag('event', eventName)

  axios
    .post(analyticsUrl, {
      user_id: userId,
      createdAt: new Date().toISOString(),
      event_name: eventName,
      from_env: window.location.host || 'unknown',
      params,
    })
    .catch((e) => {
      console.warn('unable to send analytics log', e)
    })
}

/**
 * Use hooks in case we want to use context for enhancing the reported data
 */
export function useAnalytics() {
  return {
    logEvent,
    _analytics: analytics,
  }
}

export function useAnalyticsUserTracking() {
  const {_analytics} = useAnalytics()
  const authorization = useAuth()

  useEffect(() => {
    userId = authorization.authData.user?.uid || authorization.authData.deviceId
    _analytics.setUserId(md5(userId))
  }, [
    authorization.authData.isLoggedIn,
    authorization.authData.deviceId,
    authorization.authData.user,
  ])
}

export function useReportUrlChange() {
  const {pathname} = useLocation()
  const mAnalytics = useAnalytics()

  useEffect(() => {
    mAnalytics.logEvent({
      eventName: 'url_change',
      params: {url: pathname},
    })
  }, [pathname, mAnalytics])
}

import React, {useCallback, useMemo, useState} from 'react'
import deepEqual from 'fast-deep-equal'
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import {Link} from 'react-router-dom'
import {useAsync} from 'react-async'
import {useSnackbar} from 'notistack'
import {UserRecord} from '../models'
import ReadableTime from '../../ReadableTime'
import PodcastTitle from '../../PodcastTitle'
import {MediaType} from '..'
import {markMediaFavoriteUnfavoriteInDb} from '../../../db'
import InteractivePlayButton from '../../InteractivePlayButton'
import BookmarksList from '../../EpisodeScreen/component/BookmarksList'
import {Bookmark} from '../../../db/models'
import {useAnalytics} from '../../../utils/analytics'
import {generateEpisodeLink} from '../../../utils/routes'
import {getPodcastDetail} from '../../../apis/podcastResolver'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      maxWidth: '100%',
    },
    cardContent: {},
    title: {
      overflowWrap: 'break-word',
      overflow: 'hidden',
      lineHeight: '1.5em',
      height: '3em',
      textOverflow: 'ellipsis',
    },
    subTitle: {
      maxWidth: window.innerWidth - 150,
    },
    image: {
      width: '80%',
      margin: 'auto',
    },
    imageContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      display: 'flex',
    },
    boldText: {},
    filler: {
      flex: 1,
    },
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      margin: 'auto',
      maxWidth: '100%',
    },
    buttons: {
      margin: theme.spacing(-1),
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      justifyItems: 'center',
      alignItems: 'center',
    },
    bookmarkList: {
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
    bookmarkListItems: {
      marginTop: theme.spacing(1),
    },
    titleRow: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      marginRight: 'auto',
    },
    bookmarkTitle: {},
  })
)

function UserMediaItem({
  record,
  mediaType,
}: {
  record: UserRecord
  mediaType: MediaType
}) {
  const classes = useStyles()
  const analytics = useAnalytics()
  const {enqueueSnackbar} = useSnackbar()
  const [favorite, setFavorite] = useState(record.favorite)
  const [mediaBookmarks, setMediaBookmarks] = useState(record.bookmarks)

  const startContinueThreshold = 20

  const getPodcastTask = useAsync({
    promiseFn: useCallback(async () => {
      const response = await getPodcastDetail({
        podcastId:
          record.episode.listennotesPodcastUid || record.episode.podcastId,
      })
      return response
    }, [record]),
  })

  const onFavoriteIconClick = useCallback(() => {
    markMediaFavoriteUnfavoriteInDb({
      mediaId: record.episode.id,
      favorite: !record.favorite,
    }).catch(() =>
      enqueueSnackbar('Error adding media into favorite. Please try again', {
        variant: 'error',
      })
    )
    setFavorite(!favorite)
  }, [record, favorite])

  const playButtonLabel = useMemo(() => {
    if (mediaType === MediaType.POPULAR) {
      return 'Play'
    }
    if (
      record.lastPlayedLocation < startContinueThreshold ||
      record.lastPlayedLocation >
        record.episode.episodeLength - startContinueThreshold
    ) {
      return 'Restart'
    }
    return 'Continue'
  }, [record, mediaType])

  const deleteBookmark = useCallback(
    (bookmark: Bookmark) => {
      const updatedBookmarks = mediaBookmarks?.filter(
        (item) => item.id !== bookmark.id
      )
      setMediaBookmarks(updatedBookmarks)
    },
    [mediaBookmarks, setMediaBookmarks]
  )
  const editBookmark = useCallback(
    (bookmark: Bookmark) => {
      if (mediaBookmarks) {
        const bookmarkIndex = mediaBookmarks.findIndex(
          (item) => item.id === bookmark.id
        )
        const updatedBookmarks = [...mediaBookmarks]
        updatedBookmarks[bookmarkIndex] = bookmark
        setMediaBookmarks(updatedBookmarks)
      }
    },
    [mediaBookmarks, setMediaBookmarks]
  )

  const onBookmarkPlayed = useCallback(() => {
    analytics.logEvent({
      eventName: 'playback_started',
      params: {
        origin: 'bookmark',
        bookmarkLocation: 'library_screen',
      },
    })
  }, [analytics])

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={3} md={2}>
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={record.episode.thumbnail}
                alt=""
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.content}>
              <Typography variant="subtitle1" className={classes.title}>
                <Link
                  to={generateEpisodeLink({episodeId: record.episode.id})}
                  style={{maxWidth: '100%'}}
                >
                  {record.episode.title}
                </Link>
              </Typography>

              <PodcastTitle
                title={record.episode.podcastName || ''}
                id={record.episode.podcastId}
              />
              {mediaType === MediaType.MY_LIBRARY && (
                <Typography>
                  <ReadableTime inSeconds={record.lastPlayedLocation} /> /{' '}
                  <ReadableTime inSeconds={record.episode.episodeLength} />
                </Typography>
              )}

              <div className={classes.filler} />
              <div className={classes.buttons}>
                {mediaType !== MediaType.POPULAR && (
                  <Box component="div" display={{xs: 'block', sm: 'block'}}>
                    <InteractivePlayButton
                      originForAnalytics="user_library"
                      podcast={getPodcastTask.data}
                      episode={record.episode}
                      buttonProps={{
                        // disabled: getPodcastTask.isLoading,
                        variant: 'contained',
                        color: 'primary',
                      }}
                      playFromPosition={record.lastPlayedLocation}
                      notPlayingText={playButtonLabel}
                    />
                  </Box>
                )}

                {mediaType === MediaType.MY_LIBRARY && (
                  <IconButton onClick={onFavoriteIconClick}>
                    {favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        {mediaBookmarks && mediaBookmarks.length > 0 && getPodcastTask.data && (
          <div className={classes.bookmarkListItems}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.bookmarkTitle}>
                  Bookmarks & Clips ({mediaBookmarks.length})
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BookmarksList
                  episode={record.episode}
                  podcast={getPodcastTask.data}
                  bookmarks={mediaBookmarks || []}
                  onBookmarkPlayed={onBookmarkPlayed}
                  showSettings={false}
                  onBookmarkEdited={editBookmark}
                  onBookmarkDeleted={deleteBookmark}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default React.memo(UserMediaItem, deepEqual)

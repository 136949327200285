/* eslint-disable no-nested-ternary */
import {useMemo, useCallback} from 'react'
import {auth} from 'firebase/app'
import {firestore, remoteConfig} from 'firebase'
import moment from 'moment'
import {
  Bookmark,
  BookmarkType,
  Media,
  UserMedia,
  UserRecordFromDb,
} from './models'
import useSnapshot, {extractBookmarkData} from './utils'
import {DEFAULT_CLIP_LENGTH} from '../components/SettingsProvider'
import {useAuth} from '../components/AuthProvider'
import {UserRecord} from '../components/LibraryScreen/models'
import {FilterType} from '../components/BookmarkTypeFilter'
import extractIdsFromBookmark from '../utils/extractIdsFromBookmarkPath'

const COLLECTION_USERS = 'users'
const COLLECTION_USER_MEDIA = 'media'
const COLLECTION_USER_BOOKMARKS = 'bookmarks'
const COLLECTION_MEDIA_IDS = 'media-ids'

function getDocForBookmarkAndVerifyThatCurrentUserIsOwner(bookmark: Bookmark) {
  const {currentUser} = auth()

  if (!currentUser) return null
  if (!bookmark._path) return null

  const bookmarksIds = extractIdsFromBookmark(bookmark._path)
  if (bookmarksIds?.authorId !== currentUser.uid) return null

  return firestore().doc(bookmark._path)
}

function getUserRef(userId?: string) {
  const {currentUser} = auth()
  if (!currentUser && !userId) {
    userId = remoteConfig().getValue('guest_user_id').asString()
  }

  if (userId) return firestore().collection(COLLECTION_USERS).doc(userId)

  return firestore().collection(COLLECTION_USERS).doc(currentUser?.uid)
}

function getUserMediaRef({
  mediaId,
  userId,
}: {
  mediaId: string
  userId?: string
}) {
  return getUserRef(userId).collection(COLLECTION_USER_MEDIA).doc(mediaId)
}

function getBookmarksRef({
  mediaId,
  userId,
}: {
  mediaId: string
  userId?: string
}) {
  return getUserMediaRef({mediaId, userId}).collection(
    COLLECTION_USER_BOOKMARKS
  )
}

function getMediaRef() {
  return firestore().collection(COLLECTION_MEDIA_IDS)
}

export async function setMediaLastPlayed({
  mediaId,
  timeSec,
}: {
  mediaId: string
  timeSec: number
}) {
  const userMedia = getUserMediaRef({mediaId})
  await userMedia.set(
    {lastPlayedLocation: timeSec, lastPlayedAt: new Date()},
    {merge: true}
  )

  console.log('set media last played', {mediaId, timeSec})
}

export async function setMediaSharingPreference({
  mediaId,
  sharable,
}: {
  mediaId: string
  sharable: boolean
}) {
  const userMedia = getUserMediaRef({mediaId})
  await userMedia.set({clipsSharable: sharable}, {merge: true})
}

export async function setMediaLength({
  mediaId,
  mediaLength,
}: {
  mediaId: string
  mediaLength: number
}) {
  if (!auth().currentUser) return

  await getMediaRef()
    .doc(mediaId)
    .set({episodeLength: mediaLength}, {merge: true})
}

export async function getLastPlayedTime({
  mediaId,
}: {
  mediaId: string
}): Promise<number | null> {
  const userMedia = getUserMediaRef({mediaId})
  if (!auth().currentUser) return null

  return (await userMedia.get()).data()?.lastPlayedLocation || null
}

export async function getUserMediaDetails({
  mediaId,
}: {
  mediaId: string
}): Promise<UserMedia> {
  const userMedia = getUserMediaRef({mediaId})
  const userMediaDetails = await userMedia.get()

  const data = userMediaDetails.data()

  return {
    ...data,
    lastPlayedLocation: auth().currentUser ? data?.lastlastPlayedLocation : 0,
  } as UserMedia
}

export function useBookmarksForMedia({
  mediaId,
  userId,
}: {
  mediaId: string
  userId?: string
}) {
  const appAuth = useAuth()
  const bookmarksRef = useMemo(
    () => getBookmarksRef({mediaId, userId}).orderBy('positionSec', 'asc'),
    [mediaId, userId]
  )
  let bookmarkType = BookmarkType.MY_BOOKMARKS
  if (userId === appAuth.curatedUserId) {
    bookmarkType = BookmarkType.POPULAR_BOOKMARKS
  } else if (userId && userId !== appAuth.authData.user?.uid) {
    bookmarkType = BookmarkType.SHARED_BOOKMARKS
  }
  return useSnapshot<Bookmark>(
    bookmarksRef,
    useCallback((one) => extractBookmarkData(one.raw, {bookmarkType}), [
      mediaId,
    ])
  )
}

export function useBookmarksForUser({
  mediaId,
  userId,
  bookmarkType,
}: {
  mediaId: string
  userId: string
  bookmarkType?: BookmarkType
}) {
  const bookmarksRef = useMemo(
    () => getBookmarksRef({mediaId, userId}).orderBy('positionSec', 'asc'),
    [mediaId]
  )

  return useSnapshot<Bookmark>(
    bookmarksRef,
    useCallback((one) => extractBookmarkData(one.raw, {bookmarkType}), [
      mediaId,
    ])
  )
}

export async function getBookmarksForMedia({
  mediaId,
  userId,
}: {
  mediaId: string
  userId?: string
}) {
  const bookmarksRef = await getBookmarksRef({mediaId, userId})
    .orderBy('positionSec', 'asc')
    .get()

  const bookmarkType =
    userId && userId === auth().currentUser?.uid
      ? BookmarkType.MY_BOOKMARKS
      : BookmarkType.POPULAR_BOOKMARKS

  const bookmarks: Array<Bookmark> = []
  bookmarksRef.forEach((one) => {
    bookmarks.push(extractBookmarkData(one, {bookmarkType}))
  })
  return bookmarks
}

export async function addBookmark(newBookmark: Bookmark): Promise<Bookmark> {
  const bookmarkToAdd = {
    positionSec: Number(newBookmark.positionSec),
    description: newBookmark.description,
    created: moment(newBookmark.created).toISOString(),
    id: newBookmark.id,
    // firebase does not accept undefined as a value.
    ...(newBookmark.endPositionSec !== undefined
      ? {endPositionSec: Number(newBookmark.endPositionSec)}
      : {}),
  }

  if (!auth().currentUser) return newBookmark

  const bookmarkRef = getBookmarksRef({
    mediaId: newBookmark.mediaId,
  }).doc(newBookmark.id)

  await bookmarkRef.set(bookmarkToAdd)

  return extractBookmarkData(await bookmarkRef.get(), {
    bookmarkType: newBookmark.bookmarkType,
  })
}

export async function updateBookmark(
  updatedBookmark: Bookmark
): Promise<Bookmark> {
  const {currentUser} = auth()
  if (!currentUser) return updatedBookmark

  const newData: any = {
    id: updatedBookmark.id,
    positionSec: Number(updatedBookmark.positionSec),
    description: updatedBookmark.description,
    created: moment(updatedBookmark.created).toISOString(),
  }

  if (updatedBookmark.endPositionSec) {
    newData.endPositionSec = Number(updatedBookmark.endPositionSec)
  }

  if (
    updatedBookmark.sharedAtIsoDate &&
    moment(updatedBookmark.sharedAtIsoDate).isValid()
  ) {
    newData.sharedAtIsoDate = updatedBookmark.sharedAtIsoDate
  }

  if (!updatedBookmark.mediaId) {
    console.warn('Media id not found in bookmark')
    return updatedBookmark
  }

  const bookmarkDoc = getDocForBookmarkAndVerifyThatCurrentUserIsOwner(
    updatedBookmark
  )
  if (!bookmarkDoc) {
    console.warn('‼️ doc for bookmark not found', updatedBookmark)
    return updatedBookmark
  }

  await bookmarkDoc.set(newData, {merge: false}) // Override the existing document. This way we can remove endPositionSec. Otherwise we had to delete the field in another query.

  return updatedBookmark
}

export async function setBookmarkShareable(
  bookmark: Bookmark,
  shareable: boolean
) {
  const newFieldValue = shareable
    ? moment().toISOString()
    : firestore.FieldValue.delete()

  const bookmarkDoc = getDocForBookmarkAndVerifyThatCurrentUserIsOwner(bookmark)
  if (!bookmarkDoc) {
    console.warn('‼️ doc for bookmark not found', bookmark)
    return bookmark
  }
  await bookmarkDoc.set({sharedAtIsoDate: newFieldValue}, {merge: true})
}

export async function removeBookmark(bookmark: Bookmark) {
  const {currentUser} = auth()
  if (!currentUser) return bookmark.id

  const bookmarkDoc = getDocForBookmarkAndVerifyThatCurrentUserIsOwner(bookmark)
  if (!bookmarkDoc) {
    console.warn('‼️ doc for bookmark not found', bookmark)
    return bookmark.id
  }

  await bookmarkDoc.delete()

  return bookmark.id
}

// export async function getUserMedia(): Promise<UserMedia[]> {
//   const userMediaRaw = await getUserRef()
//     .collection(COLLECTION_USER_MEDIA)
//     .orderBy('lastPlayedAt', 'desc')
//     .get()
//   const userMedia: any[] = []
//
//   userMediaRaw.forEach((oneRaw) =>
//     userMedia.push({...oneRaw.data(), id: oneRaw.id})
//   )
//   return userMedia
// }

export function useUserMedia(userId: string, lastDoc?: UserRecord, limit = 10) {
  const userMediaRef = useMemo(
    () =>
      getUserRef(userId)
        .collection(COLLECTION_USER_MEDIA)
        .orderBy('lastPlayedAt', 'desc')
        .limit(limit),

    [userId, lastDoc]
  )

  console.log('user', auth().currentUser)

  return useSnapshot<UserMedia>(
    userMediaRef,

    useCallback(
      (media) => ({
        ...media,
        lastPlayedLocation: auth().currentUser ? media.lastPlayedLocation : 0,
        lastPlayedAt: media.lastPlayedAt,
      }),
      [userId]
    )
  )
}

export async function getUserMedia(
  userId: string,
  lastDoc?: UserRecord,
  limit = 10
) {
  console.log(`lg lastRecord`, lastDoc)
  const userMediaRef = lastDoc
    ? getUserRef(userId)
        .collection(COLLECTION_USER_MEDIA)
        .orderBy('lastPlayedAt', 'desc')
        .startAfter(lastDoc.lastPlayedAt)
        .limit(limit)
    : getUserRef(userId)
        .collection(COLLECTION_USER_MEDIA)
        .orderBy('lastPlayedAt', 'desc')
        .limit(limit)

  return userMediaRef.get()
}

export async function getLatestPlayback(): Promise<UserMedia | null> {
  if (!auth().currentUser) return null

  const userMediaRaw = await getUserRef()
    .collection(COLLECTION_USER_MEDIA)
    .orderBy('lastPlayedAt', 'desc')
    .limit(1)
    .get()

  const userMedia: any[] = []

  userMediaRaw.forEach((oneRaw) =>
    userMedia.push({...oneRaw.data(), id: oneRaw.id})
  )

  if (userMedia.length === 0) return null
  return userMedia[0]
}

export async function getUserPlaybackRate() {
  if (!auth().currentUser) return null

  return (await getUserRef().get()).data()?.playbackRate || null
}

export async function getBackwardStepSizeSec() {
  if (!auth().currentUser) return null

  return (await getUserRef().get()).data()?.backwardStepSizeSec || null
}

export async function getForwardStepSizeSec() {
  if (!auth().currentUser) return null

  return (await getUserRef().get()).data()?.forwardStepSizeSec || null
}

export async function getExpandedVideoPosition() {
  if (!auth().currentUser) return null

  const data = (await getUserRef().get()).data()
  return {xFromTop: data?.xFromTop || 0, yFromTop: data?.yFromTop || 0}
}

export async function setUserPlaybackRate(playbackRate: number) {
  if (!auth().currentUser) return

  await getUserRef().set({playbackRate}, {merge: true})
}

export async function getUserSettingStopAfterClipEnds(): Promise<boolean> {
  if (!auth().currentUser) return false

  return (await getUserRef().get()).data()?.stopAfterClipEnds || false
}

export async function setUserSettingStopAfterClipEnds(value: boolean) {
  if (!auth().currentUser) return

  await getUserRef().set({stopAfterClipEnds: value}, {merge: true})
}

export async function setUserSettingExpandedVideoPosition({
  xFromTop,
  yFromTop,
}: {
  xFromTop: number
  yFromTop: number
}) {
  if (!auth().currentUser) return

  await getUserRef().set({xFromTop, yFromTop}, {merge: true})
}

export async function setUserSettingForwardStepSizeSec(
  forwardStepSizeSec: number
) {
  if (!auth().currentUser) return

  await getUserRef().set({forwardStepSizeSec}, {merge: true})
}

export async function setUserSettingBackwardStepSizeSec(
  backwardStepSizeSec: number
) {
  if (!auth().currentUser) return

  await getUserRef().set({backwardStepSizeSec}, {merge: true})
}

export async function getUserSettings(): Promise<UserRecordFromDb> {
  const document = await getUserRef().get()
  return {...(document.data() as any), id: document.id}
}

export async function setUserSettings(value: UserRecordFromDb): Promise<void> {
  const document = await getUserRef()
  if (document.id === remoteConfig().getValue('guest_user_id').asString())
    return

  await document.set(value)
}

export async function setDefaultClipLength(value: number) {
  if (!auth().currentUser) return

  await getUserRef().set({defaultClipLength: value}, {merge: true})
}

export async function getDefaultClipLength(): Promise<number> {
  if (!auth().currentUser) return DEFAULT_CLIP_LENGTH

  return (
    (await getUserRef().get()).data()?.defaultClipLength || DEFAULT_CLIP_LENGTH
  )
}

export async function getMediaIdByUrl(mediaUrl: string) {
  if (!mediaUrl) return null

  const existingMedia = await getMediaRef()
    .where('episodeMediaUrl', '==', mediaUrl)
    .get()
  if (!existingMedia.empty) {
    let docId = ''
    existingMedia.forEach((doc) => {
      docId = doc.id
    })
    return docId
  }
  return null
}

export async function addMedia(media: Media, addedManually: boolean = false) {
  const {id} = media
  getMediaRef()
    .doc(id)
    .set({
      ...media,
      created: moment().toISOString(),
      addedManually,
    })
}

export async function getMediaById(mediaId: string): Promise<Media | null> {
  const mediaIdQuery = await getMediaRef().where('id', '==', mediaId).get()
  const media: any[] = []
  mediaIdQuery.forEach((row) => {
    media.push(row.data())
  })
  return media[0] || null
}

export async function markMediaFavoriteUnfavoriteInDb({
  mediaId,
  favorite,
}: {
  mediaId: string
  favorite: boolean
}) {
  if (!auth().currentUser) return

  await getUserMediaRef({mediaId}).set({favorite}, {merge: true})
}

export interface GetPublicBookmarksResult {
  data: Array<{
    bookmark: Bookmark
    episodeId: string
    authorEmail?: string
    authorName?: string
  }>
  isLast: boolean
  getNext: () => Promise<GetPublicBookmarksResult>
}

export async function getPublicBookmarks({
  limit,
  after,
  filter,
}: {
  limit: number
  after?: firestore.DocumentSnapshot
  filter: {
    type: FilterType
    notEmpty: boolean
  }
}): Promise<GetPublicBookmarksResult> {
  let query = firestore()
    .collectionGroup('bookmarks')
    // We might be tempted to do where lower than now(), but I was unable to find a way how to chain
    // where clauses. What if null < now()? We need to do where isShareable < now && isShareable != null.
    // This is more reliable. I thing
    .where('sharedAtIsoDate', '!=', null)
    .orderBy('sharedAtIsoDate', 'desc')

  if (after) query = query.startAfter(after)
  const response = await query.limit(limit).get()

  if (response.empty)
    return {
      data: [],
      isLast: true,
      getNext: () => {
        throw new Error('Trying to fetch more after end has been reached')
      },
    }

  const plainBookmarksSnapshots: any[] = []
  response.forEach((one) => {
    plainBookmarksSnapshots.push(one)
  })

  const filteredBookmarksSnapshot = plainBookmarksSnapshots.filter(
    (bookmark) => {
      const data = bookmark.data()

      if (filter.type === 'clips' && !data.endPositionSec) return false
      if (filter.type === 'bookmarks' && data.endPositionSec) return false
      if (filter.notEmpty && !data.description) return false

      return true
    }
  )

  const bookmarksWithPodcast = await Promise.all(
    filteredBookmarksSnapshot.map(async (bookmarkSnapshot) => {
      const bookmarkData = extractBookmarkData(bookmarkSnapshot, {})
      const episodeSnap = await bookmarkSnapshot.ref.parent.parent.get()
      const userSnap = await bookmarkSnapshot.ref.parent.parent.parent.parent.get()

      return {
        bookmark: bookmarkData,
        episodeId: episodeSnap.id,
        authorEmail: userSnap.data().email,
        authorName: userSnap.data().name,
      }
    })
  )

  return {
    data: bookmarksWithPodcast,
    isLast: false,
    getNext: () =>
      getPublicBookmarks({
        limit,
        filter,
        after: plainBookmarksSnapshots[plainBookmarksSnapshots.length - 1],
      }),
  }
}

export async function incrementPlayCount(
  bookmark: Bookmark
): Promise<Bookmark> {
  if (!bookmark.sharedAtIsoDate) {
    console.warn(
      'Trying to increment play count of bookmark that is not public. Returning'
    )
    return bookmark
  }
  if (!bookmark._path) {
    console.error(
      'Path of bookmark does not exists. Should not happen, dont know what to update. Returning'
    )
    return bookmark
  }

  await firestore()
    .doc(bookmark._path)
    .update({listenedCount: bookmark.listenedCount + 1})

  return {...bookmark, listenedCount: bookmark.listenedCount + 1}
}

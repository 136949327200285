/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Typography, makeStyles, createStyles, Button} from '@material-ui/core'
import Container from '../Container'
import {
  getBookmarksForMedia,
  getMediaById,
  getUserMedia,
  useUserMedia,
} from '../../db'
import LoadingIndicator from '../LoadingIndicator'
import UserMediaList from './components/UserMediaList'
import {useAuth} from '../AuthProvider'
import SearchBar from '../SearchBar'
import InfiniteLoadingList from '../InfiniteLoadingList'
import {UserRecord} from './models'

export enum MediaType {
  POPULAR,
  MY_LIBRARY,
}

// Todo remove display : none property from search bar , when search functionality is fixed
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    filters: {
      maxWidth: '100%',
      margin: theme.spacing(0, -1),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    listContainer: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
    searchBar: {
      position: 'sticky',
      top: 65,
      background: '#fafafa',
      zIndex: 100,
      display: 'none',
    },
    addMoreButton: {
      marginBottom: theme.spacing(2),
    },
  })
)

function LibraryScreen() {
  const location = useLocation()
  const auth = useAuth()
  const history = useHistory()
  const styles = useStyles()
  const historySearchTerm: any = history.location.state
  const mediaType: MediaType = location.pathname.includes('explore')
    ? MediaType.POPULAR
    : MediaType.MY_LIBRARY
  const userId =
    mediaType === MediaType.POPULAR
      ? auth.curatedUserId
      : auth.authData.user
      ? auth.authData.user.uid
      : ''
  const userMedia = useUserMedia(userId || '')
  const [userMediaWithEpisodes, setUserMediaWithEpisodes] = useState<
    any[] | null
  >(null)
  const [searchString, setSearchString] = useState(
    historySearchTerm?.searchTerm
  )

  useEffect(() => {
    if (searchString) {
      history.replace(`/library?search=${searchString}`, {
        searchTerm: searchString,
      })
    }
  }, [searchString, history])

  useEffect(() => {
    async function getMediaDetails() {
      if (userMedia) {
        let filteredMedia = userMedia
        if (mediaType === MediaType.POPULAR) {
          filteredMedia = userMedia.filter((media) => media.favorite === true)
        }
        let mediaWithEpisodesTemp = await Promise.all(
          filteredMedia.map(async (single) => ({
            episode: await getMediaById(single.id),

            lastPlayedLocation: single.lastPlayedLocation,
            lastPlayedAt: single.lastPlayedAt || 0,

            bookmarks: await getBookmarksForMedia({
              mediaId: single.id,
              userId,
            }),

            favorite: single.favorite,
          }))
        )

        mediaWithEpisodesTemp = mediaWithEpisodesTemp.filter(
          (one) => !!one.episode && one.episode.episodeLength
        ) as any

        if (userMediaWithEpisodes) {
          userMediaWithEpisodes.push(mediaWithEpisodesTemp)
        }
        setUserMediaWithEpisodes(mediaWithEpisodesTemp)
      }
    }

    getMediaDetails()
  }, [userMedia, setUserMediaWithEpisodes, userId, auth, mediaType])

  const fetchNextPage = useCallback(
    async (previousResponse: UserRecord[] | null) => {
      let newUserMedia = null
      if (previousResponse) {
        const lastDocResult = previousResponse[previousResponse.length - 1]
        newUserMedia = await getUserMedia(userId, lastDocResult)
      } else {
        newUserMedia = await getUserMedia(userId)
      }
      let mediaWithEpisodesTemp = null
      if (newUserMedia) {
        mediaWithEpisodesTemp = await Promise.all(
          newUserMedia.docs.map(async (single) => ({
            episode: await getMediaById(single.id),
            lastPlayedLocation: single.data().lastPlayedLocation,
            lastPlayedAt: single.data().lastPlayedAt,
            bookmarks: await getBookmarksForMedia({
              mediaId: single.id,
              userId,
            }),
            favorite: single.data().favorite,
          }))
        )

        mediaWithEpisodesTemp = mediaWithEpisodesTemp.filter(
          (one) => !!one.episode && one.episode.episodeLength
        ) as any
      }

      return {
        result: mediaWithEpisodesTemp || [],
        isLast: newUserMedia.empty,
      }
    },
    [useUserMedia, userMedia]
  )

  return (
    <Container maxWidth="md">
      <div className={styles.searchBar}>
        <Container>
          <SearchBar
            inputLabel="Search for your bookmarks/clips/media"
            onChangeSearchString={(newSearch) => {
              setSearchString(newSearch)
            }}
            searchTerm={searchString}
          />
        </Container>
      </div>
      <Button
        component={Link}
        to="/add-media"
        className={styles.addMoreButton}
        variant="contained"
        color="primary"
      >
        Add media
      </Button>
      {userMediaWithEpisodes === null && (
        <LoadingIndicator text="Loading media" />
      )}
      {userMediaWithEpisodes && userMediaWithEpisodes.length === 0 && (
        <Typography>No media in library</Typography>
      )}
      {userMediaWithEpisodes && userMediaWithEpisodes.length > 0 && (
        <InfiniteLoadingList
          loadMore={fetchNextPage}
          resultToItems={(result) => result}
        >
          {(userMediaRecords: UserRecord[]) => (
            <UserMediaList
              media={userMediaRecords}
              mediaType={mediaType}
              searchTerm={searchString}
            />
          )}
        </InfiniteLoadingList>
      )}
    </Container>
  )
}

export default LibraryScreen

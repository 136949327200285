import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {createStyles, makeStyles} from '@material-ui/core'
import {Podcast} from '../../apis/listenNotes/models'
import {getManuallyAddedPodcasts} from '../../apis/podcastResolver/db'
import PodcastsGrid from '../PodcastGridList'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
)

interface Props {
  className?: string
}

function ManuallyAddedListScreen({className}: Props): JSX.Element {
  const classes = useStyles()

  const [podcasts, setPodcasts] = useState<Podcast[]>([])

  useEffect(() => {
    getManuallyAddedPodcasts().then(setPodcasts)
  }, [setPodcasts])

  return (
    <div className={clsx(classes.root, className)}>
      <PodcastsGrid podcasts={podcasts} />
    </div>
  )
}

export default ManuallyAddedListScreen

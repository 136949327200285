import React, {useContext, useCallback} from 'react'
import moment from 'moment'
import randToken from 'rand-token'
import bookmarksContext, {BookmarksContext} from './context'
import {usePlayer} from '../PlayerProvider'
import {Bookmark} from '../../db/models'
import {addBookmark, removeBookmark, updateBookmark} from '../../db'
import {useSettings} from '../SettingsProvider'

interface Props {
  children: React.ReactNode
}

export const DEFAULT_CLIP_LENGTH = 30

function BookMarksProvider({children}: Props) {
  const player = usePlayer()
  const settings = useSettings()
  const submitBookmark = useCallback(
    async ({
      description,
      type,
    }: {
      description: string
      type: 'bookmark' | 'clip'
    }) => {
      const currentTime = player.playerControls.getPosition()
      if (!currentTime || !player.playingMedia) {
        // TODO this should not happen. Use Notistack to notify user that something went wrong
        throw new Error('Error while adding bookmark. Nothing is playing')
      }
      const id = randToken.generate(4)

      const positionSec = Math.ceil(currentTime)

      const newBookmark: Bookmark = {
        id,
        listenedCount: 0,
        positionSec,
        description,
        mediaId: player.playingMedia?.episode.id,
        created: moment().toString(),
        endPositionSec:
          type === 'clip'
            ? positionSec + Number(settings.settingsData.clipLength)
            : undefined,
        authorId: '', // this is not needed, author is set automatically
        sharedAtIsoDate: undefined,
      }

      return addBookmark(newBookmark)
    },
    [player]
  )

  const bookmarksContextValue: BookmarksContext = {
    addBookmark: submitBookmark,
    removeBookmark,
    updateBookmark,
  }
  return (
    <bookmarksContext.Provider value={bookmarksContextValue}>
      {children}
    </bookmarksContext.Provider>
  )
}

export default BookMarksProvider

export function useBookmarks() {
  return useContext(bookmarksContext)
}

import {Layout} from '../components/InsideScreen/components/LayoutSwitch'

export interface UserMedia {
  id: string
  lastPlayedLocation: number
  favorite?: boolean
  bookmarks?: Bookmark[]
  lastPlayedAt: number
}

export enum BookmarkType {
  MY_BOOKMARKS,
  POPULAR_BOOKMARKS,
  SHARED_BOOKMARKS,
}

export interface Bookmark {
  id: string
  mediaId: string
  positionSec: number
  endPositionSec?: number
  description: string
  created: string
  bookmarkType?: BookmarkType
  sharedAtIsoDate?: string
  authorId: string
  _path?: string
  listenedCount: number
}

export interface Media {
  id: string
  episodeMediaUrl: string
  title: string | null
  publisher: string | null
  episodeDescription: string | null
  publishedAt: string | null
  podcastName: string | null
  podcastRssFeed: string | null
  listennotesPodcastUid: string | null
  listennotesEpisodeUid: string | null
  created: number
  thumbnail: string
  episodeLength: number
  episodeAudio: string
  hashKey: string
  podcastId: string
  addedManually?: boolean
}

export interface UserRecordFromDb {
  id: string
  backwardStepSizeSec: number
  defaultClipLength: number
  email: string
  forwardStepSizeSec: number
  name: string
  playbackRate: number
  stopAfterClipEnds: boolean
  viewSharedClips: string
  expandedVideoTop: number
  expandedVideoBottom: number
  expandedVideoRight: number
  expandedVideoLeft: number
  layout: Layout
}

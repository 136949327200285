import React from 'react'
import InsideScreen from '../InsideScreen'
import {
  useAnalyticsUserTracking,
  useReportUrlChange,
} from '../../utils/analytics'

function RootRouter() {
  useAnalyticsUserTracking()
  useReportUrlChange()
  return <InsideScreen />
}

export default RootRouter

import {EpisodeWithPodcast, PodcastWithEpisode, Podcast} from './models'
/* eslint-disable camelcase */

import listenNotesService from './service'

export interface BestPodcastsResponse {
  id: number
  name: string
  total: number
  has_next: boolean
  podcasts: Podcast[]
  parent_id: number
  page_number: number
  has_previous: number
  next_page_number: number
  previous_page_number: number
}

export interface SearchPodcastsResponse {
  /** Time it took to fetch */
  took: number
  /** Number of items on this page */
  count: number
  /** Total number of results */
  total: number
  results: Podcast[]
  next_offset: number
}

export async function bestPodcasts(args?: {
  genreId?: number
  page?: number
  region?: string
  safeMode?: boolean
}) {
  const {genreId, page, region, safeMode} = args || {}
  return listenNotesService.get<BestPodcastsResponse>('/best_podcasts', {
    params: {
      genre_id: genreId,
      page: page || 0,
      region,
      safe_mode: safeMode ? 1 : 0,
    },
  })
}
export async function searchPodcasts({
  search,
  sortByDate,
  offset,
  genreIds: genre_ids,
  publishedBefore: published_before,
  publishedAfter: published_after,
  onlyIn: only_in,
  language,
  safeMode,
}: {
  search: string
  sortByDate?: boolean
  offset?: number
  genreIds?: string
  publishedBefore?: number
  publishedAfter?: number
  /** A comma-delimited string to search only in specific fields. Allowed values are title, description, author, and audio. If not specified, then search every field */
  onlyIn?: string
  language?: string
  safeMode?: string
}) {
  return listenNotesService.get<SearchPodcastsResponse>('/search', {
    params: {
      q: search,
      sort_by_date: sortByDate ? 1 : 0,
      type: 'podcast',
      offset,
      genre_ids,
      published_before,
      published_after,
      only_in,
      language,
      safe_mode: safeMode ? 1 : 0,
    },
  })
}

export async function typeheadPodcasts({
  search,
  safeMode,
}: {
  search: string
  safeMode?: boolean
}) {
  return listenNotesService.get<{terms: string[]}>('/typehead', {
    params: {
      q: search,
      safe_mode: safeMode ? 1 : 0,
      show_podcasts: 1,
    },
  })
}

export async function podcastDetail({
  podcastId,
  nextEpisodePubDate,
  sort,
}: {
  podcastId: string
  nextEpisodePubDate?: number
  sort?: 'recent_first' | 'oldest_first'
}): Promise<{data: PodcastWithEpisode}> {
  return listenNotesService.get<PodcastWithEpisode>(
    `/podcasts/${encodeURIComponent(podcastId)}`,
    {params: {next_episode_pub_date: nextEpisodePubDate, sort}}
  )
}

export async function episodeDetail({episodeId}: {episodeId: string}) {
  return listenNotesService.get<EpisodeWithPodcast>(`/episodes/${episodeId}`)
}

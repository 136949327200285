import React, {useCallback, useMemo} from 'react'
import {Button, ButtonProps} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {useAnalytics} from '../utils/analytics'
import {usePlayer} from './PlayerProvider'
import EpisodeMedia from './ResolveMedia/EpisodeMedia'
import {Podcast} from '../apis/listenNotes/models'

interface Props {
  className?: string
  episode?: EpisodeMedia | null
  podcast?: Podcast | null
  playFromPosition?: number
  onPlay: () => {}
  onPause: () => {}
  originForAnalytics: string
  buttonProps: ButtonProps
  playingText: string
  notPlayingText: string
}

function InteractivePlayButton({
  episode,
  podcast,
  playFromPosition,
  originForAnalytics,
  onPause,
  onPlay,
  className,
  buttonProps,
  playingText,
  notPlayingText,
}: Props) {
  const analytics = useAnalytics()
  const player = usePlayer()
  const {enqueueSnackbar} = useSnackbar()

  const {
    playingMedia,
    startPlaying,
    isPlaying,
    playerControls: {pause: pausePlaying},
  } = player

  const isTargetCurrentlyPlaying = useMemo(() => {
    return (
      playingMedia &&
      isPlaying &&
      playingMedia?.podcast.id === podcast?.id &&
      playingMedia.episode.id === episode?.id
    )
  }, [playingMedia, episode, podcast, isPlaying])

  const playClicked = useCallback(() => {
    if (!isTargetCurrentlyPlaying) {
      onPlay()

      if (!episode || !podcast) {
        enqueueSnackbar(
          'Episode is still loading, press play button again in a moment',
          {
            variant: 'error',
          }
        )
        return
      }

      analytics.logEvent({
        eventName: 'playback_started',
        params: {
          origin: originForAnalytics,
          resumed: !!playFromPosition,
        },
      })
      startPlaying({
        media: {episode, podcast},
        timeSec: playFromPosition || 0,
      })
    } else {
      onPause()
      pausePlaying()
    }
  }, [
    startPlaying,
    pausePlaying,
    episode,
    podcast,
    playFromPosition,
    analytics,
    originForAnalytics,
    isTargetCurrentlyPlaying,
    enqueueSnackbar,
    onPlay,
    onPause,
  ])

  return (
    <Button
      // disabled={!episode || !podcast}
      {...buttonProps}
      onClick={playClicked}
      className={className}
    >
      {isTargetCurrentlyPlaying ? playingText : notPlayingText}
    </Button>
  )
}

export default InteractivePlayButton

InteractivePlayButton.defaultProps = {
  onPlay: () => undefined,
  onPause: () => undefined,
  playingText: 'Pause',
  notPlayingText: 'Play',
  buttonProps: {},
}

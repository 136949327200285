import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Typography,
  Link as MuiLink,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Podcast} from '../../../apis/listenNotes/models'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    image: {
      minHeight: theme.spacing(30),
      height: '30vh',
      width: 'auto',
      margin: '0 auto',
      display: 'block',
    },
    name: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    publisher: {},
    imageContainer: {
      background: theme.palette.grey[900],
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
  })
)

interface Props {
  podcast: Podcast
}

function PodcastHeader({podcast}: Props) {
  const classes = useStyles({imageUrl: podcast.image})
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img
          src={podcast.image || 'todo'}
          alt={podcast.title}
          className={classes.image}
        />
      </div>
      <Container>
        <Typography align="center" className={classes.name} variant="h5">
          {podcast.title}
        </Typography>
        <Typography align="center" className={classes.publisher} variant="h6">
          {podcast.publisher}
        </Typography>
        {!podcast.rss?.startsWith('Please') && podcast.rss && (
          <MuiLink component={Link} to={`/add-media/from-url?${podcast.rss}`}>
            Import new episodes
          </MuiLink>
        )}
      </Container>
    </div>
  )
}

export default PodcastHeader

import {firestore} from 'firebase'
import {Episode, Podcast, PodcastWithEpisode} from '../listenNotes/models'

export default {}

const COLLECTION_MANUALLY_ADDED_EPISODES = 'manually-added-episodes'
const COLLECTION_MANUALLY_ADDED_PODCASTS = 'manually-added-podcasts'

export function addEpisode(episode: Episode) {
  const {id} = episode
  firestore().doc(`/${COLLECTION_MANUALLY_ADDED_EPISODES}/${id}`).set(episode)
}

export function addPodcast(podcast: Podcast | PodcastWithEpisode) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {id, episodes, ...toAdd} = podcast
  firestore().doc(`/${COLLECTION_MANUALLY_ADDED_PODCASTS}/${id}`).set(toAdd)
}

export async function getEpisode(id: string): Promise<Episode | undefined> {
  const doc = await firestore()
    .doc(`/${COLLECTION_MANUALLY_ADDED_EPISODES}/${id}`)
    .get()

  if (!doc.exists) return undefined
  return {id: doc.id, ...doc.data()} as Episode | undefined
}

export async function getEpisodesForPodcast(
  podcastId: string
): Promise<Episode[]> {
  const doc = await firestore()
    .collection(COLLECTION_MANUALLY_ADDED_EPISODES)
    .where('podcastId', '==', podcastId)
    .orderBy('pub_date_ms', 'desc')
    .get()
  const result: Episode[] = []
  doc.forEach((one) => {
    result.push({...one.data(), id: one.id} as Episode)
  })

  return result
}

export async function getPodcast(
  id: string
): Promise<PodcastWithEpisode | undefined> {
  const doc = await firestore()
    .doc(`/${COLLECTION_MANUALLY_ADDED_PODCASTS}/${id}`)
    .get()

  if (!doc.exists) return undefined

  const podcast = {id: doc.id, ...doc.data()} as PodcastWithEpisode
  podcast.episodes = await getEpisodesForPodcast(podcast.id)

  return podcast
}

export async function getManuallyAddedPodcasts(): Promise<Podcast[]> {
  const doc = await firestore()
    .collection(COLLECTION_MANUALLY_ADDED_PODCASTS)
    .get()
  const result: Podcast[] = []

  doc.forEach((one) => {
    result.push({...one.data(), id: one.id} as Podcast)
  })
  return result
}

import React from 'react'
import clsx from 'clsx'
import {
  Button,
  ButtonGroup,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'

export enum Layout {
  FULL = 'full',
  LEFT = 'left',
  RIGHT = 'right',
}

const useStyles = makeStyles<Theme, {layout: Layout}>((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      marginLeft: ({layout}) => (layout !== Layout.LEFT ? 'auto' : ''),
      marginRight: ({layout}) => (layout !== Layout.RIGHT ? 'auto' : ''),
    },
  })
)

interface Props {
  className?: string
  value: Layout
  onChange: (v: Layout) => void
}

function LayoutSwitch({
  className,
  value: layout,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles({layout})
  const handleChange = (newValue: Layout) => () => onChange(newValue)
  return (
    <ButtonGroup
      className={clsx(className, classes.root)}
      variant="outlined"
      color="primary"
      aria-label="contained primary button group"
    >
      <Button
        onClick={handleChange(Layout.LEFT)}
        variant={layout === Layout.LEFT ? 'contained' : 'outlined'}
      >
        Left
      </Button>
      <Button
        onClick={handleChange(Layout.FULL)}
        variant={layout === Layout.FULL ? 'contained' : 'outlined'}
      >
        Full
      </Button>
      <Button
        onClick={handleChange(Layout.RIGHT)}
        variant={layout === Layout.RIGHT ? 'contained' : 'outlined'}
      >
        Right
      </Button>
    </ButtonGroup>
  )
}

export default LayoutSwitch

import {createContext} from 'react'

export type ControlledPlayerState = 'playing' | 'paused' | 'initial'

export interface ControlledPlayerContext {
  source: string | null
  waiting: boolean
  state: ControlledPlayerState
  volume: number
  rate: number
  duration: number | undefined

  getPosition: () => number | undefined

  play: () => void
  pause: () => void
  setSource: (
    url: string,
    args?: {seek?: number; autoplayIfPossible?: boolean}
  ) => void
  seek: (positionSec: number) => void
  setVolume: (percent: number) => void
  setRate: (multiplier: number) => void

  registerOnPlayedListener: (
    listener: (position: number | undefined) => void
  ) => () => void
  registerOnUserSeekingListener: (
    listener: (position: number) => void
  ) => () => void
  registerOnPlaybackInteractionListener: (
    listener: (args: {
      interactionType: 'seek' | 'play' | 'pause'
      currentState: 'playing' | 'paused'
    }) => void
  ) => () => void
}

export const emptyControlledPlayerContext: ControlledPlayerContext = {
  source: null,
  state: 'initial',
  waiting: false,
  volume: 100,
  rate: 1,
  duration: undefined,

  getPosition: () => undefined,

  play: () => undefined,
  pause: () => undefined,
  setSource: () => undefined,
  seek: () => undefined,
  setVolume: () => undefined,
  setRate: () => undefined,

  registerOnPlayedListener: () => () => undefined,
  registerOnUserSeekingListener: () => () => undefined,
  registerOnPlaybackInteractionListener: () => () => undefined,
}

const controlledPlayerContext = createContext<ControlledPlayerContext>(
  emptyControlledPlayerContext
)

export default controlledPlayerContext

/* eslint-disable camelcase */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {Podcast} from '../../apis/listenNotes/models'
import EpisodeMedia from '../ResolveMedia/EpisodeMedia'
import playerContext, {PlayerContext} from './context'
import PlayerBar from './components/PlayerBar'
import {setMediaLength} from '../../db'
import {useSettings} from '../SettingsProvider'
import ControlledPlayerProvider, {
  useControlledPlayer,
} from '../ControlledPlayer'
import useSaveProgress from './utils/useSaveProgress'
import useStopClipManager from './utils/useStopClipManager'

export interface PlayingMedia {
  episode: EpisodeMedia
  podcast: Podcast
}

interface Props {
  children: React.ReactNode
}

function PlayerProvider({children}: Props) {
  const settings = useSettings()
  const [playingMedia, setPlayingMedia] = useState<PlayingMedia | null>(null)
  const controlledPlayer = useControlledPlayer()

  const playClipManager = useStopClipManager({
    currentlyPlayingMedia: playingMedia,
    player: controlledPlayer,
  })

  useSaveProgress({playingMedia})

  useEffect(() => {
    controlledPlayer.setRate(settings.settingsData.playbackSpeed)
  }, [settings, controlledPlayer])

  useEffect(() => {
    if (controlledPlayer.duration && playingMedia) {
      if (controlledPlayer.duration !== playingMedia?.episode.episodeLength) {
        setMediaLength({
          mediaId: playingMedia.episode.id,
          mediaLength: controlledPlayer.duration,
        })
      }
    }
  }, [playingMedia, controlledPlayer.duration])

  const startPlaying = useCallback(
    ({media, timeSec, pauseOnLoad}) => {
      setPlayingMedia(media)

      controlledPlayer.setSource(media.episode.episodeAudio, {
        seek: timeSec,
        autoplayIfPossible: !pauseOnLoad,
      })
    },
    [controlledPlayer.setSource, setPlayingMedia]
  )

  const playClip = useCallback(
    ({
      startSec,
      endSec,
      media,
      forceStop,
    }: {
      startSec: number
      endSec: number
      media: PlayingMedia
      forceStop?: boolean
    }) => {
      startPlaying({media, timeSec: startSec, pauseOnLoad: false})
      playClipManager.registerToStop(
        {start: startSec, end: endSec, media},
        forceStop
      )
    },
    [playClipManager, startPlaying]
  )

  const contextValue: PlayerContext = {
    playerControls: controlledPlayer,
    playingMedia,
    setPlayingMedia,
    startPlaying,
    playClip,
    isPlaying: controlledPlayer.state === 'playing',
  }

  // For debugging purposes. Player can now be accessed in browser console using `window.player` variable.
  if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    window.player = contextValue
  }

  return (
    <playerContext.Provider value={contextValue}>
      {children}
    </playerContext.Provider>
  )
}

export default function PlayerProviderWithControlledPlayer({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <ControlledPlayerProvider>
      <PlayerProvider>{children}</PlayerProvider>
    </ControlledPlayerProvider>
  )
}

export function usePlayer() {
  return useContext(playerContext)
}

export function useCurrentPosition() {
  const {playerControls} = usePlayer()
  const [currentTime, setCurrentTimeState] = useState<undefined | number>(
    playerControls.getPosition()
  )

  useEffect(() => {
    return playerControls.registerOnPlayedListener(setCurrentTimeState)
  }, [setCurrentTimeState])

  return {
    currentTime,
    setCurrentTime: playerControls.seek,
  }
}

export {PlayerBar}

import React from 'react'
import {makeStyles, createStyles, Grid} from '@material-ui/core'
import {Podcast} from '../../apis/listenNotes/models'
import PodcastCell from './components/PodcastCell'
import RectangularBox from '../RectangularBox'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: theme.palette.grey[900],
    },
  })
)
interface Props {
  podcasts: Podcast[]
  onItemClick: (args: {podcast: Podcast; index: number}) => void
}

function PodcastsGrid({podcasts, onItemClick}: Props) {
  const classes = useStyles()

  return (
    <Grid container className={classes.container} spacing={0}>
      {podcasts.map((podcast, index) => (
        <Grid
          component={RectangularBox}
          mode="calculateHeight"
          item
          xs={6}
          sm={4}
          md={3}
          lg={3}
          xl={2}
          key={podcast.id}
        >
          <PodcastCell
            onClick={() => onItemClick({podcast, index})}
            podcast={podcast}
          />
        </Grid>
      ))}
    </Grid>
  )
}

PodcastsGrid.defaultProps = {
  onItemClick: () => undefined,
}

export default PodcastsGrid

import {
  getMediaById,
  getPublicBookmarks,
  GetPublicBookmarksResult,
} from '../../db'
import {StandaloneBookmarkData} from './components/StandaloneBookmark'
import {FilterType} from '../BookmarkTypeFilter'
import {getPodcastDetail} from '../../apis/podcastResolver'

const ONE_PAGE_LIMIT = 20

export interface FetchBookmarksResult {
  fetchNext: () => Promise<GetPublicBookmarksResult>
  data: StandaloneBookmarkData[]
}

export function createFetchFunction(filter: {
  type: FilterType
  notEmpty: boolean
}) {
  return async function fetchBookmarks(
    previous: FetchBookmarksResult | null
  ): Promise<{result: FetchBookmarksResult; isLast: boolean}> {
    const bookmarksWithEpisodeId = await (previous
      ? previous.fetchNext()
      : getPublicBookmarks({limit: ONE_PAGE_LIMIT, filter}))

    const bookmarksWithFullEpisode: any = await Promise.all(
      bookmarksWithEpisodeId.data.map(async (bookmarkWithEpisodeId) => {
        const episode = await getMediaById(bookmarkWithEpisodeId.episodeId)
        if (!episode) return null

        const podcast = await getPodcastDetail({
          podcastId: episode.listennotesPodcastUid || episode.podcastId,
        })

        return {
          bookmark: bookmarkWithEpisodeId.bookmark,
          authorEmail: bookmarkWithEpisodeId.authorEmail,
          authorName: bookmarkWithEpisodeId.authorName,
          episode,
          podcast,
        }
      })
    )

    return {
      result: {
        fetchNext: bookmarksWithEpisodeId.getNext,
        data: bookmarksWithFullEpisode.filter(Boolean),
      },
      isLast: bookmarksWithEpisodeId.isLast,
    }
  }
}
